import "./PowerBIApplication.css";

import React from "react";
import { connect } from 'react-redux';
import { models, Report } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';

import { authWorkflow_GetDashboardAttributes } from '../../state/workflows/login';


class PowerBIApplication extends React.Component {
  constructor(props) {
    super();
    this.props = props || {};
    this.handleError = this.handleError.bind(this);
    this.renderEmbeddedReport = this.renderEmbeddedReport.bind(this);
    this.renderExternalReport = this.renderExternalReport.bind(this);
  }

  async handleError(event) {
    console.warn(event.detail);

    if ((event.detail || {}).message === 'TokenExpired') {
      const { tokenState } = this.props;
      const { applicationToken } = tokenState;
      if (!!applicationToken) {
        console.log("Refresh PowerBI embedding tokens...")
        await authWorkflow_GetDashboardAttributes(applicationToken);
      }
    }
  }

  renderEmbeddedReport() {
    const { reportAttributes } = this.props;
    const {
      powerbi_report_id,
      powerbi_embed_token,
      powerbi_embed_url
    } = reportAttributes;

    const cssClassName = 'full-size';
    const reportEmbeddingConfig = {
      type: 'report',   // Supported types: report, dashboard, tile, visual, qna, paginated report and create
      id: powerbi_report_id,
      embedUrl: powerbi_embed_url,
      accessToken: powerbi_embed_token,
      tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
      settings: {
        panes: {
          filters: {
            expanded: false,
            visible: false
          }
        },
        //background: models.BackgroundType.Transparent,
        cssClassName: cssClassName
      }
    };
    const reportEventHandlers = new Map([
      ['loaded', function () { console.log('Report loaded'); }],
      //['rendered', function () {console.log('Report rendered');}],
      ['error', async (event) => { this.handleError(event); } ],
      //['visualClicked', () => console.log('visual clicked')],
      //['pageChanged', (event) => console.log(event)],
    ]);

    return (
      <PowerBIEmbed embedConfig = {reportEmbeddingConfig}
                    eventHandlers = {reportEventHandlers}
                    cssClassName = {cssClassName}
                    getEmbeddedComponent = { (embeddedReport) => { this.report = embeddedReport; } } />
    );
  }

  renderExternalReport(dashboardProps) {
    const { reportAttributes } = this.props;
    return (
      <iframe width="100%"
              height="100%"
              src={reportAttributes.powerbi_web_url}
              frameBorder="0"
              allowFullScreen={true} />
    );
  }

  render() {
    const { reportAttributes, position } = this.props;
    const {
      powerbi_report_id,
      powerbi_report_name,
      powerbi_embed_token,
      powerbi_embed_url,
      powerbi_web_url,
    } = reportAttributes || {};

    let reportNode = null;
    // If we have the report ID and the embedding token, we use embedding
    if (powerbi_report_id && powerbi_embed_token && powerbi_embed_url) {
      console.log(`Rendering embedded PowerBI report (${powerbi_report_name})...`);
      reportNode = this.renderEmbeddedReport();
    }

    // Fallback: if we have a report URL but no embedding token, use an iFrame with external report viewer
    else if (powerbi_web_url) {
      console.log(`Rendering externally hosted  PowerBI report (${powerbi_report_name})...`);
      reportNode = this.renderExternalReport();
    }

    const backgroundClassName = reportNode ? 'caramel-background' : '';
    const containerPositionClassName = position ?? '';
    const containerClassName = `powerbi-report-container ${backgroundClassName} ${containerPositionClassName}`;

    return (
      <div className={containerClassName}>
        <h3 className="ptserif-normal-black-xs align-right">
          {powerbi_report_name}
        </h3>
        { reportNode }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    tokenState: state.token,
  };
}

export default connect(mapStateToProps)(PowerBIApplication);
