import React from "react";
import "./LoginButton.css";

function LoginButton(props) {
  const { children, clickHandler, disabled, tabIndex } = props;

  let buttonIsDisabled;
  if (typeof(disabled) === "boolean") {
    buttonIsDisabled = disabled;
  }
  else if (typeof(disabled) === 'string') {
    buttonIsDisabled = ['disabled', 'true', 'yes', 'y'].includes(disabled)
  }
  else {
    buttonIsDisabled = false;
  }

  const disabledClassName = (buttonIsDisabled) ? 'login-button-disabled' : '';
  const buttonClassName = `login-button ${disabledClassName}`;

  const handleKeyDown = (e) => {
    const keyCode = (e || {}).code.toLowerCase();
    if (['space', 'enter'].includes(keyCode)) {
      clickHandler(e);
    }
  }

  return (
    <div className={buttonClassName}
         disabled={disabled}
         onKeyDown={handleKeyDown}
         tabIndex={tabIndex}>
      <div className="big-button"
           onClick={(e) => clickHandler(e)}>{children}</div>
    </div>
  )
};

export default LoginButton;
