const defaultState = {
  viewportSize: {
    width: 1920,
    height: 1080
  }
};

export function viewportSizeReducer(state = defaultState, action) {
  switch (action.type) {

    case 'VIEWPORT_SIZE_UPDATED':
      const { viewportSize } = action.payload;
      return {
        ...state,
        viewportSize
      };

    default:
      return state;
  }
}
