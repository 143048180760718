import React from "react";
import { Link } from "react-router-dom";

import Elevation1Radius10 from "../Elevation1Radius10";

import "./PageSelector.css";


function PageSelector(props) {
  const {
    pageName,
    subtitle,
    pageUrl,
    IconClass,
    elevation1Radius10Props,
    positioningClassNames
  } = props;
  var cssClasses = `page-selector ${positioningClassNames.join(" ")}`;

  return (
    <Link to={pageUrl}>
      <div className={cssClasses}>
        <div className="overlap-group-outer">
          <div className="overlap-group-inner">
            <Elevation1Radius10 className={elevation1Radius10Props.className} />
            <div className="page-selector-title ptserif-bold-black-l">{pageName}</div>
            <img className="page-selector-underline" src="/img/underline@2x.svg" />
            <div className="page-selector-subtitle desktopbodycopy">{subtitle}</div>
          </div>
          <IconClass className="page-selector-image" fontSize="large" />
        </div>
      </div>
    </Link>
  );
}

export default PageSelector;
