import React from "react";
import "./InnerContent.css";

function InnerContent(props) {
  const { desktopBodycopy18PtCalibriRegular, desktopBodycopy20PtCalibriRegular, className } = props;

  return (
    <div className={`inner-content-1 ${className || ""}`}>
      <div className="desktop-bodycopy-18pt-calibri-regular desktopbodycopy-s">
        {desktopBodycopy18PtCalibriRegular}
      </div>
      <div className="desktop-bodycopy-20pt-calibri-regular desktopbodycopy">{desktopBodycopy20PtCalibriRegular}</div>
    </div>
  );
}

export default InnerContent;
