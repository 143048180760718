import { getData } from "./serviceClient";


export async function listUsers(appToken) {
  return await getData('principal', 'users/list', appToken);
}

export async function deleteUser(appToken, user_id) {
  return await getData(
    'principal',
    'users/delete',
    appToken,
    {user_id}
  );
}

export async function saveUser(appToken, user) {
  return await getData(
    'principal',
    'users/save',
    appToken,
    {user}
  );
}
