// AWS COgnito authentication token
export function cognitoTokenReceived(cognitoAuthenticationToken) {
  return {
    type: 'GET_COGNITO_TOKEN_SUCCEEDED',
    payload: { cognitoAuthenticationToken }
  };
}

// Application token with all authorization claims
export function getApplicationTokenInitiated() {
  return {
    type: 'GET_APPLICATION_TOKEN_INITIATED'
  };
}

export function getApplicationTokenSucceeded(applicationToken) {
  return {
    type: 'GET_APPLICATION_TOKEN_SUCCEEDED',
    payload: { applicationToken }
  };
}

export function getApplicationTokenFailed(errorMessage) {
  return {
    type: 'GET_APPLICATION_TOKEN_FAILED',
    payload: { errorMessage }
  };
}
