import React from "react";
import { Link } from "react-router-dom";
import "./CompanyFilter.css";

function CompanyFilter(props) {
  const { companyAbc1, companyAbc2, addCompany } = props;

  return (
    <div className="container-center-horizontal">
      <div className="company-filter screen">
        <div className="company-filter-1">
          <div className="overlap-group-32">
            <img className="vector-55" src="/img/vector-49@2x.svg" />
            <div className="company-abc-8 desktopfilters">{companyAbc1}</div>
          </div>
          <div className="filter-options-2">
            <div className="flex-row-23">
              <img className="vector-56" src="/img/vector-7@2x.svg" />
              <div className="company-abc-9 desktopfilters">{companyAbc2}</div>
            </div>
            <img className="line-18-5" src="/img/line-18@2x.svg" />
            <div className="flex-row-24">
              <Link to="/my-profile-page">
                <img className="vector-57" src="/img/vector-6@2x.svg" />
              </Link>
              <Link to="/my-profile-page">
                <div className="add-company-1 desktopfilters">{addCompany}</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyFilter;
