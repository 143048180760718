import React from "react";
import "./Elevation1Radius102.css";

function Elevation1Radius102(props) {
  const { src, className } = props;

  return (
    <div className={`elevation-1-radius-10-1 ${className || ""}`}>
      <img className="elevation-1-radius-10-5" src={src} />
    </div>
  );
}

export default Elevation1Radius102;
