import { hashHistory } from 'react-router';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import promiseMiddleware from 'redux-promise-middleware';
import thunk from 'redux-thunk';

import * as reducers from './reducers/allReducers';

const rootReducer = combineReducers({
  ...reducers,
  routing: routerReducer
});

const middlewareEnhancer = applyMiddleware(
  thunk,
  promiseMiddleware,
  routerMiddleware(hashHistory)
);

const store = createStore(
  rootReducer,
  middlewareEnhancer
));

export default store;
