function getComparer(attributeName, caseSensitive, prioritizeFunc) {
  const comparer = (obj1, obj2) => {
    let value1 = obj1[attributeName];
    let value2 = obj2[attributeName];

    // Both null, no change to sort order
    if (!value1 &&!value2) {
      return 0;
    }

    // One null value, push that one down in the ordering
    else if (!value1) {
      return 1;
    }
    else if (!value2) {
      return -1;
    }

    // Check prioritized objects
    if (!!prioritizeFunc) {
      var value1Prioritized = prioritizeFunc(obj1);
      var value2Prioritized = prioritizeFunc(obj2);
      if (value1Prioritized && value2Prioritized) {
        return getComparer(attributeName, caseSensitive, null);  // Both items on the prio list, default comparer
      }
      else if (value1Prioritized) {
        return -1;  // Only value1 on the prio list, pull value1 in front
      }
      else if (value2Prioritized) {
        return 1;  // Only value2 on the prio list, bush value1 back
      }
      else {
        return getComparer(attributeName, caseSensitive, null);  // Neither value on the prio list, default comparer
      }
    }

    // Compare by attribute values. Note that caseSensitive may only be used on strings.
    else {
      if (!caseSensitive) {
        value1 = value1.toLowerCase();
        value2 = value2.toLowerCase();
      }

      return (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
    }
  };

  return comparer;
}


export function sortByAttribute(arr, attributeName, caseSensitive=true, prioritizeFunc=null) {
  const comparer = getComparer(attributeName, caseSensitive, prioritizeFunc);
  return arr.sort(comparer);
}
