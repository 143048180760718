export function passwordResetInitiated(username) {
  return {
    type: 'PASSWORD_RESET_INITIATED',
    payload: { username }
  };
}

export function passwordResetSucceeded(username, password) {
  return {
    type: 'PASSWORD_RESET_SUCCEEDED',
    payload: { username, password }
  };
}

export function passwordResetFailed() {
  return {
    type: 'PASSWORD_RESET_FAILED'
  };
}
