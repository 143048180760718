import React from "react";
import "./SearchBar.css";

function SearchBar(props) {
  const { searchForDocuments } = props;

  return (
    <div className="container-center-horizontal">
      <div className="search-bar screen">
        <div className="search-bar-1">
          <div className="search-bar-2">
            <div className="overlap-group-23">
              <img className="vector-35" src="/img/vector-46@2x.svg" />
              <div className="search-for-documents valign-text-middle desktopbodycopy">{searchForDocuments}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchBar;
