import "./HomelandingPage.css";

import React from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import ApartmentIcon from '@mui/icons-material/Apartment';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FolderIcon from '@mui/icons-material/Folder';
import GroupIcon from '@mui/icons-material/Group';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import PersonIcon from '@mui/icons-material/Person';

import store from '../../state/store';

import Achtergrond from "../Achtergrond";
import Header from "../Header";
import PageSelector from "../PageSelector"
import TypeTitles from "../TypeTitles";


const RowSize = 2;  // the number of columns in the view

const DocumentsPageImplemented = false; // TODO


class HomelandingPage extends React.Component {
  constructor(props) {
    super();
    this.props = props;

    this.listPageSelectors = this.listPageSelectors.bind(this);
    this.currentUserHasAccessLevel = this.currentUserHasAccessLevel.bind(this);
  }

  contactIcons() {
    const show_phone_email_icon = false;
    if (show_phone_email_icon) {
      return (<img className="mail-and-mobile" src="/img/mail-and-mobile@2x.svg" />);
    }
    else
      return '';
  }

  currentUserHasAccessLevel(level) {
    const levels = {
      user: 1,
      principal: 2,
      manager: 3,
      admin: 4
    };

    const myRoles = this.props.myRoles || {};
    let maxLevel;
    if (myRoles.isAdmin)
      maxLevel = levels.admin;
    else if (myRoles.isAccountAndHospitalityManager)
      maxLevel = levels.manager;
    else if (myRoles.isClientPrincipal)
      maxLevel = levels.principal;
    else
      maxLevel = levels.user;

    return levels[level] <= maxLevel;
  }

  imageUrl(filename) {
    return `/img/${filename}`;
  }

  selectorClasses(position, nrTiles) {
    // Returns the CSS classes for the given position (0-based index).
    const rowNumber = Math.floor(position / RowSize) + 1;
    const colNumber = (position % RowSize) + 1;
    const classNames = ["selector-pos", `selector-pos-row-${rowNumber}`, `selector-pos-col-${colNumber}`]
    if (nrTiles / RowSize > 2) {
      classNames.push('dense');
    }
    return classNames;
  }

  listPageSelectors() {
    const { pageSelectorProps, myRoles, dashboardAttributes } = this.props;
    const elevationRadiusProps = pageSelectorProps.elevation1Radius10Props;

    const pageData = [
      {
        name: "Profile",
        url: "/profile",
        subtitle: "View your profile settings",
        IconClass: PersonIcon
      },
    ];

    if (DocumentsPageImplemented) {
      pageData.push(
        {
        name: "Documents",
        url: "/documents",
        subtitle: "An overview of the documents",
        IconClass: FolderIcon
        }
      );
    }

    if (this.currentUserHasAccessLevel('admin')) {
      pageData.push(
        {
          name: "Master Administrator Page",
          url: "/report-assignments",
          subtitle: "Manage BI report assignments",
          IconClass: ContentCopyIcon
        },
      );
    }

    if (this.currentUserHasAccessLevel('manager')) {
      pageData.push(
        {
          name: "A\&H Administrator Page",
          url: "/clients",
          subtitle: "Manage your compass clients and Principal users",
          IconClass: ApartmentIcon
        },
      );
    }

    if (this.currentUserHasAccessLevel('principal')) {
      pageData.push(
        {
          name: "Manage Portal Access",
          url: "/users",
          subtitle: "Manage access for your colleagues",
          IconClass: GroupIcon
        },
      );
    }

    const biReports = (dashboardAttributes || {}).reports || [];
    if (biReports.length > 0) {
      pageData.push(
        {
          name: "Dashboards",
          url: "/performance-dashboard",
          subtitle: "Insights in the key performance indicators",
          IconClass: InsertChartIcon
        }
      );
    }

    return pageData.map((data, idx) =>
      <PageSelector key={`page-selector${idx + 1}`}
                    pageName={data.name}
                    subtitle={data.subtitle}
                    pageUrl={data.url}
                    IconClass={data.IconClass}
                    elevation1Radius10Props={elevationRadiusProps}
                    positioningClassNames={this.selectorClasses(idx, pageData.length)} />
    );
  }

  render() {
    const {
      ellipse2,
      logoCompassGroup_Zwart_Rgb1,
      headerProps,
      typeTitlesProps,
      myOrganization,
    } = this.props;

    const { clientPrincipalUser } = (myOrganization || {});

    const cpText = !!clientPrincipalUser ? `${clientPrincipalUser.firstName} ${clientPrincipalUser.lastName} (${clientPrincipalUser.eMailAddress})` : '';
    const welcomeMessage = `Should you have any questions, please feel free to contact your location manager ${cpText} directly.`;

    const typeTitlesClassName = `${typeTitlesProps.className || ''} selector-pos-col-1`;
    const companyName = "Compass Group".replace(/ /g, "\u00A0");

    return (
      <div className="container-center-horizontal">
        <div className="home-landing-page screen">
          <div className="overlap-group4">
            <Achtergrond />
            <div className="left-group">
              <div className="left-header-1">{companyName}</div>
              <div className="left-header-2">For the love of<br />good food & great service</div>
              <img className="ellipse-2" src={ellipse2} />
              <div className="welcome-message desktopbodycopy">{welcomeMessage}</div>
              {this.contactIcons()}
            </div>
            <Header header2Props={headerProps.header2Props} />
            {this.listPageSelectors()}
          </div>
          <TypeTitles subtitle={typeTitlesProps.subtitle} className={typeTitlesClassName} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  /* Return a dict with the relevant state info to pass into the props for this component */
  return {
    myRoles: state.profile.roles,
    myOrganization: state.profile.organization,
    dashboardAttributes: state.dashboardAttributes,
  };
}

export default connect(mapStateToProps)(HomelandingPage)
