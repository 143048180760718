import React from "react";
import { Link } from "react-router-dom";
import Cancel from "../Cancel";
import "./CancelAndSaveChanges2.css";

function CancelAndSaveChanges2(props) {
  const { saveChanges, className } = props;

  return (
    <div className={`cancel-and-save-changes-1 ${className || ""}`}>
      <Cancel />
      <Link to="/login-screen">
        <div className="yes-im-sure">
          <div className="overlap-group1-3">
            <div className="save-changes-1 valign-text-middle ptserif-bold-white-xxs">{saveChanges}</div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default CancelAndSaveChanges2;
