import "./LoginScreen.css";

import React from "react";
import { connect } from 'react-redux';

import { authenticateAndLoadProfileData } from '../../state/workflows/login';
import { requestPasswordResetEmail } from '../../api/passwordReset';
import LoginButton from "../LoginButton";
import IntroText from "../IntroText";
import WelcomeHeader from "../WelcomeHeader";

class LoginScreen extends React.Component {

  constructor(props) {
    super();

    this.props = props;
    this.state = {
      // Input fields state
      username: props.username,
      password: props.password,
      passwordVisible: false,

      // Response
      loginHasFailed: false,
      
      // Modal state
      showPasswordResetModal: false,
      retrievePasswordResetTokenInProgress: false,
      passwordResetResultMessages: null,
    };

    this.onClickLoginButton = async e => {
      this.setState({loginHasFailed: false});
      const loginResult = await authenticateAndLoadProfileData(this.state.username, this.state.password);
      this.setState({loginHasFailed: !loginResult});
    };
    this.togglePasswordVisibility = e => this.setState({passwordVisible: !this.state.passwordVisible});

    this.renderPasswordResetLink = this.renderPasswordResetLink.bind(this);
    this.renderPasswordResetModal = this.renderPasswordResetModal.bind(this);
    this.closePasswordResetModal = this.closePasswordResetModal.bind(this);
  }

  async sendPasswordResetToken(email) {
    this.setState({retrievePasswordResetTokenInProgress: true});
    try {
      this.setState({passwordResetResultMessages: ['In progress...']})

      const response = await requestPasswordResetEmail(email);
      const responsePayload = (response || {})['password_reset/generate'] || {};

      let resultMessages = null;
      if (responsePayload.status === 'ok') {
        resultMessages = [
          responsePayload.message
        ];
      }
      else {
        resultMessages = [
          'Failed to send password reset e-mail',
          'Please try again later'
        ];
      }
      this.setState({passwordResetResultMessages: resultMessages})
    }
    finally {
      this.setState({retrievePasswordResetTokenInProgress: false});
      setTimeout(
        () => { this.closePasswordResetModal() },
        10000
      )
    }
  }

  renderPasswordInputField() {
    const inputType  = (this.state.passwordVisible) ? "text" : "password";
    return (
      <input type={ inputType }
             className="password-input desktopbodycopy"
             autoComplete="compass-client-portal-current-password"
             value={this.state.password}
             tabIndex={2}
             onChange={ event => this.setState({password: event.target.value}) } />
    );
  }

  renderLoginButton() {
    const { loginButtonProps, loginState } = this.props;
    const { username, password } = this.state;
    const disabled = (
      !username || !password
      || !loginButtonProps
      || !loginState || loginState.loginInProgress
    );
    const doNothing = () => {};
    const clickHandler = (disabled) ? doNothing : this.onClickLoginButton;
    return (
      <LoginButton id="login-button"
                   tabIndex={3}
                   clickHandler={clickHandler}>
        {this.props.loginButtonProps.children}
      </LoginButton>
    );
  }

  renderPasswordResetLink() {
    const clickHandler = () => { this.setState({showPasswordResetModal: true})};
    const { loginFailed, forgotPassword } = this.props;
    if (!!this.state.loginHasFailed)
      return(
        <div className="forgot-password desktopbodycopy-italic">
          {loginFailed}
          <br/ >
          <br />
          <a onClick={clickHandler}>{forgotPassword}</a>
        </div>
      );
    else
      return '';
  }

  renderPasswordResetModal() {
    const { username, showPasswordResetModal, passwordResetResultMessages } = this.state;
    if (!!showPasswordResetModal) {
      const eMailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const isValidEmailAddress = !!(!!username && username.length > 0 && eMailRegExp.test(username.toLowerCase()));

      let fragment = '';
      if (!!passwordResetResultMessages && passwordResetResultMessages.length > 0) {
        fragment = (
          <React.Fragment>
            <div className="modal-content-item">
              <a className="subject-header">Password reset e-mail has been requested</a><br/>
              <a className="content-item">
                {passwordResetResultMessages.map((s, idx) => <span key={`password-reset-message-${idx}`}>{s}<br /></span>)}
              </a>
            </div>
            <div className="registration-link-modal-footer"
                 onClick={() => this.closePasswordResetModal()}>(click to close)
            </div>
          </React.Fragment>
        );
      }
      else if (isValidEmailAddress) {
        const { retrievePasswordResetTokenInProgress } = this.state;
        fragment = (
          <React.Fragment>
            <div className="modal-content-item">
              <a className="subject-header">Send password reset link?</a><br/>
              <a className="content-item">Please confirm if you want to send a password reset link to {username}<br /></a>
            </div>
            <div className="registration-link-modal-footer">
              <div className="modal-confirm" disabled={!!retrievePasswordResetTokenInProgress ? 'disabled': null} onClick={() => this.sendPasswordResetToken(username)}>Yes</div>
              <div className="modal-cancel" disabled={!!retrievePasswordResetTokenInProgress ? 'disabled': null} onClick={() => this.closePasswordResetModal()}>No</div>
            </div>
          </React.Fragment>
        );
      }
      else {
        fragment = (
          <React.Fragment>
            <div className="modal-content-item">
              <a className="subject-header">Check E-mail address</a><br/>
              <a className="content-item">The username you supplied is not a valid e-mail address.</a>
            </div>
            <div className="registration-link-modal-footer"
                 onClick={() => this.closePasswordResetModal()}>(click to close)
            </div>
         </React.Fragment>
        );
      }

      return (
        <div className="client-dashboard-modal password-change-confirmation-modal" >
          {fragment}
        </div>
      );
    }
    else
      return '';
  }

  closePasswordResetModal() {
    this.setState({
      showPasswordResetModal: false,
      passwordResetResultMessages: null,
    });
  }

  render() {
    const usernameClasses = this.state.loginHasFailed ? "username username-failed" : "username";
    const passwordClasses = this.state.loginHasFailed ? "password password-failed" : "password";
    return (
      <div className="container-center-horizontal">
        <div className="login-screen screen">
          <div className="proposition-summary">
            <div className="overlap-group-login-left">
              <WelcomeHeader />
              <IntroText textLines={this.props.introText} />
              <div className="login-and-discover-the-portal">{this.props.loginAndDiscoverThePortal}</div>
            </div>
          </div>
          <div className="login-area">
            <form>
              <div className="overlap-group-login-right">
                <div className="login ptserif-bold-black-xl">{this.props.login}</div>
                <div className="please-enter-your-username-and-password ptserif-normal-black-l">
                  {this.props.pleaseEnterYourUsernameAndPassword}
                </div>
                <div className={usernameClasses}>
                  <input type="text"
                         className="username-input desktopbodycopy"
                         tabIndex={1}
                         autoFocus
                         value={this.state.username}
                         autoComplete="compass-client-portal-username"
                         onChange={ event => this.setState({username: event.target.value}) } />
                  <img className="profile-filled-2" src="/img/profile---filled-2@2x.svg" />
                </div>
                <div className={passwordClasses}>
                  { this.renderPasswordInputField() }
                  <img className="vector-30" src="/img/vector-41@2x.svg" onClick={this.togglePasswordVisibility } />
                </div>
                { this.renderPasswordResetLink() }
                { this.renderLoginButton() }
                <img className="logo-compass-group-login-screen" src={this.props.logoCompassGroup_Zwart_Goud_Rgb1} />
              </div>
            </form>
          </div>
          { this.renderPasswordResetModal() }
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { login } = state;
  return {
    loginState: login
  };
}

export default connect(mapStateToProps)(LoginScreen);
