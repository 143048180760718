const defaultState = {
  desired: false,
  inProgress: false,
  username: null,
  currentUser: null
};

export function passwordChangeReducer(state = defaultState, action) {
  switch (action.type) {

    case 'PASSWORD_CHANGE_INITIATED_USER':
    case 'PASSWORD_CHANGE_INITIATED_SERVER':
      let username;
      if (action.payload.cognitoUser)
        username = action.payload.cognitoUser.username;
      else if (action.payload.username)
        username = action.payload.username;
      else
        username = null;

      if (username) {
        console.log(`Initiate password change for user \'${username}\'`);
        return {
          ...state,
          desired: true,
          username: username,
          currentUser: action.payload.cognitoUser,
        };
      }
      else {
        // Unable to determine the username for which the password change is requested. Do not change state.
        return state;
      }

    case 'PASSWORD_CHANGE_REQUEST_SENT':
      console.log(`Request password change for user \'${state.username}\'`);
      return {
        ...state,
        inProgress: true,
      };

    case 'PASSWORD_CHANGE_SUCCEEEDED':
      return {
        ...state,
        desired: false,
        inProgress: false
      };

    case 'PASSWORD_CHANGE_FAILED':
      return {
        ...state,
        inProgress: false
      };

    default:
      return state;
  }
}
