import React from "react";
import Elevation1Radius10 from "../Elevation1Radius10";
import InnerSubjects from "../InnerSubjects";
import "./BoxWithTitle.css";

function BoxWithTitle(props) {
  const { className, elevation1Radius10Props, innerSubjectsProps } = props;

  return (
    <div className={`box-with-title ${className || ""}`}>
      <Elevation1Radius10 className={elevation1Radius10Props.className} />
      <InnerSubjects desktopH4Header={innerSubjectsProps.desktopH4Header}
                     className={innerSubjectsProps.className} />
    </div>
  );
}

export default BoxWithTitle;
