const defaultState = {
  contract_nr: null,
  contract_name: null,
  segment: null,
  contract_start_date: null,
  contract_end_date: null,
  rm_name: null
};

export function clientContractAttributesReducer(state = defaultState, action) {
  switch (action.type) {
    case 'GET_CLIENT_CONTRACT_ATTRIBUTES_INITIATED':
      return {
        ...state,
      };

    case 'GET_CLIENT_CONTRACT_ATTRIBUTES_SUCCEEDED':
      const {
        contract_nr,
        contract_name,
        segment,
        contract_start_date,
        contract_end_date,
        rm_name
      } = action.payload;
      return {
        ...state,
        contract_nr,
        contract_name,
        segment,
        contract_start_date,
        contract_end_date,
        rm_name,
      };

    case 'GET_CLIENT_CONTRACT_ATTRIBUTES_FAILED':
      return {
        ...state,
        ...defaultState,
      };

    default:
      return state;
  }
}
