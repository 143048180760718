const defaultState = {
  reports: null,
};

export function dashboardAttributesReducer(state = defaultState, action) {
  switch (action.type) {

    case 'GET_DASHBOARD_ATTRIBUTES_INITIATED':
      return state;

    case 'GET_DASHBOARD_ATTRIBUTES_SUCCEEDED':
      const { dashboard_attributes } = action.payload;
      return {
        ...state,
        reports: dashboard_attributes
      };

    case 'GET_DASHBOARD_ATTRIBUTES_FAILED':
      return {
        ...state,
        ...defaultState
      };

    default:
      return state;
  }
}
