import './MultiSelectDDL.css';

import React, { useState } from "react";

import {
  Checkbox,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  FormControl,
  Select
} from '@mui/material';


class MultiSelectDDL extends React.Component {
  SelectAllItemValue = "<all-elements>";

  constructor(props) {
    super()

    this.props = props;
    this.state = {
      selectedOptions: this.parseInitialSelection(props.options, props.initialSelection),
    };

    this.isItemSelected = (option) => this.state.selectedOptions.includes(option);
    this.areAllItemsSelected = () => {
      const { options } = this.props;
      const { selectedOptions } = this.state;
      return (!!options && options.length > 0 && !!selectedOptions && selectedOptions.length === options.length);
    };

    this.handleValueChange = (event) => {
      // Parameter event.value contains the array of selected options in the <Select> control
      const { value } = event.target;
      let newSelection = value || [];

      // Apply filter: if an option is not available for selection it is removed from the list of selected options
      const { optionsFilterFn } = this.props;
      if (!!optionsFilterFn)
        newSelection = newSelection.filter((option) => optionsFilterFn(option.value));

      // Apply the single selection restriction is that was specified on this component
      const { restrictToSingleSelect } = this.props;
      if (!!restrictToSingleSelect)
        newSelection = newSelection.slice(-1);

      // Check if the "select all" option was clicked. That has a different behaviour (all/none)
      if (!!value && value.length > 0 && value[value.length - 1] === this.SelectAllItemValue) {
        newSelection = (this.areAllItemsSelected()) ? [] : this.props.options;
      }

      // Update component state
      this.setState({selectedOptions: newSelection});

      // Expose state also to the parent component
      if (!!props.onChange) {
        const dataToExpose = newSelection.map((option) => option.value);
        props.onChange(dataToExpose);
      }
    };
  }

  parseInitialSelection(options, initialSelection) {
    let selectedValues = [];
    if (!!initialSelection) {
      if (typeof(initialSelection) === 'string') {
        selectedValues = initialSelection.split(',').map((s) => s.trim());
      }
      else if (Array.isArray(initialSelection)) {
        selectedValues = initialSelection.map((item) => item.toString());
      }
      else
        selectedValues = [];
    }
    return options.filter((option) => !!option.value && selectedValues.includes(option.value.toString()));
  }

  serializeOptions(options) {
    // Returns a representation of the selected options to show in the top of the DDL
    return (!!options)
      ? options.map((option) => option.displayText || option.value).join(', ')
      : '';
  }

  serializeListItem(option, isSelectable) {
    const textValue = option.displayText || option.value;
    if (isSelectable)
      return textValue;
    else
      return <i className='multiselect-ddl-unavailable-option'>{textValue}</i>;
  }

  componentDidMount() {
    // Initial value change after initialization
    const { options, initialSelection } = this.props;
    const selectedItems = this.parseInitialSelection(options, initialSelection);
    const initialValueChangeEvent = {
      target: {
        value: selectedItems
      }
    };
    this.handleValueChange(initialValueChangeEvent);
  }

  render() {
    const { contextKey, title, options, optionsFilterFn, restrictToSingleSelect } = this.props;
    const { selectedOptions } = this.state;

    const isAllSelected = this.areAllItemsSelected();
    const firstMenuItemClassName = (isAllSelected) ? "selected-all-on": "selected-all-off";
    const selectAllMenuItem = (!restrictToSingleSelect)
      ? (<MenuItem id="menu-item-1-select-all"
                   className={firstMenuItemClassName}
                   value={this.SelectAllItemValue} >
           <ListItemIcon>
             <Checkbox checked={isAllSelected} />
           </ListItemIcon>
           <ListItemText className="emphasize-first-row"
                         primary="Select All" />
         </MenuItem>)
      : null;
    const checkboxItem = (option, itemIsSelectable) => (!restrictToSingleSelect)
      ? (<ListItemIcon><Checkbox className={itemIsSelectable ? '' : 'multiselect-ddl-unavailable-option'}
                   checked={this.isItemSelected(option)} /></ListItemIcon>)
      : null;

    const rootId = `multiselect-ddl-root-${contextKey}`;
    const labelId = `multiselect-ddl-label-${contextKey}`;

    return (
      <div className="multiselect-ddl-container">
        <FormControl id={rootId}>
          <InputLabel id={labelId}>{title}</InputLabel>
          <Select labelId={labelId}
                  multiple
                  value={selectedOptions}
                  onChange={this.handleValueChange}
                  renderValue={() => this.serializeOptions(selectedOptions)}
                  className="multiselect-ddl-border">
            {selectAllMenuItem}
            {options.map((option) => {
              const itemIsSelectable = (!optionsFilterFn) || optionsFilterFn(option.value);
              return (
                <MenuItem key={option.key}
                          value={option}>
                  {checkboxItem(option, itemIsSelectable)}
                  <ListItemText primary={this.serializeListItem(option, itemIsSelectable)} />
                </MenuItem>
              );
            }
            )}
          </Select>
        </FormControl>
      </div>
    );
  }
}

export default MultiSelectDDL;
