import { getData } from "./serviceClient";


export async function listOrganizations(appToken) {
  return await getData('manager', 'organizations/list', appToken);
}

export async function saveOrganization(appToken, organization) {
  return await getData(
    'manager',
    'organizations/save',
    appToken,
    {organization}
  );
}

export async function deleteOrganization(appToken, organization_id) {
  return await getData(
    'manager',
    'organizations/delete',
    appToken,
    {organization_id}
  );
}

export async function listAccountManagers(appToken, organization_ids) {
  return await getData(
    'manager',
    'organizations/list-ahm',
    appToken,
    {organization_ids}
  );
}
