export function userRegistrationInitiated() {
  return {
    type: 'USER_REGISTRATION_INITIATED'
  };
}

export function userRegistrationSucceeded(username, password) {
  return {
    type: 'USER_REGISTRATION_SUCCEEDED',
    payload: {
      username: username,
      initialPassword: password
    }
  };
}

export function userRegistrationFailed() {
  return {
    type: 'USER_REGISTRATION_FAILED'
  };
}
