import "./PerformanceDashboard.css";

import React from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import Achtergrond from "../Achtergrond";
import Header from "../Header";
import LeftNavigationBar from "../LeftNavigationBar";
import TypeTitles from "../TypeTitles";
import PowerBIApplication from "../PowerBIApplication";


class PerformanceDashboard extends React.Component {
  constructor(props) {
    super();

    this.props = props;
    this.state = {
      selectedReportId: null,
    };

    this.getReportAttributes = this.getReportAttributes.bind(this);
    this.selectReport = this.selectReport.bind(this);
    this.showReportsSelector = this.showReportsSelector.bind(this);
    this.renderPlaceHolderText = this.renderPlaceHolderText.bind(this);
    this.renderReportsSelector = this.renderReportsSelector.bind(this);
    this.renderReportsSelectorItem = this.renderReportsSelectorItem.bind(this);
    this.renderPowerBIReport = this.renderPowerBIReport.bind(this);
  }

  getReportAttributes(reportId) {
    const { dashboardAttributes } = this.props;
    const reports = (dashboardAttributes || {}).reports || [];
    return reports.find((report) => report.powerbi_report_id === reportId);
  }

  selectReport(reportId) {
    this.setState({selectedReportId: reportId});
  }

  showReportsSelector() {
    const { dashboardAttributes } = this.props;
    const reports = (dashboardAttributes || {}).reports;
    return (!!reports && reports.length > 1);
  }

  renderPlaceHolderText() {
    const { dashboardAttributes } = this.props;
    const reports = (dashboardAttributes || {}).reports;
    const { selectedReportId } = this.state;

    let messageText;
    if (reports == null) {
      messageText = 'Configuring BI dashboard connection...';
    }
    else if (reports.length > 1 && selectedReportId == null) {
      messageText = 'Please select your report...';
    }
    else {
      messageText = null;
    }

    if (!!messageText) {
      return (
        <div className="reports-message-text">
          <h3 className="ptserif-normal-black-xs">
          {messageText}
          </h3>
        </div>
      );
    }
    else {
      return '';
    }
  }

  renderReportsSelectorItem(reportSpec, idx) {
    const reportId = reportSpec.powerbi_report_id;
    return (
      <li key={`report-item-${idx}`}
          className="reports-list-item"
          onClick={() => this.selectReport(reportId)} >
          {reportSpec.powerbi_report_name}
      </li>
    );
  }

  renderReportsSelector() {
    const { dashboardAttributes } = this.props;
    const reports = (dashboardAttributes || {}).reports || [];
    if (reports.length > 1) {
      const listItems = reports.map(
        (reportSpec, idx) => this.renderReportsSelectorItem(reportSpec, idx)
      );

      return (
        <div className="reports-list-container">
          <ul className="reports-list">
            { listItems }
          </ul>
        </div>
      );
    }
    else {
      return '';
    }
  }

  renderPowerBIReport() {
    const { dashboardAttributes } = this.props;
    const { selectedReportId } = this.state;
    const reports = (dashboardAttributes || {}).reports || [];

    // Get attributed for selected PowerBI report || the only available PowerBI report if there is only one available
    let reportAttributes;
    if (!!selectedReportId) {
      reportAttributes = this.getReportAttributes(selectedReportId);
      console.log(`Selected PowerBI report: ${reportAttributes.powerbi_report_name}`);
    }
    else if (reports.length == 1) {
      reportAttributes = reports[0];
    }
    else {
      reportAttributes = null;
    }

    if(!!reportAttributes) {
      const positionClassName = this.showReportsSelector() ? 'push-down-7' : '';
      return (<PowerBIApplication position={positionClassName} reportAttributes={reportAttributes} />);
    }
    else {
      return '';
    }
  }

  render() {
    const {
      achtergrondProps,
      headerProps,
      typeTitlesProps,
      dashboardAttributes,
    } = this.props;

    const placeHolder = this.renderPlaceHolderText();
    const reportsSelector = this.renderReportsSelector();
    const powerBIReport = this.renderPowerBIReport();

    return (
      <div className="performance-dashboard container-center-horizontal screen">
        <div className="overlap-group-main">
          <Achtergrond className={achtergrondProps.className} />
          <Header header2Props={headerProps.header2Props} />
          <div>
          </div>
          <LeftNavigationBar />
          {reportsSelector}
          {placeHolder}
          {powerBIReport}
        </div>
        <TypeTitles
          subtitle={typeTitlesProps.subtitle}
          className={typeTitlesProps.className}
        />
      </div>
    );
  }
}


function mapStateToProps(state) {
  /* Return a dict with the relevant state info to pass into the props for this component */
  const { dashboardAttributes } = state;
  return {
    dashboardAttributes
  };
}

export default connect(mapStateToProps)(PerformanceDashboard);
