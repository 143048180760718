import React from "react";
import "./FillInBox.css";

function FillInBox(props) {
  const { className } = props;

  return <div className={`fill-in-box-2 border-1px-teak ${className || ""}`}></div>;
}

export default FillInBox;
