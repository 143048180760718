import React from "react";
import { createRoot } from 'react-dom/client';
import "./static/css/globals.css";
import "./static/css/colors.css";
import "./static/css/fonts.css";
import "./static/css/borders.css";

import App from "./App";

const rootElement = createRoot(document.getElementById("app"));
rootElement.render(<App />);
