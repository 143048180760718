import { getApiUrl } from "./serviceDefinitions";

export async function getApplicationToken(cognitoToken) {
  const url = getApiUrl('public', 'token');
  var fetchArgs = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    redirect: 'follow',
    body: JSON.stringify({'cognito_token': cognitoToken})
  };

  const response = await fetch(url, fetchArgs);
  const responseBody = await response.json();

  if (!!responseBody.application_token) {
    console.log('Application security token issued');
    return {
      applicationToken: responseBody.application_token,
      errorMessage: responseBody.message,
    }
  }
  else {
    return {
      applicationToken: null,
      errorMessage: responseBody.message,
    }
  }
}
