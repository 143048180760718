import "./Header.css";

import React from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import { userLoggedOut } from '../../state/actions/login';
import store from '../../state/store';


class Header extends React.Component {
  constructor(props) {
    super();
    this.props = props;
  }

  dispatchLogout() {
    console.log('Logging out');
    store.dispatch(userLoggedOut('User logged out'));
  }

  render() {
    const { header2Props, login, token, profile } = this.props;
    const { errorMessage } = (token || {});
    const { employee } = (profile || {});
    const name = (!!employee) ? `${employee.firstName} ${employee.lastName}` : (!!login) ? login.loggedInUser : '';
    const title = !!(employee) ? employee.functionTitle : errorMessage : '';

    return (
      <div className="header">
        <div className="header-full">
          <Link to="/home">
            <img className="logo-compass-group_zwart_rgb-1-1" src="/img/logo-compass-group-zwart-rgb-1@2x.png" />
          </Link>
          <a onClick={this.dispatchLogout}>
            <img className="vector-logout" src="/img/vector-4@2x.svg" />
          </a>
          <Link to="/profile">
            <img className="vector-profile" src="/img/vector-1@2x.svg" />
          </Link>
          <div className="user-name-function">
            <span className="calibri-normal-black-xs">
              <React.Fragment>
              {name}
              </React.Fragment>
            </span><br />
            <a className="span1-1 calibri-bold-black-xxs">{title}</a>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { login, token, profile } = state;
  return { login, token, profile };
}

export default connect(mapStateToProps)(Header);
