import React from "react";
import "./ViewAll.css";

function ViewAll(props) {
  const { className } = props;

  return (
    <div className={`view-all-3 ${className || ""}`}>
      <div className="view-all-2 desktopbodycopy">View all</div>
      <img className="arrow-forward-6" src="/img/arrow-forward-2@2x.svg" />
    </div>
  );
}

export default ViewAll;
