import React from "react";
import "./Achtergrond.css";

function Achtergrond(props) {
  const { className } = props;

  return <div className={`achtergrond ${className || ""}`}></div>;
}

export default Achtergrond;
