import React from "react";
import "./Frame1.css";

function Frame1(props) {
  const { desktopBodycopy20PtCalibriRegular, desktopBodycopy18PtCalibriRegular, className } = props;

  return (
    <div className={`frame-1-4 ${className || ""}`}>
      <div className="desktop-bodycopy-20pt-calibri-regular-2 desktopbodycopy">{desktopBodycopy20PtCalibriRegular}</div>
      <div className="desktop-bodycopy-18pt-calibri-regular-1 desktopbodycopy-s">
        {desktopBodycopy18PtCalibriRegular}
      </div>
    </div>
  );
}

export default Frame1;
