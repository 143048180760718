import { renderToString } from 'react-dom/server'

export function composeEmailSubject(attrs) {
  const { addressee } = attrs;
  return `${addressee.firstName}, you have been invited to the Compass Client Portal!`
}

export function composeEmailBody(attrs) {
  const { invitation, addressee, sender } = attrs;
  const senderOrganization = (!!sender.organizationName && !sender.organizationName.toLowerCase().startsWith('compass'))
    ? `${sender.organizationName} acting on behalf of Compass Group`
    : 'Compass Group';

  return renderToString(
    <html>
      <body style={{"backgroundColor": "#b89d5f59",
                    "color": "black",
                    "fontFamily": "Calibri-Regular",
                    "fontStyle": "italic",
                    "fontWeight": 400}} >
        <p>
          Dear {addressee.firstName}
        </p>
        <p style={{"marginTop": "1em"}}>
          You have been invited to join the Compass Client Portal.
        </p>
        <p style={{"marginTop": "1em"}}>
          Please follow the personal registration link provided below and complete your details to register.<br />
          Link: <a href={invitation.url}>Client Portal registration page</a>
        </p>
        <p style={{"marginTop": "1em"}}>
          This invitation expires on {invitation.expires}.<br />
          If you have any questions, please contact {sender.firstName} {sender.lastName} at {sender.eMailAddress}.
        </p>
        <p style={{"marginTop": "1em"}}>
          Sincerely,<br />
          {sender.firstName} {sender.lastName}<br />
          {senderOrganization}
        </p>
      </body>
    </html>
  );
}
