import React from "react";
import "./Frame4.css";

function Frame4(props) {
  const { text, explanation, className } = props;

  return (
    <div className={`frame-4 ${className || ""}`}>
      <div className="text ptserif-bold-husk-xl">{text}</div>
      <div className="explanation ptserif-normal-husk-l">{explanation}</div>
    </div>
  );
}

export default Frame4;
