import "./UsersOverview.css";

import React from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import { Button } from '@mui/material';
import { Email } from '@mui/icons-material';

import Achtergrond from "../Achtergrond";
import Header from "../Header";
import LeftNavigationBar from "../LeftNavigationBar";
import TypeTitles from "../TypeTitles";
import EditableTable from "../EditableTable";

import { sendEmail } from '../../api/email';
import { getRegistrationLink } from '../../api/registration';
import { listUsers, saveUser, deleteUser } from '../../api/users';
import { composeEmailBody, composeEmailSubject } from '../../helpers/email';
import { sortByAttribute } from '../../helpers/sorting';
import sleep from '../../helpers/sleep';
import { loadAuthorizationsAndProfileData } from '../../state/workflows/login';


class UsersOverview extends React.Component {
  // RegularUser (in the /clients page we manage PrincipalUser records. here in /users we manage RegularUser records)
  DefaultUserType = 'RU';

  constructor(props) {
    super();
    this.props = props;
    this.state = {
      users: null,
      listQueryInProgress: false,
      generatedRegistrationDetails: null,
      message: null,
      reportNames: null,
    };

    // Click handlers
    this.reloadUsers = this.reloadUsers.bind(this);
    this.saveUserRecord = this.saveUserRecord.bind(this);
    this.deleteUserRecord = this.deleteUserRecord.bind(this);
    this.sendRegistrationLink = this.sendRegistrationLink.bind(this);
    this.closeRegistrationLinkModal = this.closeRegistrationLinkModal.bind(this);
    this.closeMessageModal = this.closeMessageModal.bind(this);
    
    // Rendering functions
    this.renderUsersTable = this.renderUsersTable.bind(this);
    this.renderReloadButton = this.renderReloadButton.bind(this);
    this.renderRegistrationDetailsModal = this.renderRegistrationDetailsModal.bind(this);
    this.renderMessageModal = this.renderMessageModal.bind(this);

    // Helpers (only if they need access to props or other instance data)
    this.tableColumns = this.tableColumns.bind(this);
    this.editorSettings = this.editorSettings.bind(this);
    this.sortUsers = this.sortUsers.bind(this);
  }

  async reloadUsers() {
    // Get API credentials
    const { tokenState } = this.props;
    const { applicationToken } = tokenState;
    if (!applicationToken) {
      return;
    }

    // Perform API call and update state
    this.setState({listQueryInProgress: true});
    try {
      const response = await listUsers(applicationToken);
      const users = response['users/list'];
      if (!!users) {
        this.setState({users: this.sortUsers(users)});
      }
    }
    catch(err) {
      this.setState({message: err});
    }
    finally {
      this.setState({listQueryInProgress: false});
    }
  }

  async saveUserRecord(userObj) {

    // Get API credentials
    const { loginState, tokenState } = this.props;
    const { loggedInUser, userAttributes } = loginState;
    const { applicationToken } = tokenState;
    if (!loggedInUser || !userAttributes || !applicationToken) {
      return;
    }

    // Enrich the user object with extra context
    // * Use the field parent_user_id to refer to the currently logged in user
    const currentUserId = userAttributes.profile;

    // * Use the fields user_id, username and organization_id from the users as retrieved from the API
    //   (columns are not editable so these fields are missing from argument userObj which comes out of the editor)
    // * Field organization_id is taken from the parent user if it is missing (when saving a new user)
    const { users } = this.state;
    const userIdx = users.findIndex((u) => u.user_id === userObj.user_id);
    const userObjFromApi = users.find((u) => u.user_id === userObj.user_id) || {};
    const parentUserObj = users.find((u) => u.username.toLowerCase() === loggedInUser.toLowerCase()) || {};

    const userObjExtended = {
      ...userObj,
      user_id: userObj.user_id ?? userObjFromApi.user_id,
      username: userObjFromApi.username ?? userObj.email,
      parent_user_id: currentUserId,
      user_type: userObjFromApi.user_type ?? this.DefaultUserType,
      organization_id: userObjFromApi.organization_id ?? parentUserObj.organization_id,
      organization_name:userObjFromApi.organization_name ?? parentUserObj.organization_name,
    }

    // Strip a few generated fields.
    // 'User' is composed from many fields
    // 'User Type' is a dictionary lookup (i.e. AD -> admin)
    // 'Reports' is a lookup from reports names based on data field report_ids
    const generatedColumnsWithoutDataField = ['User', 'User Type', 'Reports'];
    const userObjToSave = Object.keys(userObjExtended)
      .filter(key => !generatedColumnsWithoutDataField.includes(key))
      .reduce((obj, key) => {
        obj[key] = userObjExtended[key];
        return obj;
      }, {});

    // Save object and update state
    try {
      const response = await saveUser(applicationToken, userObjToSave);
      const saveResult = (response || {})["users/save"] || {};
      if (saveResult.status == "saved") {
        const updatedUsers = users;
        if (userIdx >= 0 && userIdx < updatedUsers.length)
          updatedUsers[userIdx] = userObjExtended;  // edit existing user in the table
        else
          updatedUsers.push(userObjExtended); // add user to the table

        this.setState({
          users: this.sortUsers(updatedUsers),
          listQueryInProgress: true
        });
        await sleep(0);
        this.setState({listQueryInProgress: false});
        return saveResult.user_id;
      }
      else {
        throw new Error("API did not confirm save");
      }
    }
  }

  async deleteUserRecord(recordId) {
    const { tokenState } = this.props;
    const { applicationToken } = tokenState;
    if (!applicationToken) {
      return;
    }

    try {
      const response = await deleteUser(applicationToken, recordId);
      const deleteResult = (response || {})["users/delete"] || {};
      if (deleteResult.status == "deleted") {
        const remainingUsers = this.state.users.filter((user) => user.user_id != deleteResult.user_id);
        this.setState({
          users: this.sortUsers(remainingUsers),
          listQueryInProgress: true
        });
        await sleep(0);
        this.setState({listQueryInProgress: false});
        return deleteResult.user_id;
      }
      else {
        throw new Error("API did not confirm delete");
      }
    }
    catch(err) {
      this.setState({message: err});
    }
  }

  async sendRegistrationLink(table, row) {
    const { loginState, tokenState, myProfile, myOrganization } = this.props;
    const { userAttributes } = loginState;
    const { applicationToken } = tokenState;

    const clientContractNumbers = (row.original || {}).client_contract_numbers || [];
    const reportIds = (row.original || {}).report_ids || [];
    const tokenRequestPayload = {
      registration_token_attributes: {
        grantor_id: userAttributes.profile,
        organization_id: row.getValue('organization_id'),
        user_type: row.original["user_type"] || this.DefaultUserType,
        grantee_email: row.getValue('email'),
        client_contract_numbers: clientContractNumbers.slice(0, 10),
        report_ids: reportIds.slice(0, 5),
      }
    };

    try {
      // Create registration link
      const response = await getRegistrationLink(applicationToken, tokenRequestPayload);
      const registrationLink = response['registration/generate'];

      // Prepare e-mail to send
      const registrationDetails = {
        invitation: registrationLink,
        addressee: {
          firstName: row.getValue('first_name'),
          lastName: row.getValue('last_name'),
          organizationName: row.getValue('organization_name')
        },
        sender: {
          ...myProfile,
          organizationName: myOrganization.name
        }
      };

      const emailPayload = {
        recipient_address: registrationLink.email,
        message_subject: composeEmailSubject(registrationDetails),
        message_body: composeEmailBody(registrationDetails)
      };

      // Send e-mail
      const emailResponse = await sendEmail(applicationToken, emailPayload);
      const emailStatus = (emailResponse || {}).sendmail || {};
      if (emailStatus.result === "ok") {
        // Display pop-up
        this.setState({
          generatedRegistrationDetails: registrationDetails,
        });

        // Update the authorization state of the user from 'New' to 'Invited'
        const userObj = {
          ...row.original,
          authorization_status: 'Invited'
        }
        await this.saveUserRecord(userObj);

        this.setState({listQueryInProgress: true});
        const { users } = this.state;
        const updatedUserIdx = users.findIndex((user) => user.user_id === userObj.user_id);
        if (updatedUserIdx >= 0 && updatedUserIdx < users.length) {
          users[updatedUserIdx].authorization_status = userObj.authorization_status;
        }
        this.setState({users: this.sortUsers(users)});
        await sleep(0);
        this.setState({listQueryInProgress: false});
      }
      else {
        this.setState({
          generatedRegistrationDetails: null,
          message: `Failed! (${emailStatus.recipient})`
        });
      }
    }
    catch(error) {
      this.setState({
        generatedRegistrationDetails: null,
        message: `Failed! (${error})`
      });
    }
  };

  renderUsersTable() {
    const { dashboardAttributes, loginState } = this.props;
    const { loggedInUser } = loginState;

    // Users in the state are already sorted
    const { reportNames } = this.state;
    const users = (this.state.users || [])
      .map((userObj) => this.annotateReadOnlyFlag(userObj, loggedInUser))

    return (
      <EditableTable tableId="users-table"
                     className="users-table"
                     recordTypeName="user"
                     columns={this.tableColumns()}
                     data={users}
                     settings={this.editorSettings()} />
    );
  }

  renderPlaceHolder() {
    return (
      <div className="users-table-placeholder">Client portal users loading...</div>
    );
  }

  renderReloadButton() {
    return (
      <Button variant="contained"
              className="reload-users-button"
              style={{backgroundColor: "var(--teak)"}}
              onClick={this.reloadUsers}>
        Reload users
      </Button>
    );
  }

  renderRegistrationDetailsModal() {
    const { generatedRegistrationDetails } = this.state;
    if (!!generatedRegistrationDetails) {
      const { invitation, sender, addressee } = generatedRegistrationDetails;
      const addresseeText = `${addressee.firstName} ${addressee.lastName} < ${invitation.email}>`
      return (
        <div className="registration-link-modal" >
          <div className="registration-link-item">
            <a className="subject-header">Sent E-mail message to:</a><br/>
            <a className="content-item">{addresseeText}<br /></a>
          </div>
          <div className="registration-link-item">
            <a className="subject-header">Link URL:</a><br/>
            <a className="content-item">{invitation.url}<br /></a>
          </div>
          <div className="registration-link-item">
            <a className="subject-header">Expires:</a><br/>
            <a className="content-item">{invitation.expires || '-'}</a>
          </div>
          <div className="registration-link-modal-footer"
               onClick={() => this.closeRegistrationLinkModal()}>(click to close)</div>
        </div>
      );
    }
    else
      return '';
  }

  closeRegistrationLinkModal() {
    this.setState({
      generatedRegistrationDetails: null
    });
  }

  renderMessageModal() {
    const { message } = this.state;
    if (!!message) {
      setTimeout(this.closeMessageModal, 2000);
      return (
        <div className="message-modal" onClick={() => this.closeMessageModal()} >
          { message }
        </div>
      );
    }
    else {
      return '';
    }
  }

  closeMessageModal() {
    this.setState({message: null});
  }

  static getDerivedStateFromProps(props, state) {
    const reports = (props.dashboardAttributes || {}).reports || [];
    const reportNames = Object.fromEntries(
      reports.map((report) => [report.powerbi_report_id, report.powerbi_report_name])
    );

    return { reportNames };
  }

  render() {
    const { headerProps, typeTitlesProps } = this.props;
    const { users, listQueryInProgress } = this.state;
    const typeTitlesClassName = `${(typeTitlesProps || {}).className || ''} selector-pos-col-1`;

    const tableElement = (!!users && !listQueryInProgress) ? this.renderUsersTable() : this.renderPlaceHolder();
    const reloadButton = this.renderReloadButton();
    const registrationDetailsModal = this.renderRegistrationDetailsModal();
    const messageModal = this.renderMessageModal();

    return (
      <div className="container-center-horizontal">
        <div className="users-overview-page screen">
          <div className="overlap-group-users-overview">
            <Achtergrond />
            <Header header2Props={headerProps.header2Props} />
            <LeftNavigationBar />
            <div className="users-table-container">
              { tableElement }
              { reloadButton }
            </div>
            { registrationDetailsModal }
            { messageModal }
          </div>
          <TypeTitles subtitle={typeTitlesProps.subtitle} className={typeTitlesClassName} />
        </div>
      </div>
    );
  }

  async componentDidMount() {
    // This will load the users for the table
    await this.reloadUsers();

    // This will refresh the current user's data in the redux store (i.e. which report IDs you can assign etc)
    const { cognitoAuthenticationToken } = this.props.tokenState;
    await loadAuthorizationsAndProfileData(cognitoAuthenticationToken);
  }

  // Helpers
  tableColumns() {
    const { myOrganization, myRoles, dashboardAttributes } = this.props;
    const currentUserIsAdmin = (myRoles || {}).isAdmin;

    const clientContractsInThisOrganization = sortByAttribute(
      (myOrganization || {}).availableClientContracts || [],
      'contract_name',
      false
    );
    const myClientContractNumbers = ((myOrganization || {}).clientContractNumbers || []).map((cc) => Number(cc));
    const clientContractsAvailableToAssign = clientContractsInThisOrganization
      .filter((contract) => !!currentUserIsAdmin || myClientContractNumbers.includes(contract.contract_nr));

    const availableReportsForThisParentUser = sortByAttribute(
      (dashboardAttributes || {}).reports || [],
      'powerbi_report_name',
      false
    );

    const columns = [
      {
        accessorKey: 'user_id',
        header: 'User ID',
        enableEditing: false,
        hiddenByDefault: true, // custom attribute
        isPrimaryKey: true, // custom attribute
        isAutoGenerated: true, // custom attribute
      },
      {
        accessorKey: 'username',
        header: 'Username',
        enableEditing: false,
        hiddenByDefault: true, // custom attribute
      },
      {
        header: 'User',
        accessorFn: (row) => (
          <div className="nested-table-cell">
            {row.first_name} {row.last_name}<br />
            {row.function}<br />
            {row.organization_name}
          </div>
        ),
        enableEditing: false,
        size: 250,
        hiddenByDefault: false, // custom attribute
        isAutoGenerated: true, // custom attribute
        filterFn: (row, column, filterExpresssion) => {
          const { first_name, last_name, organization_name } = row.original;
          const function_name = row.original.function;
          const allText = `${first_name} ${last_name} ${function_name} ${organization_name}`;
          return allText.toLowerCase().includes((filterExpresssion || '').toLowerCase());
        },
      },
      {
        header: 'Name',
        accessorKey: 'full_name',
        accessorFn: (row) => `${row.first_name} ${row.last_name}`,
        enableEditing: false,
        size: 150,
        isAutoGenerated: true, // custom attribute
        isPrimaryName: true, // custom attribute
        hiddenByDefault: true, // custom attribute
      },
      {
        header: 'First Name',
        accessorKey: 'first_name',
        hiddenByDefault: true, // custom attribute
      },
      {
        header: 'Last Name',
        accessorKey: 'last_name',
        hiddenByDefault: true, // custom attribute
      },
      {
        header: 'E-mail',
        accessorKey: 'email',
        size: 150,
        validationType: 'email',
        enableEditing: false,
        enableEditingForNewRecords: true, // custom attribute
      },
      {
        accessorKey: 'organization_id',
        header: 'Organization ID',
        enableEditing: false,
        hiddenByDefault: true, // custom attribute
      },
      {
        accessorKey: 'organization_name',
        header: 'Organization',
        size: 150,
        enableEditing: false,
        hiddenByDefault: true, // custom attribute
      },
      {
        accessorKey: 'function',
        header: 'Function',
        size: 150,
        enableEditing: true,
        hiddenByDefault: true, // custom attribute
      },
      {
        header: 'User Type',
        accessorFn: (row) => this.serializeUserType(row),
        enableEditing: false,
        size: 80,
        isAutoGenerated: true, // custom attribute
      },
      {
        accessorKey: 'client_contract_numbers',
        header: 'Client Contracts',
        size: 220,
        isArrayField: true, // custom attribute
        options: clientContractsAvailableToAssign.map(
          (cc) => {
            return {
              key: cc.contract_nr,
              value: cc.contract_nr,
              displayText: `${cc.contract_name} (${cc.contract_nr})`,
            };
          }
        ),
      },
      {
        accessorKey: 'report_ids',
        header: 'Report IDs',
        size: 220,
        isArrayField: true, // custom attribute
        hiddenByDefault: true, // custom attribute
        options: availableReportsForThisParentUser.map(
          (report) => {
            return {
              key: report.powerbi_report_id,
              value: report.powerbi_report_id,
              displayText: report.powerbi_report_name,
            };
          }
        ),
      },
      {
        accessorFn: (row) => (row.report_ids || [])
          .map((reportId) => (this.state.reportNames || {})[reportId])
          .filter((reportName) => !! reportName)
          .sort()
          .join(', '),
        header: 'Reports',
        size: 220,
        enableEditing: false,
        isAutoGenerated: true, // custom attribute
        hiddenByDefault: false, // custom attribute
      },
      {
        accessorKey: 'active',
        header: 'Access',
        size: 50,
        enableEditingAsBoolean: true, // custom attribute
      },
      {
        accessorKey: 'authorization_status',
        header: 'Status',
        size: 150,
        enableEditing: false,
      },
    ];

    // Only admin users see the "protect" column
    // (because it only applies to A&H manager users, which are maintained by the admins)
    if (currentUserIsAdmin) {
      columns.push(
        {
          accessorKey: 'protect_auto_deactivation',
          header: 'Protect',
          size: 50,
          enableEditingAsBoolean: true,
        },
      );
    }

    return columns;
  }

  editorSettings() {
    return {
      canCreateNewRow: true,
      createNewRowText: "New user",
      canEditExistingRow: true,
      editExistingRowText: "Edit user",
      canDeleteExistingRow: true,
      deleteExistingRowText: "Delete user",
      saveHandler: this.saveUserRecord,
      deleteHandler: this.deleteUserRecord,
      extraActions: [
        {
          title: "Send registration link",
          renderIcon: () => (<Email />),
          clickHandler: this.sendRegistrationLink,
        }
      ],
    };
  }

  annotateReadOnlyFlag(userObj, loggedInUser) {
    // The user object representing the currently logged in user receives a "readOnly" flag
    return {
      ...userObj,
      readOnly: userObj.username === loggedInUser
    };
  }

  filterUsers(users) {
    // Pseudo-users like 'new[AM]' excluded from table
    var r = new RegExp("^new\\[([A-Z]{2})\\]$");
    return users.filter((user) => !!user && !r.test(user.user_id));
  }

  sortUsers(users) {
    // Current user comes first. Then sort by first name.
    const loggedInUser = (this.props.loginState || {}).loggedInUser;
    const currentUsers = users.filter((userObj) => userObj.username == loggedInUser);
    const otherUsers = this.filterUsers(users.filter((userObj) => userObj.username != loggedInUser));
    return [
      ...sortByAttribute(currentUsers, 'first_name', false),
      ...sortByAttribute(otherUsers, 'first_name', false)
    ];
  }

  serializeUserType(userObj) {
    const { user_type } = userObj;
    if (user_type == 'AD')
      return 'admin';
    else if (user_type == 'AM')
      return 'A&H manager';
    else if (user_type == 'PU')
      return 'principal';
    else if (user_type == 'RU')
      return 'user';
    else
      return null;
  }
}

function mapStateToProps(state) {
  /* Return a dict with the relevant state info to pass into the props for this component */
  const { login, token, profile, clientPortalUsers, dashboardAttributes } = state;
  return {
    loginState: login,
    tokenState: token,
    myProfile: (profile || {}).employee,
    myOrganization: (profile || {}).organization,
    myRoles: (profile || {}).roles,
    dashboardAttributes
  };
}

export default connect(mapStateToProps)(UsersOverview);
