import { getData } from "./serviceClient";

const EnableEmail = true;  // set to false for testing without sending real e-mails

export async function sendEmail(appToken, eMailArgs) {
  if (EnableEmail) {
    return await getData('principal', 'sendmail', appToken, eMailArgs);
  }
  else {
    return {sendmail: {result: 'ok', recipient: eMailArgs.recipient_address}};
  }
}
