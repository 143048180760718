export function userLoginInitiated() {
  return {
    type: 'USER_LOGIN_INITIATED'
  };
}

export function userLoginSucceeded(username, token, userAttributes) {
  return {
    type: 'USER_LOGIN_SUCCEEDED',
    payload: {
      loggedInUser: username,
      authToken: token,  // token in this scope is the AWS Cognito token, not the application token
      userAttributes: userAttributes
    }
  };
}

export function userLoginFailed() {
  return {
    type: 'USER_LOGIN_FAILED'
  };
}

export function userLoggedOut(message) {
  return {
    type: 'USER_LOGGED_OUT',
    payload: { message }
  };
}
