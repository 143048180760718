import "./SLAParameters.css";

import React from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import Achtergrond from "../Achtergrond";
import CancelAndSaveChanges2 from "../CancelAndSaveChanges2";
import Elevation1Radius10 from "../Elevation1Radius10";
import Frame1 from "../Frame1";
import FillInBox from "../FillInBox";
import Header from "../Header";
import InnerContent2 from "../InnerContent2";
import InnterContent3 from "../InnterContent3";
import InnerSubjects from "../InnerSubjects";
import LeftNavigationBar from "../LeftNavigationBar";
import TypeTitles from "../TypeTitles";

class SLAParameters extends React.Component {
  constructor(props) {
    super();
    this.props = props;
  }

  render() {
    const {
      rectangle42,
      number1,
      percent1,
      percent2,
      companyAbc,
      locationZ,
      number2,
      fillInToKnowYourCaptureRate,
      logoCompassGroup_Zwart_Rgb1,
      achtergrondProps,
      elevation1Radius101Props,
      innterContent31Props,
      frame11Props,
      frame12Props,
      frame13Props,
      frame14Props,
      frame15Props,
      headerProps,
      innterContent32Props,
      fillInBox1Props,
      frame16Props,
      innterContent33Props,
      innterContent34Props,
      frame17Props,
      fillInBox2Props,
      cancelAndSaveChanges21Props,
      innerSubjects1Props,
      elevation1Radius102Props,
      innerContent2Props,
      cancelAndSaveChanges22Props,
      innerSubjects2Props,
      typeTitlesProps,
      profile
    } = this.props;

    return (
      <div className="container-center-horizontal">
        <div className="sla-parameters-1 screen">
          <div className="overlap-group6-1">
            <Achtergrond className={achtergrondProps.className} />
            <img className="rectangle-42" src={rectangle42} />
            <div className="performance-objectives">
              <div className="overlap-group3-4">
                <Elevation1Radius10 className={elevation1Radius101Props.className} />
                <div className="sustainbility">
                  <InnterContent3
                    desktopBodycopy20PtCalibriRegular={innterContent31Props.desktopBodycopy20PtCalibriRegular}
                  />
                  <div className="benchmark-year">
                    <Frame1
                      desktopBodycopy20PtCalibriRegular={frame11Props.desktopBodycopy20PtCalibriRegular}
                      desktopBodycopy18PtCalibriRegular={frame11Props.desktopBodycopy18PtCalibriRegular}
                    />
                    <div className="overlap-group-19">
                      <div className="number-6 valign-text-middle desktopbodycopy">{number1}</div>
                      <FillInBox />
                    </div>
                  </div>
                  <img className="line-16" src="/img/line-16@2x.svg" />
                  <div className="food-waste">
                    <Frame1
                      desktopBodycopy20PtCalibriRegular={frame12Props.desktopBodycopy20PtCalibriRegular}
                      desktopBodycopy18PtCalibriRegular={frame12Props.desktopBodycopy18PtCalibriRegular}
                    />
                    <div className="overlap-group-20">
                      <div className="percent valign-text-middle desktopbodycopy">{percent1}</div>
                      <FillInBox />
                    </div>
                  </div>
                  <div className="food">
                    <Frame1
                      desktopBodycopy20PtCalibriRegular={frame13Props.desktopBodycopy20PtCalibriRegular}
                      desktopBodycopy18PtCalibriRegular={frame13Props.desktopBodycopy18PtCalibriRegular}
                    />
                    <div className="group-30 border-1px-teak"></div>
                  </div>
                  <div className="food">
                    <Frame1
                      desktopBodycopy20PtCalibriRegular={frame14Props.desktopBodycopy20PtCalibriRegular}
                      desktopBodycopy18PtCalibriRegular={frame14Props.desktopBodycopy18PtCalibriRegular}
                    />
                    <div className="group-31 border-1px-teak"></div>
                  </div>
                </div>
                <div className="scr">
                  <Frame1
                    desktopBodycopy20PtCalibriRegular={frame15Props.desktopBodycopy20PtCalibriRegular}
                    desktopBodycopy18PtCalibriRegular={frame15Props.desktopBodycopy18PtCalibriRegular}
                    className={frame15Props.className}
                  />
                  <InnterContent3
                    desktopBodycopy20PtCalibriRegular={innterContent32Props.desktopBodycopy20PtCalibriRegular}
                    className={innterContent32Props.className}
                  />
                  <FillInBox className={fillInBox1Props.className} />
                </div>
                <div className="products">
                  <Frame1
                    desktopBodycopy20PtCalibriRegular={frame16Props.desktopBodycopy20PtCalibriRegular}
                    desktopBodycopy18PtCalibriRegular={frame16Props.desktopBodycopy18PtCalibriRegular}
                    className={frame16Props.className}
                  />
                  <InnterContent3
                    desktopBodycopy20PtCalibriRegular={innterContent33Props.desktopBodycopy20PtCalibriRegular}
                    className={innterContent33Props.className}
                  />
                  <div className="overlap-group-21">
                    <div className="percent-1 valign-text-middle desktopbodycopy">{percent2}</div>
                    <FillInBox />
                  </div>
                </div>
                <div className="customer-satisfaction">
                  <div className="overlap-group2-5">
                    <img className="vector-31" src="/img/vector-42@2x.svg" />
                    <InnterContent3
                      desktopBodycopy20PtCalibriRegular={innterContent34Props.desktopBodycopy20PtCalibriRegular}
                      className={innterContent34Props.className}
                    />
                  </div>
                  <Frame1
                    desktopBodycopy20PtCalibriRegular={frame17Props.desktopBodycopy20PtCalibriRegular}
                    desktopBodycopy18PtCalibriRegular={frame17Props.desktopBodycopy18PtCalibriRegular}
                    className={frame17Props.className}
                  />
                  <FillInBox className={fillInBox2Props.className} />
                </div>
              </div>
              <CancelAndSaveChanges2
                saveChanges={cancelAndSaveChanges21Props.saveChanges}
                className={cancelAndSaveChanges21Props.className}
              />
              <InnerSubjects
                desktopH4Header={innerSubjects1Props.desktopH4Header}
                className={innerSubjects1Props.className}
              />
            </div>
            <div className="filters">
              <div className="company-abc-2">
                <img
                  className="apartment_fill0_wght300_grad0_opsz48-1"
                  src="/img/apartment-fill0-wght300-grad0-opsz48-1@2x.svg"
                />
                <div className="company-abc-3 desktopfilters">{companyAbc}</div>
              </div>
              <div className="location-z-3">
                <div className="overlap-group-22">
                  <img className="vector-32" src="/img/vector-22@2x.svg" />
                  <div className="location-z-4 desktopfilters">{locationZ}</div>
                </div>
              </div>
              <div className="x2022-2">
                <img className="calendar_today_fill0" src="/img/calendar-today-fill0-wght300-grad0-opsz48-1@2x.svg" />
                <div className="number-7 desktopfilters">{number2}</div>
              </div>
            </div>
            <div className="input-variables">
              <div className="overlap-group4-1">
                <Elevation1Radius10 className={elevation1Radius102Props.className} />
                <div className="overlap-group2-6">
                  <div className="rectangle-28 border-1px-teak"></div>
                  <div className="fill-in-to-know-your-capture-rate valign-text-middle desktopbodycopy-s">
                    {fillInToKnowYourCaptureRate}
                  </div>
                </div>
                <InnerContent2 frame1Props={innerContent2Props.frame1Props} />
              </div>
              <CancelAndSaveChanges2
                saveChanges={cancelAndSaveChanges22Props.saveChanges}
                className={cancelAndSaveChanges22Props.className}
              />
              <InnerSubjects
                desktopH4Header={innerSubjects2Props.desktopH4Header}
                className={innerSubjects2Props.className}
              />
            </div>

            <Header header2Props={headerProps.header2Props} />
            <LeftNavigationBar />
          </div>
          <TypeTitles
            subtitle={typeTitlesProps.subtitle}
            className={typeTitlesProps.className}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { profile } = state;
  return {
    profile
  };
}

export default connect(mapStateToProps)(SLAParameters);
