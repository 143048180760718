import "./ClientsOverview.css";

import React from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import { Button } from '@mui/material';
import { Email } from '@mui/icons-material';

import Achtergrond from "../Achtergrond";
import Header from "../Header";
import LeftNavigationBar from "../LeftNavigationBar";
import TypeTitles from "../TypeTitles";
import EditableTable from "../EditableTable";

import { sendEmail } from '../../api/email';
import { listOrganizations, saveOrganization, deleteOrganization, listAccountManagers } from '../../api/organizations';
import { getRegistrationLink } from '../../api/registration';
import { sortByAttribute } from '../../helpers/sorting';
import { composeEmailBody, composeEmailSubject } from '../../helpers/email';
import { loadAuthorizationsAndProfileData } from '../../state/workflows/login';


class ClientsOverview extends React.Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = {
      organizations: null,
      listQueryInProgress: false,
      generatedRegistrationDetails: null,
      message: null,
    };

    // Click event handlers
    this.reloadClientOrganizations = this.reloadClientOrganizations.bind(this);
    this.saveClientOrganization = this.saveClientOrganization.bind(this);
    this.deleteClientOrganization = this.deleteClientOrganization.bind(this);
    this.sendRegistrationLink = this.sendRegistrationLink.bind(this);
    this.closeRegistrationLinkModal = this.closeRegistrationLinkModal.bind(this);
    this.closeMessageModal = this.closeMessageModal.bind(this);

    // Rendering functions
    this.renderOrganizationsTable = this.renderOrganizationsTable.bind(this);
    this.renderReloadButton = this.renderReloadButton.bind(this);
    this.renderRegistrationDetailsModal = this.renderRegistrationDetailsModal.bind(this);
    this.renderMessageModal = this.renderMessageModal.bind(this);
  }

  async reloadClientOrganizations() {
    // Get API credentials
    const { tokenState } = this.props;
    const { applicationToken } = tokenState;
    if (!applicationToken) {
      return;
    }

    this.setState({listQueryInProgress: true})
    try {
      const organizationsResponse = await listOrganizations(applicationToken);
      let organizations = organizationsResponse["organizations/list"];
      if (!!organizations) {
        this.setState({organizations});
      }

      const organizationIds = organizations.map((org) => org.organization_id);
      const managersResponse = await listAccountManagers(applicationToken, organizationIds);
      const accountManagers = (managersResponse["organizations/list_ahm"] || {}).account_managers_per_organization;
      if (!!accountManagers) {
        organizations = organizations.map((organization) => {
          return {
            ...organization,
            account_manager: accountManagers[organization.organization_id] || {}
          };
        });
        this.setState({organizations});
      }
    }
    catch(err) {
      this.setState({message: err});
    }
    finally {
      this.setState({listQueryInProgress: false});
    }
  }

  async saveClientOrganization(organization) {
    const { tokenState } = this.props;
    const { applicationToken } = tokenState;
    if (!applicationToken) {
      return;
    }

    try {
      const objToSave = this.filterGeneratedAttributes(organization);
      const response = await saveOrganization(applicationToken, objToSave);
      const saveResult = (response || {})["organizations/save"] || {};
      if (saveResult.status == "saved") {
        await this.reloadClientOrganizations();
        return saveResult.organization_id;
      }
      else {
        throw new Exception("API did not confirm save");
      }
    }
    catch(err) {
      this.setState({message: err});
    }
  }

  async deleteClientOrganization(recordId) {
    const { tokenState } = this.props;
    const { applicationToken } = tokenState;
    if (!applicationToken) {
      return;
    }

    try {
      const response = await deleteOrganization(applicationToken, recordId);
      const deleteResult = (response || {})["organizations/delete"] || {};
      if (deleteResult.status == "deleted") {
        return deleteResult.organization_id;
      }
      else {
        throw new Exception("API did not confirm delete");
      }
    }
    catch(err) {
      this.setState({message: err});
    }
  }

  async sendRegistrationLink(table, row) {
    const { loginState, tokenState, myProfile, myOrganization } = this.props;
    const { userAttributes } = loginState;
    const { applicationToken } = tokenState;

    const clientContractNumbers = ((row.original || {}).client_contract_numbers || [])
      .filter((ccn) => !!ccn)
      .map((ccn) => ccn.toString());;

    let reportIds = (row.original || {}).report_ids || [];
    if (typeof(reportIds) === 'string') {
      reportIds = reportIds.split(',');
    }

    const tokenRequestPayload = {
      registration_token_attributes: {
        grantor_id: userAttributes.profile,
        organization_id: row.getValue('organization_id'),
        user_type: 'PU',  // PrincipalUser
        grantee_email: row.getValue('principal_user.email'),
        client_contract_numbers: clientContractNumbers.slice(0, 5),
        report_ids: reportIds.slice(0, 5),
      }
    };

    try {
      // Create registration link
      const response = await getRegistrationLink(applicationToken, tokenRequestPayload);
      const registrationLink = response['registration/generate'];

      // Prepare e-mail to send
      const registrationDetails = {
        invitation: registrationLink,
        addressee: {
          firstName: row.getValue('principal_user.first_name'),
          lastName: row.getValue('principal_user.last_name'),
          organizationName: row.getValue('organization_name')
        },
        sender: {
          ...myProfile,
          organizationName: myOrganization.name
        }
      };
      const emailPayload = {
        recipient_address: registrationLink.email,
        message_subject: composeEmailSubject(registrationDetails),
        message_body: composeEmailBody(registrationDetails)
      };

      // Send e-mail
      const emailResponse = await sendEmail(applicationToken, emailPayload);
      const emailStatus = (emailResponse || {}).sendmail || {};
      if (emailStatus.result === "ok") {
        this.setState({
          generatedRegistrationDetails: registrationDetails,
        });
      }
      else {
        this.setState({
          message: `Failed! (${emailStatus.recipient})`
        });
      }
    }
    catch(error) {
      this.setState({
        generatedRegistrationDetails: null,
        message: `Failed! (${error})`
      });
    }
  };

  renderOrganizationsTable() {
    const myReports = sortByAttribute(
      this.props.myReports || [],
      'powerbi_report_name',
      false
    );
    const { clientOrganizations, myOrganization, myRoles } = this.props;
    const currentUserIsAdmin = (myRoles || {}).isAdmin;

    const clientContractsInThisOrganization = sortByAttribute(
      (myOrganization || {}).availableClientContracts || [],
      'contract_name',
      false
    );
    const myClientContractNumbers = ((myOrganization || {}).clientContractNumbers || []).map((cc) => Number(cc));
    const clientContractsAvailableToAssign = clientContractsInThisOrganization
      .filter((contract) => !!currentUserIsAdmin || myClientContractNumbers.includes(contract.contract_nr));

    let organizationsEnriched = this.annotateReadOnlyFlags(
      this.state.organizations || [],
      currentUserIsAdmin
    );
    organizationsEnriched = this.annotateReportNames(
      organizationsEnriched,
      this.props.myReports || []
    );
    const organizations = sortByAttribute(
      organizationsEnriched,
      'organization_name',
      false
    );

    const contractNumbers = clientContractsAvailableToAssign.map(
      (cc) => {
        return {
          key: cc.contract_nr,
          value: cc.contract_nr,
          displayText: `${cc.contract_name} (${cc.contract_nr})`,
        };
      }
    );

    const contractNumberOrganizationMappings = organizations
      // build list of [ [contract nr -> organization id], ... ]
      .flatMap((o) => (!o.is_root && o.client_contract_numbers || []).map((ccn) => [ccn, o.organization_id]))

      // reduce to { contract nr -> [organization id, ...] }
      .reduce(
        (acc, obj) => {
          const [k, v] = obj;
          acc[k] = [...(acc[k] || []), v];
          return acc;
        },
        {}
      );

    const availableContractsFilterFn = (contractNr, rowData) => {
      const { organization_id } = rowData;
      const organizationsForContract = contractNumberOrganizationMappings[contractNr] || [];

      const contractNrIsMappedToThisOrganization = organizationsForContract.some((oid) => oid == organization_id);
      if (contractNrIsMappedToThisOrganization)
        return true;

      const contractNrIsMappedToOtherOrganization = organizationsForContract.some((oid) => oid != organization_id);
      return !contractNrIsMappedToOtherOrganization;
    };

    const columns = [
      {
        accessorKey: 'organization_id',
        header: 'Organization ID',
        enableEditing: false,
        hiddenByDefault: true, // custom attribute
        isPrimaryKey: true, // custom attribute
        isAutoGenerated: true, // custom attribute
      },
      {
        accessorKey: 'organization_name',
        header: 'Organization',
        size: 150,
        enableEditing: false,
        enableEditingForNewRecords: true, // custom attribute
        isPrimaryName: true, // custom attribute
      },
      {
        accessorKey: 'client_contract_numbers',
        header: 'Client Contracts',
        size: 220,
        isArrayField: true, // custom attribute
        options: contractNumbers,
        optionsFilterFn: availableContractsFilterFn, // custom attribute
      },
      {
        accessorKey: 'report_ids',
        accessorFn: (organization) => organization.report_names,
        filterFn: (row, column, filterExpresssion) => ((row.original || {}).report_names || []).join(' ').toLowerCase().includes(filterExpresssion.toLowerCase()),
        header: 'Reports',
        size: 150,
        isArrayField: true, // custom attribute
        options: myReports.map(
          (report) => {
            return {
              key: report.powerbi_report_id,
              value: report.powerbi_report_id,
              displayText: report.powerbi_report_name,
            };
          }
        ),
      },
      {
        accessorKey: 'principal_user.display_text',
        header: 'Principal User',
        enableEditing: false,
        size: 150,
        isAutoGenerated: true, // custom attribute
        accessorFn: (row) => !!row.principal_user && row.principal_user.email
          ? (
              <React.Fragment>
                {row.principal_user.first_name} {row.principal_user.last_name}<br />
                {'<'}{row.principal_user.email}{'>'}
              </React.Fragment>
            )
          : '',
        filterFn: (row, column, filterExpresssion) => {
          const { first_name, last_name, email } = row.original.principal_user;
          const allText = `${first_name} ${last_name} ${email}`;
          return allText.toLowerCase().includes((filterExpresssion || '').toLowerCase());
        },
      },
      {
        header: 'First Name',
        accessorKey: 'principal_user.first_name',
        hiddenByDefault: true, // custom attribute
      },
      {
        header: 'Last Name',
        accessorKey: 'principal_user.last_name',
        hiddenByDefault: true, // custom attribute
      },
      {
        header: 'E-mail',
        accessorKey: 'principal_user.email',
        size: 150,
        validationType: 'email',
        hiddenByDefault: true, // custom attribute
      },
      {
        accessorFn: (row) => !!row.account_manager && row.account_manager.full_name
          ? (
              <React.Fragment>
                {row.account_manager.full_name}<br />
                {'<'}{row.account_manager.email}{'>'}
              </React.Fragment>
            )
          : '',
        filterFn: (row, column, filterExpresssion) => {
          const { full_name, email } = row.original.account_manager;
          const allText = `${full_name} ${email}`;
          return allText.toLowerCase().includes((filterExpresssion || '').toLowerCase());
        },
        header: 'A&H Manager',
        enableEditing: false,
        size: 150,
        isAutoGenerated: true, // custom attribute
      },
      {
        accessorKey: 'active',
        header: 'Access',
        size: 50,
        enableEditingAsBoolean: true, // custom attribute
      },
    ];

    const editorSettings = {
      canCreateNewRow: true,
      createNewRowText: "New organization",
      canEditExistingRow: true,
      editExistingRowText: "Edit organization",
      canDeleteExistingRow: true,
      deleteExistingRowText: "Delete organization",
      saveHandler: this.saveClientOrganization,
      deleteHandler: this.deleteClientOrganization,
      extraActions: [
        {
          title: "Send registration link",
          renderIcon: () => (<Email />),
          clickHandler: this.sendRegistrationLink,
        }
      ],
    };

    return (
      <EditableTable tableId="organizations-table"
                     className="organizations-table"
                     recordTypeName="organization"
                     columns={columns}
                     data={organizations}
                     settings={editorSettings} />
    );
  }

  renderPlaceHolder() {
    return (
      <div className="organizations-table-placeholder">Client organizations loading...</div>
    );
  }

  renderReloadButton() {
    return (
      <Button variant="contained"
              className="reload-clients-button"
              style={{backgroundColor: "var(--teak)"}}
              onClick={this.reloadClientOrganizations}>
        Reload organizations
      </Button>
    );
  }

  renderRegistrationDetailsModal() {
    const { generatedRegistrationDetails } = this.state;
    if (!!generatedRegistrationDetails) {
      const { invitation, sender, addressee } = generatedRegistrationDetails;
      const addresseeText = `${addressee.firstName} ${addressee.lastName} < ${invitation.email}>`
      return (
        <div className="registration-link-modal" >
          <div className="registration-link-item">
            <a className="subject-header">Sent E-mail message to:</a><br/>
            <a className="content-item">{addresseeText}<br /></a>
          </div>
          <div className="registration-link-item">
            <a className="subject-header">Link URL:</a><br/>
            <a className="content-item">{invitation.url}<br /></a>
          </div>
          <div className="registration-link-item">
            <a className="subject-header">Expires:</a><br/>
            <a className="content-item">{invitation.expires || '-'}</a>
          </div>
          <div className="registration-link-modal-footer"
               onClick={() => this.closeRegistrationLinkModal()}>(click to close)</div>
        </div>
      );
    }
    else
      return '';
  }

  closeRegistrationLinkModal() {
    this.setState({
      generatedRegistrationDetails: null,
    });
  }

  renderMessageModal() {
    const { message } = this.state;
    if (!!message) {
      setTimeout(this.closeMessageModal, 2000);
      return (
        <div className="message-modal" onClick={() => this.closeMessageModal()} >
          { message }
        </div>
      );
    }
    else {
      return '';
    }
  }

  closeMessageModal() {
    this.setState({message: null});
  }

  render() {
    const { headerProps, typeTitlesProps } = this.props;
    const { organizations, listQueryInProgress } = this.state;
    const typeTitlesClassName = `${(typeTitlesProps || {}).className || ''} selector-pos-col-1`;

    const tableElement = (!!organizations && !listQueryInProgress) ? this.renderOrganizationsTable() : this.renderPlaceHolder();
    const reloadButton = this.renderReloadButton();
    const registrationDetailsModal = this.renderRegistrationDetailsModal();
    const messageModal = this.renderMessageModal();

    return (
      <div className="container-center-horizontal">
        <div className="clients-overview-page screen">
          <div className="overlap-group-clients-overview">
            <Achtergrond />
            <Header header2Props={headerProps.header2Props} />
            <LeftNavigationBar />
            <div className="organizations-table-container">
              { tableElement }
              { reloadButton }
            </div>
            { registrationDetailsModal }
            { messageModal }
          </div>
          <TypeTitles subtitle={typeTitlesProps.subtitle} className={typeTitlesClassName} />
        </div>
      </div>
    );
  }

  async componentDidMount() {
    // Load the organizations list
    await this.reloadClientOrganizations();

    // This will refresh the current user's data in the redux store (i.e. which report IDs you can assign etc)
    const { cognitoAuthenticationToken } = this.props.tokenState;
    await loadAuthorizationsAndProfileData(cognitoAuthenticationToken);
  }

  annotateReadOnlyFlags(organizations, currentUserIsAdmin) {
    // The root organization is only editable by admin users
    return organizations.map(
      (organization) => {
        return {
          ...organization,
          readOnly: !!organization.is_root && !currentUserIsAdmin
        };
      }
    );
  }

  annotateReportNames(organizations, reports) {
    const reportIdNameDict = Object.fromEntries(
      reports.map((report) => [report.powerbi_report_id, report.powerbi_report_name])
    );
    return organizations.map(
      (organization) => {
        return {
          ...organization,
          report_names: (organization.report_ids || [])
            .map((report_id) => reportIdNameDict[report_id])
            .filter((report_name) => !!report_name)
        }
      }
    );
  }

  filterGeneratedAttributes(obj) {
    const generatedColumnsWithoutDataField = ['A&H Manager', 'display_text'];
    const nestedObjectKeys = Object.keys(obj)
      .filter((key) => typeof(obj[key]) === 'object' && !Array.isArray(obj[key]))
      .filter((key) => !generatedColumnsWithoutDataField.includes(key));
    const nestedObjects = Object.fromEntries(
      nestedObjectKeys.map((key) => [key, this.filterGeneratedAttributes(obj[key])])
    );
    const filteredObject = Object.keys(obj)
      .filter(key => !generatedColumnsWithoutDataField.includes(key))
      .reduce((o, key) => { o[key] = obj[key]; return o; }, {});

    return {
      ...filteredObject,
      ...nestedObjects
    };
  }
}

function mapStateToProps(state) {
  /* Return a dict with the relevant state info to pass into the props for this component */
  return {
    loginState: state.login,
    tokenState: state.token,
    myProfile: state.profile.employee,
    myOrganization: state.profile.organization,
    myReports: state.dashboardAttributes.reports,
    myRoles: state.profile.roles,
  };
}

export default connect(mapStateToProps)(ClientsOverview);
