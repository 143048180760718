import "./MyProfilePage.css";

import React from "react";
import { connect } from 'react-redux';

import Achtergrond from "../Achtergrond";
import BoxWithTitle from "../BoxWithTitle";
import InnerContent from "../InnerContent";
import PhoneNumber from "../PhoneNumber";
import LeftNavigationBar from "../LeftNavigationBar";
import Header from "../Header";
import TypeTitles from "../TypeTitles";


function renderLeftPane(props) {
  if (!props.showBasicProfile) {
    return '';
  }

  const {
    boxWithTitle2Props,
    innerContent7Props,
    innerContent8Props,
    phoneNumberProps,
    innerContent9Props,
    innerContent10Props,
    profile
  } = props;

  const { employee } = profile;
  const displayName = (employee.firstName != null && employee.lastName != null)
    ? `${employee.firstName} ${employee.lastName}`
    : '';

  return (
    <div className="overlap-group-left">
      <BoxWithTitle
        className={boxWithTitle2Props.className}
        elevation1Radius10Props={boxWithTitle2Props.elevation1Radius10Props}
        innerSubjectsProps={boxWithTitle2Props.innerSubjectsProps} />
      <div className="first-last-name">
        <InnerContent
          desktopBodycopy18PtCalibriRegular={innerContent10Props.desktopBodycopy18PtCalibriRegular}
          desktopBodycopy20PtCalibriRegular={displayName}
          className={innerContent10Props.className} />
      </div>
      <div className="e-mail">
        <InnerContent
          desktopBodycopy18PtCalibriRegular={innerContent9Props.desktopBodycopy18PtCalibriRegular}
          desktopBodycopy20PtCalibriRegular={employee.eMailAddress}
          className={innerContent9Props.className} />
      </div>
      <div className="company">
        <InnerContent
          desktopBodycopy18PtCalibriRegular={innerContent8Props.desktopBodycopy18PtCalibriRegular}
          desktopBodycopy20PtCalibriRegular={(profile.organization || {}).name || 'Loading...'}
          className={innerContent8Props.className} />
      </div>
      <div className="function">
        <InnerContent
          desktopBodycopy18PtCalibriRegular={innerContent7Props.desktopBodycopy18PtCalibriRegular}
          desktopBodycopy20PtCalibriRegular={employee.functionTitle}
          className={innerContent7Props.className} />
      </div>
    </div>
  );
}

function renderRightPane(props) {
  if (!props.showRoles) {
    return (
      <div className="overlap-group-right">
        <img src={props.alternativeImage} />
      </div>
    );
  }

  const boxWithTitle1Props = {
    innerSubjectsProps: {
      desktopH4Header: "Roles",
      className: "",
    },
    elevation1Radius10Props: {
      className: "elevation-1-radius-10-4",
    },
  };

  const innerContent1Props = {
      desktopBodycopy18PtCalibriRegular: "Available roles",
      desktopBodycopy20PtCalibriRegular: renderRolesList(props.profile),
      className: "inner-content-2",
  };

  return (
    <div className="overlap-group-right">
      <BoxWithTitle
        elevation1Radius10Props={boxWithTitle1Props.elevation1Radius10Props}
        innerSubjectsProps={boxWithTitle1Props.innerSubjectsProps}
      />
      <div className="roles-list">
        <InnerContent
          desktopBodycopy18PtCalibriRegular={innerContent1Props.desktopBodycopy18PtCalibriRegular}
          desktopBodycopy20PtCalibriRegular={innerContent1Props.desktopBodycopy20PtCalibriRegular}
          className={innerContent1Props.className}
        />
      </div>

    </div>
  );
}

function renderRolesList(profile) {
  const { employee, organization, roles } = profile;
  const rolesList = [];
  if (!!employee && !! organization && !!roles) {
    if (!!employee.eMailAddress) {
      rolesList.push('Client Portal User');
    }
    if (!!roles.isAdmin) {
      rolesList.push('BI Administrator (Compass Group)');
    }
    if (!!roles.isAccountAndHospitalityManager) {
      rolesList.push('Account & Hospitality Manager (Compass Group)');
    }
    if (!!roles.isClientPrincipal) {
      rolesList.push(`Principal User (${profile.organization.name})`);
    }
  }
  return (
    <React.Fragment>
      <ul>
        {rolesList.map((roleName, idx) => <li key={`roles-list-item-${idx}`} className="roles-list-item">{roleName}</li>)}
      </ul>
    </React.Fragment>
  );
}

function renderPageContent(props) {
  const {
    typeTitlesProps,
    token,
    profile
  } = props;
  const { errorMessage } = token;

  if (!!profile.employee) {
    return (
      <React.Fragment>
        <TypeTitles subtitle={typeTitlesProps.subtitle}
                              className={typeTitlesProps.className} />
        {renderLeftPane(props)}
        {renderRightPane(props)}
      </React.Fragment>
    );
  }
  else if (!!errorMessage) {
    const subTitle = `${errorMessage}. Please contact your organization's principal user or the Compass Group Account & Hospitality manager for assistance.`;
    return <TypeTitles subtitle={subTitle}
                       className={typeTitlesProps.className} />
  }
}

function MyProfilePage(props) {
  const {
    achtergrondProps,
    headerProps,
    typeTitlesProps,
    profile
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="my-profile-page screen">
        <Achtergrond className={achtergrondProps.className} />
        <div>
          <Header header2Props={headerProps.header2Props} />
          <LeftNavigationBar />
          {renderPageContent(props)}
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const { token, profile } = state;
  return { token, profile };
}

export default connect(mapStateToProps)(MyProfilePage);
