import "./DocumentPage.css";

import React from "react";
import { Link } from "react-router-dom";

import Achtergrond from "../Achtergrond";
import Elevation1Radius10 from "../Elevation1Radius10";
import ViewAll from "../ViewAll";
import InnerSubjects from "../InnerSubjects";
import Elevation1Radius102 from "../Elevation1Radius102";
import Header from "../Header";
import LeftNavigationBar from "../LeftNavigationBar";
import TypeTitles from "../TypeTitles";


function FilterElements(props) {
  return <div className="filter-group" />;
  /* TODO filter on company, location, contract number
  const {
    companyAbc,
    locationZ,
    number1
  } = props;
  return (
    <div className="filter-group">
      <div className="company-abc">
        <div className="overlap-group-12">
          <img className="vector-17" src="/img/vector-20@2x.svg" />
          <div className="company-abc-1 desktopfilters">{companyAbc}</div>
        </div>
      </div>
      <div className="location-z-1">
        <div className="overlap-group2-3">
          <img className="vector-19" src="/img/vector-22@2x.svg" />
          <div className="location-z-2 desktopfilters">{locationZ}</div>
        </div>
      </div>
      <div className="x2022">
        <div className="overlap-group1-4">
          <img className="vector-20" src="/img/vector-21@2x.svg" />
          <div className="number desktopfilters">{number1}</div>
        </div>
      </div>
    </div>
  );
  */
}


function renderDocument(details, idx) {
  const rowNumber = idx + 1;
  const rowClass = `flex-row row-${idx + 1} width-50`;
  const imageSource = (details.locked) ? "/img/lock-6@2x.svg" : "/img/arrow-forward-2@2x.svg"
  return (
    <div key={`document-row-${idx+1}`} className={rowClass}>
      <div className="flex-row-inner">
        <img className="document-icon" src={details.image} />
        <div className="document-filename desktopbodycopy">{details.filename}</div>
        <div className="document-date desktopbodycopy-18pt">{details.date}</div>
        <div className="document-size desktopbodycopy-18pt">{details.filezise}</div>
        <div className="document-status-icon">
          <img src={imageSource} height="100%" />
        </div>
      </div>
    </div>
  );
}


function Invoices(props) {
  return <div />;  // TODO enable
}

function SustainabilityReporting(props) {
  return <div />; // TODO enable
}

function Contracts(props) {
  const {
    contracts,
    elevation1Radius102Props2,
    viewAll,
    image17,
    x4765893Xlsx,
    x146046Docs,
    x21Mb,
    x34Mb,
    x48Mb,
    x65Mb1,
    x65Mb2,
    date8,
    date9,
    date10,
    date11,
    date12,
    image13,
    image14,
    image15,
    image16,
    frameworkAgreement05Pdf,
    frameworkAgreement06Pdf,
    windowCleaning2022Docx,
    innerSubjects3Props,
  } = props;

  const elevationRadiusClassName = `${elevation1Radius102Props2.className} width-50`;

  return (
      <div className="contracts">
      <Elevation1Radius10 className={elevationRadiusClassName} />
      <div className="overlap-group-10 width-50">
        <ViewAll className="view-all view-all-1" />
        { (props.contracts || []).map((details, idx) => renderDocument(details, idx)) }
        <InnerSubjects desktopH4Header={innerSubjects3Props.desktopH4Header}
                       className={innerSubjects3Props.className} />
        </div>
      </div>
  );
}

function DocumentPage(props) {
  const {
    achtergrondProps,
    headerProps,
    innerSubjects3Props,
    typeTitlesProps,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="document-page screen">
        <div className="overlap-group7">
          <Achtergrond className={achtergrondProps.className} />
          {FilterElements(props)}
          {Invoices(props)}
          {SustainabilityReporting(props)}
          {Contracts(props)}
          <Header header2Props={headerProps.header2Props} />
          <LeftNavigationBar />
        </div>
        <TypeTitles
          subtitle={typeTitlesProps.subtitle}
          className={typeTitlesProps.className}
        />
      </div>
    </div>
  );
}

export default DocumentPage;
