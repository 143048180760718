import React from "react";
import Frame1 from "../Frame1";
import "./InnerContent2.css";

function InnerContent2(props) {
  const { frame1Props } = props;

  return (
    <div className="inner-content-2-1">
      <Frame1
        desktopBodycopy20PtCalibriRegular={frame1Props.desktopBodycopy20PtCalibriRegular}
        desktopBodycopy18PtCalibriRegular={frame1Props.desktopBodycopy18PtCalibriRegular}
      />
    </div>
  );
}

export default InnerContent2;
