export function getClientContractAttributesInitiated() {
  return {
    type: 'GET_CLIENT_CONTRACT_ATTRIBUTES_INITIATED'
  };
}

export function getClientContractAttributesSucceeded(attributes) {
  return {
    type: 'GET_CLIENT_CONTRACT_ATTRIBUTES_SUCCEEDED',
    payload: {
      ...attributes
    }
  };
}

export function getClientContractAttributesFailed() {
  return {
    type: 'GET_CLIENT_CONTRACT_ATTRIBUTES_FAILED'
  };
}
