import "./TypeTitles.css";

import React from "react";
import { connect } from 'react-redux';


function renderWelcome(employee) {
  const welcomeText = (!!employee) ? `Welcome ${employee.firstName},` : 'Welcome!'
  return (<h1 className="welcome-header ptserif-bold-black-xl">{welcomeText}</h1>);
}

function TypeTitles(props) {
  const { profile, subtitle, className } = props;
  const { employee } = profile;

  return (
    <div className={`type-titles ${className || ""}`}>
      {renderWelcome(employee)}
      <div className="subtitle ptserif-normal-black-l">{subtitle}</div>
    </div>
  );
}

function mapStateToProps(state) {
  const { profile } = state;
  return {
    profile
  };
}

export default connect(mapStateToProps)(TypeTitles);
