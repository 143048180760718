import React from "react";
import { Link } from "react-router-dom";
import "./LastYearFilter.css";

function LastYearFilter(props) {
  const { lastYear1, lastDay, last15Days, last30Days, lastQuarter, lastYear2, customDate } = props;

  return (
    <div className="container-center-horizontal">
      <div className="last-year-filter screen">
        <div className="last-year-filter-1">
          <div className="overlap-group-15">
            <img className="vector-24" src="/img/vector-33@2x.svg" />
            <div className="last-year desktopfilters">{lastYear1}</div>
          </div>
          <div className="filter-options-1">
            <div className="flex-row-4">
              <img className="vector-23" src="/img/vector-8@2x.svg" />
              <div className="last desktopfilters">{lastDay}</div>
            </div>
            <div className="flex-row-5">
              <img className="vector-25" src="/img/vector-8@2x.svg" />
              <div className="last desktopfilters">{last15Days}</div>
            </div>
            <div className="flex-row-6">
              <img className="vector-23" src="/img/vector-8@2x.svg" />
              <div className="last desktopfilters">{last30Days}</div>
            </div>
            <div className="flex-row-7">
              <img className="vector-23" src="/img/vector-8@2x.svg" />
              <div className="last-quarter desktopfilters">{lastQuarter}</div>
            </div>
            <div className="flex-row-8">
              <img className="vector-23" src="/img/vector-7@2x.svg" />
              <div className="last desktopfilters">{lastYear2}</div>
            </div>
            <img className="line-18-1" src="/img/line-18@2x.svg" />
            <div className="flex-row-9">
              <Link to="/my-profile-page">
                <img className="vector-26" src="/img/vector-6@2x.svg" />
              </Link>
              <Link to="/my-profile-page">
                <div className="custom-date desktopfilters">{customDate}</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LastYearFilter;
