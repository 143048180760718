import { getData } from "./serviceClient";

export async function requestPasswordResetEmail(email) {
  const requestBody = {
    password_reset_token_attributes: { email }
  };
  return await getData('public', 'password-reset/generate', {}, requestBody);
};

export async function parsePasswordResetToken(token) {
  const requestBody = { password_reset_token: token };
  return await getData('public', 'password-reset/parse', {}, requestBody);
}

export async function performPasswordReset(token, newPassword) {
  const requestBody = {
    password_reset_token: token,
    new_password: newPassword
  };
  return await getData('public', 'password-reset/execute', {}, requestBody);
}
