import "./RegistrationScreen.css";

import React from "react";
import { connect } from 'react-redux';

import { parseRegistrationToken, registerNewUser } from '../../api/registration';
import { userRegistrationInitiated, userRegistrationSucceeded, userRegistrationFailed } from '../../state/actions/registration';
import store from '../../state/store';

import { Lock, Person, VerifiedUser, Work } from '@mui/icons-material';
import IntroText from "../IntroText";
import LoginButton from "../LoginButton";
import WelcomeHeader from "../WelcomeHeader";

class RegistrationScreen extends React.Component {

  constructor(props) {
    super();

    this.props = props;
    this.state = {
      // API interaction state
      tokenValidationInProgress: false,
      tokenValidationCompleted: false,
      tokenValidationFailed: false,
      tokenValidationResponseData: null,

      // Form field values
      email: '',
      firstName: '',
      lastName: '',
      jobTitle: '',
      password: '',
      password2: '',

      // Other UI state
      passwordVisible: false,
      inputValidationError: null,
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.passwordInputType = this.passwordInputType.bind(this);
    this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
    this.validateToken = this.validateToken.bind(this);
    this.renderFormItem = this.renderFormItem.bind(this);
    this.renderRegistrationForm = this.renderRegistrationForm.bind(this);
    this.renderMessages = this.renderMessages.bind(this);
    this.renderTokenValidationErrors = this.renderTokenValidationErrors.bind(this);
    this.onClickRegisterButton = this.onClickRegisterButton.bind(this);
    this.setValidationError = this.setValidationError.bind(this);
    this.clearValidationError = this.clearValidationError.bind(this);
  }

  // Start-up
  async componentDidMount() {
    this.validateToken();
  }

  async validateToken() {
    if (!!this.props.token && !this.state.tokenValidationCompleted) {
      let tokenValidationFailed = true;
      let tokenValidationResponseData = null;
      let email = null, firstName = null, lastName = null, jobTitle = null;
      try {
        this.setState({
          tokenValidationInProgress: true
        });
        const response = await parseRegistrationToken(this.props.token);
        if (!!response) {
          tokenValidationResponseData = response['registration/parse'];
          email = this.getElementFromTokenValidationResponse(tokenValidationResponseData, 'grantee_email');
          firstName = this.getElementFromTokenValidationResponse(tokenValidationResponseData, 'first_name');
          lastName = this.getElementFromTokenValidationResponse(tokenValidationResponseData, 'last_name');
          jobTitle = this.getElementFromTokenValidationResponse(tokenValidationResponseData, 'function');
          tokenValidationFailed = false;
        }
      }
      finally {
        this.setState({
          tokenValidationInProgress: false,
          tokenValidationCompleted: true,
          tokenValidationFailed,
          tokenValidationResponseData,
          email,
          firstName,
          lastName,
          jobTitle
        });
      }
    }
  }

  getElementFromTokenValidationResponse(tokenValidationResponseData, key) {
    return ((tokenValidationResponseData || {}).status === 'ok') ? tokenValidationResponseData.data[key] : null;
  }

  // GUI state management
  passwordInputType() {
    return (!!this.state.passwordVisible) ? "text" : "password";
  }

  togglePasswordVisibility() {
    this.setState({passwordVisible: !this.state.passwordVisible});
  }

  // Rendering
  renderMessages() {
    const { tokenValidationInProgress, inputValidationError } = this.state;
    let messageText;
    let showAsError;
    if (!!tokenValidationInProgress) {
      messageText = 'Checking token...';
      showAsError = false;
    }
    else if (!!inputValidationError) {
      messageText = inputValidationError;
      showAsError = true;
    }
    else {
      messageText = null;
      showAsError = false;
    }

    const errorClass = (showAsError) ? 'error-modal' : 'message-modal';
    const modalClasses = `modal ${errorClass}`;
    return (!!messageText) ? <div className={modalClasses}>{messageText}</div> : '';
  }

  renderTokenValidationErrors() {
    const { tokenValidationFailed, tokenValidationResponseData } = this.state;
    if (tokenValidationFailed) {
      const errorMessage = (tokenValidationResponseData || {}).message || "invalid token";
      return (
        <div className="modal error-modal token-validation-error">
          Registration token refused:<br /><br /><br />
          {errorMessage}
        </div>
      );
    }
    else {
      return '';
    }
  }

  renderFormItem(dataField, initialValue, renderIcon, readOnly, treatAsPasswordInput) {
    const readOnlyClass = (!!readOnly) ? 'form-item-readonly' : '';
    const containerClass = `form-item ${readOnlyClass}`;
    const changeHandler = (!readOnly) ? (event) => this.setState({[dataField]: event.target.value}) : null;
    const usePlaceholder = !readOnly && initialValue !== this.state[dataField];
    const placeholderValue = (!!usePlaceholder) ? initialValue : null;
    const inputValue = (!usePlaceholder) ? initialValue : this.state[dataField];
    const autoCompleteKey = `compass-client-portal-${dataField}`;
    return (
      <div className={containerClass}>
        <input type={(!!treatAsPasswordInput) ? this.passwordInputType() : 'text'}
               disabled={!!readOnly ? 'disabled': null}
               className="text-input desktopbodycopy"
               autoComplete={autoCompleteKey}
               placeholder={placeholderValue}
               value={inputValue}
               onChange={changeHandler} />
        {renderIcon()}
      </div>
    );
  }

  renderRegistrationForm() {
    const { userRegistrationState } = this.props;
    const { tokenValidationCompleted, tokenValidationFailed, tokenValidationResponseData } = this.state;
    if (tokenValidationCompleted && !tokenValidationFailed) {
      return (
        <form>
          <div className="registration-form-head ptserif-normal-black-l">
          Fill in your data and register
          </div>
          {
            this.renderFormItem('email',
                                this.state.email,
                                () => <VerifiedUser className="custom-icon" />,
                                true)
          }
          {
            this.renderFormItem('firstName',
                                '(first name)',
                                () => <Person className="custom-icon" />)
          }
          {
            this.renderFormItem('lastName',
                                '(last name)',
                                () => <Person className="custom-icon" />)
          }
          {
            this.renderFormItem('jobTitle',
                                '(function)',
                                () => <Work className="custom-icon" />)
          }
          {
            this.renderFormItem('password',
                                '(create password)',
                                () => <Lock className="custom-icon" onClick={() => this.togglePasswordVisibility()}/>,
                                false,
                                true)
          }
          {
            this.renderFormItem('password2',
                                '(repeat password)',
                                () => <Lock className="custom-icon" onClick={() => this.togglePasswordVisibility()}/>,
                                false,
                                true)
          }

          <LoginButton clickHandler={this.onClickRegisterButton}
                       disabled={!!userRegistrationState.registrationInProgress ? 'disabled' : null}>
                       {this.props.loginButtonProps.children}
          </LoginButton>
        </form>
      );
    }
    else {
      return "";
    }
  }

  render() {
    return (
      <div className="container-center-horizontal">
        <div className="registration-screen screen">
          <div className="proposition-summary">
            <div className="overlap-group-registration-left">
              <WelcomeHeader />
              <IntroText textLines={this.props.introText} />
              <div className="login-and-discover-the-portal">{this.props.loginAndDiscoverThePortal}</div>
            </div>
          </div>
          <div className="registration-area">
            <div className="overlap-group-registration-right">
              <img className="logo-compass-group_zwart_goud_rgb-1" src={this.props.logoCompassGroup_Zwart_Goud_Rgb1} />
              <div className="register ptserif-bold-black-xl">Register as new user</div>
              {this.renderRegistrationForm()}
            </div>
          </div>
          {this.renderMessages()}
          {this.renderTokenValidationErrors()}
        </div>
      </div>
    );
  }

  // Form submission
    checkPasswordComplexity(password) {
    // Requires a length of at least 6 characters, containing at least:
    // * 1 uppercase letter
    // * 1 lowercase letter
    // * 1 digit
    // * 1 special character (! @ # $ % ^ & * - _ ; :)
    const pattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*-_;\:])(?=.{1,})");
    return pattern.test(password);
  }

  setValidationError(message, timeout=2000) {
    this.setState({inputValidationError: message});
    window.setTimeout(this.clearValidationError, timeout);
  }

  clearValidationError() {
    this.setState({inputValidationError: null});
  }

  async onClickRegisterButton() {
    const { email, firstName, lastName, jobTitle, password, password2 } = this.state;
    if (!firstName || !lastName || !jobTitle || !password || !password2) {
      this.setValidationError('Not all fields have been filled in');
    }
    else if (password !== password2) {
      this.setValidationError('Passwords do not match');
    }
    else if (!this.checkPasswordComplexity(password)) {
      this.setValidationError(
        'Use a better password. (minimum 6 characters, must include uppercase and lowercase, numbers and special characters)',
        5000
      );
    }
    else {
        const { token } = this.props;
        const userAttributes = {
          firstName,
          lastName,
          jobTitle,
          password,
        };

        store.dispatch(userRegistrationInitiated());
        try {
          const response = await registerNewUser(token, userAttributes);
          if (!!response && (response['registration/new'] || {}).status === 'ok') {
            store.dispatch(userRegistrationSucceeded(email, password));
            console.log(`Successfully registered new user with username ${email}`);
          }
          else {
            store.dispatch(userRegistrationFailed());
            console.log(response);
            const { message } = (response || {})['registration/new'] || {};
            if (!!message) {
              this.setValidationError(message, 5000);
            }
          }
        }
        catch {
          this.setValidationError('User registration failed', 5000);
          store.dispatch(userRegistrationFailed());
        }
    }
  }
}

function mapStateToProps(state) {
  const { userRegistration } = state;
  return {
    userRegistrationState: userRegistration
  };
}

export default connect(mapStateToProps)(RegistrationScreen);
