import React from "react";
import "./Cancel.css";

function Cancel() {
  return (
    <div className="cancel-2">
      <div className="overlap-group-6 border-1px-husk">
        <div className="cancel-3 valign-text-middle ptserif-bold-husk-xxs">Cancel</div>
      </div>
    </div>
  );
}

export default Cancel;
