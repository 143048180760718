export function getDashboardAttributesInitiated() {
  return {
    type: 'GET_DASHBOARD_ATTRIBUTES_INITIATED'
  };
}

export function getDashboardAttributesSucceeded(attributes) {
  return {
    type: 'GET_DASHBOARD_ATTRIBUTES_SUCCEEDED',
    payload: {
      ...attributes
    }
  };
}

export function getDashboardAttributesFailed() {
  return {
    type: 'GET_DASHBOARD_ATTRIBUTES_FAILED'
  };
}
