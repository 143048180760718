import store from '../store';

import { userLoginInitiated, userLoginSucceeded, userLoginFailed, userLoggedOut } from '../actions/login';
import { passwordChangeInitiatedByServer } from '../actions/passwordChange';
import { cognitoTokenReceived, getApplicationTokenInitiated, getApplicationTokenSucceeded, getApplicationTokenFailed } from '../actions/token';
import { getProfileInitiated, getProfileSucceeded, getProfileFailed } from '../actions/profile';
import { getDashboardAttributesInitiated, getDashboardAttributesSucceeded, getDashboardAttributesFailed } from '../actions/dashboardAttributes';
import { getClientContractAttributesInitiated, getClientContractAttributesSucceeded, getClientContractAttributesFailed } from '../actions/clientContractAttributes';

import { getToken } from '../../api/cognito';
import { getApplicationToken } from '../../api/token';
import { getProfile } from '../../api/profile';
import { getClientContractAttributes } from '../../api/clientContract';
import { getDashboardAttributes } from '../../api/dashboardAttributes';
import sleep from '../../helpers/sleep';


async function authWorkflow_GetCognitoToken(username, password, retries) {
  store.dispatch(userLoginInitiated());
  attempts = 0;
  while (true) {
    const authResult = await getToken(username, password);
    if (authResult.success) {
      store.dispatch(userLoginSucceeded(authResult.username, authResult.token, authResult.userAttributes));
      return {
        cognitoUsername: authResult.username,
        cognitoUserAttributes: authResult.userAttributes,
        cognitoToken: authResult.token,
      };
    }
    else if (authResult.passwordChangeRequired) {
      store.dispatch(passwordChangeInitiatedByServer(authResult.cognitoUser));
      throw new Error('Login workflow redirected to password change workflow');
    }
    else if (attempts < retries) {
      attempts++;
      await sleep(2000);
    }
    else {
      store.dispatch(userLoginFailed());
      throw new Error('Failed to authenticate with AWS Cognito identity provider service');
    }
  }
}

async function authWorkflow_GetApplicationToken(cognitoToken, retries) {
  store.dispatch(getApplicationTokenInitiated());
  const appTokenResult = await getApplicationToken(cognitoToken);
  if (!!appTokenResult.applicationToken) {
    store.dispatch(getApplicationTokenSucceeded(appTokenResult.applicationToken));
    return appTokenResult.applicationToken;
  }
  else {
    const { errorMessage } = appTokenResult;
    store.dispatch(getApplicationTokenFailed(errorMessage));
    store.dispatch(userLoggedOut(errorMessage));
  }
}

async function authWorkflow_GetProfile(appToken, detail) {
  store.dispatch(getProfileInitiated());
  const profileResult = await getProfile(appToken, detail);
  if ((profileResult || {}).profile) {
    store.dispatch(getProfileSucceeded(profileResult.profile));
  }
  else {
    store.dispatch(getProfileFailed());
    throw new Error('Failed to read profile data from API')
  }
}
const authWorkflow_GetProfile_Basic = (appToken) => authWorkflow_GetProfile(appToken, false);
const authWorkflow_GetProfile_Full = (appToken) => authWorkflow_GetProfile(appToken, true);

export async function authWorkflow_GetDashboardAttributes(appToken) {
  store.dispatch(getDashboardAttributesInitiated());
  const dashboardAttributesResult = await getDashboardAttributes(appToken);
  const responsePayload = (dashboardAttributesResult || {}).dashboard_attributes;
  if (responsePayload) {
    store.dispatch(getDashboardAttributesSucceeded(responsePayload));
  }
  else {
    store.dispatch(getDashboardAttributesFailed());
    throw new Error('Failed to read PowerBI dashboard attributes data from API')
  }
}

export async function authenticateAndLoadProfileData(username, password, retries=0) {
  try {
    // Check parameters
    if (!username || !password)
    {
      console.warn('Missing username or password');
      return;
    }

    // Step 1: Login credentials -> Cognito token
    const { cognitoToken } = await authWorkflow_GetCognitoToken(username, password, retries);
    store.dispatch(cognitoTokenReceived(cognitoToken));

    // Step 2+: Cognito token -> all authorization and profile data
    return loadAuthorizationsAndProfileData(cognitoToken);
  }
  catch(err) {
    console.log(`Authentication workflow failed. ${err}`);
    return false;
  }
}

export async function loadAuthorizationsAndProfileData(cognitoToken) {
  try {
    // Check parameters
    if (!cognitoToken)
    {
      console.warn('Missing authentication token');
      return;
    }

    // Step 2: Cognito token -> application auth token
    var appToken = await authWorkflow_GetApplicationToken(cognitoToken);

    // Step 3: Application token + username -> profile data and dynamically configured attributes for back-end services
    const parallelFunctions = [
      authWorkflow_GetProfile_Basic,
      authWorkflow_GetDashboardAttributes,
    ];
    const parallelTasks = parallelFunctions.map((f) => f(appToken));
    await Promise.all(parallelTasks);

    // Step 4: extended details for the profile data (these are not needed for the initial render of sidebar en home)
    await authWorkflow_GetProfile_Full(appToken);

    return true;
  }
  catch(err) {
    console.log(`Authorization & get login data workflow failed. ${err}`);
    return false;
  }
}
