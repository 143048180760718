import React from "react";
import "./InnterContent3.css";

function InnterContent3(props) {
  const { desktopBodycopy20PtCalibriRegular, className } = props;

  return (
    <div className={`innter-content-3-1 ${className || ""}`}>
      <div className="desktop-bodycopy-20pt-calibri-regular-1 desktopbodycopy">{desktopBodycopy20PtCalibriRegular}</div>
      <img className="line-3" src="/img/line-3@2x.svg" />
    </div>
  );
}

export default InnterContent3;
