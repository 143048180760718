import React from "react";
import { Link } from "react-router-dom";
import "./LocationFilterDocumentsSLA.css";

function LocationFilterDocumentsSLA(props) {
  const { locationZ1, locationA, locationB, locationC, locationZ2, addLocation } = props;

  return (
    <div className="container-center-horizontal">
      <div className="location-filter-documents-sla screen">
        <div className="location-filter-documents-sla-1">
          <div className="overlap-group-26">
            <img className="vector-48" src="/img/vector-22@2x.svg" />
            <div className="location-z-5 desktopfilters">{locationZ1}</div>
          </div>
          <div className="overlap-group1-8">
            <div className="flex-row-18">
              <img className="vector-47" src="/img/vector-8@2x.svg" />
              <div className="location-1 desktopfilters">{locationA}</div>
            </div>
            <div className="flex-row-19">
              <img className="vector-47" src="/img/vector-8@2x.svg" />
              <div className="location-1 desktopfilters">{locationB}</div>
            </div>
            <div className="flex-row-20">
              <img className="vector-47" src="/img/vector-8@2x.svg" />
              <div className="location-c desktopfilters">{locationC}</div>
              <img className="vector-49" src="/img/vector-64@2x.svg" />
            </div>
            <div className="flex-row-21">
              <img className="vector-47" src="/img/vector-7@2x.svg" />
              <div className="location-z-6 desktopfilters">{locationZ2}</div>
            </div>
            <img className="line-18-4" src="/img/line-18@2x.svg" />
            <div className="flex-row-22">
              <Link to="/my-profile-page">
                <img className="vector-50" src="/img/vector-6@2x.svg" />
              </Link>
              <Link to="/my-profile-page">
                <div className="add-location-1 desktopfilters">{addLocation}</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocationFilterDocumentsSLA;
