import React from "react";
import Popup2 from "../Popup2";
import "./EnhanceServiceQuality.css";

function EnhanceServiceQuality(props) {
  const { popup2Props } = props;

  return (
    <div className="container-center-horizontal">
      <div className="enhance-service-quality screen">
        <div className="enhance-service-quality-1">
          <div className="overlap-group-27">
            <Popup2 frame4Props={popup2Props.frame4Props} middleButtonProps={popup2Props.middleButtonProps} />
            <img className="qr-code-icon" src="/img/qr-code-icon@2x.svg" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnhanceServiceQuality;
