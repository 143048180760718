import "./ChangePasswordScreen.css";

import React from "react";
import { connect } from 'react-redux';

import store from '../../state/store';
import { passwordChangeRequestSentToServer, passwordChangeSucceeded, passwordChangeFailed } from '../../state/actions/passwordChange';
import { authenticateAndLoadProfileData } from '../../state/workflows/login';

import IntroText from "../IntroText";
import LoginButton from "../LoginButton";
import WelcomeHeader from "../WelcomeHeader";

import { changePassword } from '../../api/cognito';


class ChangePasswordScreen extends React.Component {

  constructor(props) {
    super();

    this.props = props;

    this.state = {
      password: ""
    };

    this.onClickChangePasswordButton = async e => {
      store.dispatch(passwordChangeRequestSentToServer());

      const { currentUser } = this.props.passwordChangeState;
      const username = currentUser.username;
      const newPassword = this.state.password;

      try {
        const changePasswordResult = await changePassword(currentUser, newPassword);
        const passwordChangeOK = changePasswordResult.accessToken != null;
        if (passwordChangeOK) {
          store.dispatch(passwordChangeSucceeded());
          await authenticateAndLoadProfileData(username, newPassword, 10);
        }
      }
      catch(err) {
        console.log(err);
        store.dispatch(passwordChangeFailed());
      }
    };
  }

  render() {
    return (
      <div className="container-center-horizontal">
        <div className="login-screen screen">
          <div className="proposition-summary">
            <div className="overlap-group-login-left">
              <WelcomeHeader />
              <IntroText textLines={this.props.introText} />
              <div className="login-and-discover-the-portal">{this.props.loginAndDiscoverThePortal}</div>
            </div>
          </div>
          <div className="login-area">
            <form>
              <div className="overlap-group-login-right">
                <img className="logo-compass-group_zwart_goud_rgb-1" src={this.props.logoCompassGroup_Zwart_Goud_Rgb1} />
                <div className="login ptserif-bold-black-xl">{this.props.changePassword}</div>
                <div className="please-enter-your-username-and-password ptserif-normal-black-l">
                  {this.props.pleaseEnterYourUsernameAndNewPassword}
                </div>
                <div className="username">
                  <input type="text"
                         className="username-input desktopbodycopy"
                         autoComplete="compass-client-portal-username"
                         value={this.props.passwordChangeState.username}
                         disabled="disabled" />
                  <img className="profile-filled-2" src="/img/profile---filled-2@2x.svg" />
                </div>
                <div className="password">
                  <input type="password"
                         className="password-input desktopbodycopy"
                         autoComplete="compass-client-portal-new-password"
                         value={this.state.password}
                         onChange={ event => this.setState({password: event.target.value}) } />
                  <img className="vector-30" src="/img/vector-41@2x.svg" />
                </div>
                <LoginButton clickHandler={this.onClickChangePasswordButton}
                             disabled={this.props.passwordChangeState.inProgress ? "disabled": ""}>
                             {this.props.changePasswordButtonText}
                </LoginButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { login, passwordChange } = state;
  return {
    passwordChangeState: passwordChange
  };
}

export default connect(mapStateToProps)(ChangePasswordScreen);
