import React from "react";
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Routes, Route, Navigate, useSearchParams } from "react-router-dom";
import { useInterval } from 'react-interval-hook';

import HomelandingPage from "./components/HomelandingPage";
import LocationFilter from "./components/LocationFilter";
import MyProfilePage from "./components/MyProfilePage";
import DocumentPage from "./components/DocumentPage";
import ClientsOverview from "./components/ClientsOverview";
import ReportManagementOverview from "./components/ReportManagementOverview";
import UsersOverview from "./components/UsersOverview";
import RegistrationScreen from "./components/RegistrationScreen";
import PasswordResetScreen from "./components/PasswordResetScreen";
import LastYearFilter from "./components/LastYearFilter";
import YearsFilterDocumentsSLA from "./components/YearsFilterDocumentsSLA";
import ThankYou from "./components/ThankYou";
import LoginScreen from "./components/LoginScreen";
import ChangePasswordScreen from "./components/ChangePasswordScreen";
import SLAParameters from "./components/SLAParameters";
import SearchBar from "./components/SearchBar";
import CompanyFilterDocumentsSLA from "./components/CompanyFilterDocumentsSLA";
import ChangesSaved from "./components/ChangesSaved";
import LocationFilterDocumentsSLA from "./components/LocationFilterDocumentsSLA";
import EnhanceServiceQuality from "./components/EnhanceServiceQuality";
import PerformanceDashboard from "./components/PerformanceDashboard";
import CompanyFilter from "./components/CompanyFilter";

import { viewportSizeUpdated } from './state/actions/viewportSize';
import { loadAuthorizationsAndProfileData } from './state/workflows/login';
import store from './state/store';

const introText = [
  "Even during a busy day you want to keep your guests happy, healthy and safe. This portal provides you with real time and clear insights on key performance indicators, such as: number of guests, revenue, and food choices.",
  "With easy dashboards and clear overviews you can adapt efficiently if needed.",
  "At Compass Group we want to help you provide a better life for the people around you. We do this by offering the best service and by using responsible products.",
  "Together, we can make a positive impact on our planet, its people and its animals."
];
const loginAndDiscover = "Login and discover the portal";

const header1Data = {
  header2Props: {
    className: "",
  },
};

const captureRateInformationData = {
  cancelAndSaveChangesProps: {
    children: "Fill in",
  },
};


const sLAParametersSelectorData = {
  slaParameters: "SLA Parameters",
  viewAddAndEditC: "View, add and edit contract input variables to be used in your dashboard",
  elevation1Radius10Props: {
    className: "elevation-1-radius-10-18",
  },
};

const homelandingPageData = {
  ellipse2: "/img/impression-800-1200.jpg",
  headerProps: header1Data,
  logoCompassGroup_Zwart_Rgb1: "/img/logo-compass-group-zwart-rgb-1@2x.png",
  profileSelectorProps: {
    elevation1Radius10Props: {
      className: "elevation-1-radius-10-16",
    },
  },
  pageSelectorProps: {
    elevation1Radius10Props: {
      className: "elevation-1-radius-10-17",
    },
  },
  typeTitlesProps: {
    subtitle: "Manage your Portal (for administrators) or Click on “Dashboards” to view your interactive Dashboard!",
    className: "type-titles-0"
  }
};

const achtergrondData = {
    className: "achtergrond",
};

const elevation1Radius107Data = {
    className: "elevation-1-radius-10-6",
};

const boxWithTitle2Data = {
  className: "box-with-title-1",
  elevation1Radius10Props: elevation1Radius107Data,
  innerSubjectsProps: {
    desktopH4Header: "General",
    className: "inner-subjects-1",
  },
};

const innerContent7Data = {
    desktopBodycopy18PtCalibriRegular: "Function",
    className: "inner-content-5",
};

const innerContent8Data = {
    desktopBodycopy18PtCalibriRegular: "Company",
    className: "inner-content-6",
};

const phoneNumberData = {
  innerContentProps: {
    desktopBodycopy18PtCalibriRegular: "Phone number",
    className: "inner-content-7",
  },
};

const innerContent10Data = {
    desktopBodycopy18PtCalibriRegular: "E-mail",
    className: "inner-content-8",
};

const innerContent11Data = {
    desktopBodycopy18PtCalibriRegular: "Name",
    className: "inner-content-9",
};

const myProfilePageData = {
    clickToOrDragAnd: <React.Fragment>Click to or drag and drop<br />(SVG, PNG, JPG or GIF)</React.Fragment>,
    achtergrondProps: achtergrondData,
    boxWithTitle2Props: boxWithTitle2Data,
    innerContent7Props: innerContent7Data,
    innerContent8Props: innerContent8Data,
    phoneNumberProps: phoneNumberData,
    innerContent9Props: innerContent10Data,
    innerContent10Props: innerContent11Data,
    headerProps: header1Data,
    typeTitlesProps: {
    welcome: "Profile",
    subtitle: "View your profile",
    className: "type-titles-1",
},
    showBasicProfile: true,
    showRoles: false,
    alternativeImage: "/img/background-large.jpg",
};

const elevation1Radius108Data = {
    className: "elevation-1-radius-10-8",
};

const innerSubjects3Data = {
    desktopH4Header: "Invoices",
    className: "inner-subjects-2",
};

const elevation1Radius1022Data = {
    src: "/img/elevation-1---radius-10@1x.png",
};

const viewAll2Data = {
    className: "view-all-4",
};

const innerSubjects4Data = {
    desktopH4Header: "Annual sustainability reporting",
    className: "inner-subjects-3",
};

const elevation1Radius109Data = {
    className: "elevation-1-radius-10-10",
};

const innerSubjects5Data = {
    desktopH4Header: "Documents",
    className: "inner-subjects-4",
};

const header22Data = {
    className: "header-8",
};

const header3Data = {
    header2Props: header22Data,
};

const typeTitles3Data = {
    welcome: "Documents",
    subtitle: "An overview of all the documents in this account",
    className: "type-titles-2",
};

const documentPageData = {
    contracts: [
    {
      filename: "4765893.xlsx",
      filezise: "6,5MB",
      image: "/img/image-17@2x.png",
      date: "April 24, 2022",
      locked: true,
    },
    {
      filename: "Framework Agreement v0.5",
      filezise: "6,4 MB",
      image: "/img/image-14@2x.png",
      date: "May 3, 2022",
      locked: true,
    },
    {
      filename: "146046.docx",
      filezise: "4.8 MB",
      image: "/img/image-16@2x.png",
      date: "May 3, 2022",
    },
    {
      filename: "Window cleaning 2022.docx",
      filezise: "2,1 MB",
      image: "/img/image-16@2x.png",
      date: "May 17, 2022",
    },
  ],
    achtergrondProps: achtergrondData,
    elevation1Radius102Props: elevation1Radius1022Data,
    elevation1Radius102Props2: elevation1Radius109Data,
    innerSubjects1Props: innerSubjects3Data,
    innerSubjects2Props: innerSubjects4Data,
    innerSubjects3Props: innerSubjects5Data,
    headerProps: header3Data,
    typeTitlesProps: typeTitles3Data,
};

const frame42Data = {
    text: "Thank you!",
    explanation: "You can find the details in your mailbox",
    className: "frame-4-1",
};

const middleButton1Data = {
    children: "Close",
    className: "",
};

const popup21Data = {
    frame4Props: frame42Data,
    middleButtonProps: middleButton1Data,
};

const thankYouData = {
    popup2Props: popup21Data,
};

const loginButtonData = {
    children: "Login",
};


const loginScreenData = {
    introText,
    loginAndDiscoverThePortal: loginAndDiscover,
    login: "Login",
    pleaseEnterYourUsernameAndPassword: "Please enter your username and password",
    username: '',
    password: '',
    loginFailed: "Login failed",
    forgotPassword: "Forgot password?",
    spanText1: "No account yet?",
    spanText2: " Register!",
    logoCompassGroup_Zwart_Goud_Rgb1: "/img/logo-compass-group-zwart-goud-rgb-1@2x.png",
    loginButtonProps: loginButtonData,
};

const changePasswordScreenData = {
    introText,
    loginAndDiscoverThePortal: loginAndDiscover,
    changePassword: "Change your password",
    pleaseEnterYourUsernameAndNewPassword: "Enter your username and new password below",
    usernameLabel: "Username",
    passwordLabel: "New Password",
    logoCompassGroup_Zwart_Goud_Rgb1: "/img/logo-compass-group-zwart-goud-rgb-1@2x.png",
    changePasswordButtonText: "Set Password",
};

const registrationScreenData = {
    introText,
    loginAndDiscoverThePortal: loginAndDiscover,
    loginButtonProps: {children: "Register"}
};

const passwordResetScreenData = {
    introText,
    loginAndDiscoverThePortal: loginAndDiscover,
    loginButtonProps: {children: "Set Password"}
};

const elevation1Radius1010Data = {
    className: "elevation-1-radius-10-12",
};

const innterContent31Data = {
    desktopBodycopy20PtCalibriRegular: "Sustainability",
};

const frame11Data = {
    desktopBodycopy20PtCalibriRegular: "Benchmark year",
    desktopBodycopy18PtCalibriRegular: "",
};

const frame12Data = {
    desktopBodycopy20PtCalibriRegular: "Food waste",
    desktopBodycopy18PtCalibriRegular: "% of total consumtion waste",
};

const frame13Data = {
    desktopBodycopy20PtCalibriRegular: "Food recycled",
    desktopBodycopy18PtCalibriRegular: "% of total consumption waste",
};

const frame14Data = {
    desktopBodycopy20PtCalibriRegular: "Food composted",
    desktopBodycopy18PtCalibriRegular: "% of total consumption waste",
};

const frame15Data = {
    desktopBodycopy20PtCalibriRegular: "Social return hours",
    desktopBodycopy18PtCalibriRegular: "% of total offer",
    className: "frame-1-1",
};

const innterContent32Data = {
    desktopBodycopy20PtCalibriRegular: "SCR",
    className: "innter-content-3",
};

const fillInBox3Data = {
    className: "fill-in-box-1",
};

const frame16Data = {
    desktopBodycopy20PtCalibriRegular: "Healthy nutrition",
    desktopBodycopy18PtCalibriRegular: "% of total offer",
    className: "frame-1-2",
};

const innterContent33Data = {
    desktopBodycopy20PtCalibriRegular: "Products",
    className: "innter-content-3",
};

const innterContent34Data = {
    desktopBodycopy20PtCalibriRegular: "Customer satisfaction",
    className: "innter-content-3-2",
};

const frame17Data = {
    desktopBodycopy20PtCalibriRegular: "Customer satisfaction",
    desktopBodycopy18PtCalibriRegular: "Scale 1-10",
    className: "frame-1-3",
};

const fillInBox5Data = {
    className: "fill-in-box-1",
};

const cancelAndSaveChanges22Data = {
    saveChanges: "Save changes",
    className: "cancel-and-save-changes-2",
};

const innerSubjects6Data = {
    desktopH4Header: "Performance objectives",
    className: "inner-subjects-5",
};

const elevation1Radius1011Data = {
    className: "elevation-1-radius-10-14",
};

const frame18Data = {
    desktopBodycopy20PtCalibriRegular: "Building visitors",
    desktopBodycopy18PtCalibriRegular: "Average per day",
};

const innerContent22Data = {
    frame1Props: frame18Data,
};

const cancelAndSaveChanges23Data = {
    saveChanges: "Save changes",
    className: "cancel-and-save-changes-3",
};

const innerSubjects7Data = {
    desktopH4Header: "Input variables",
    className: "inner-subjects-6",
};

const typeTitles4Data = {
    welcome: "SLA Parameters",
    subtitle: "View, add and edit input variables to be used in your dashboard",
    className: "type-titles-3",
};

const sLAParametersData = {
    rectangle42: "/img/rectangle-42@1x.png",
    number1: "2021",
    percent1: "8%",
    percent2: "65%",
    companyAbc: "Company ABC",
    locationZ: "Location Z",
    number2: "2022",
    fillInToKnowYourCaptureRate: "Fill in to know your capture rate",
    logoCompassGroup_Zwart_Rgb1: "/img/logo-compass-group-zwart-rgb-1@2x.png",
    spanText1: <React.Fragment>Sarah van As<br /></React.Fragment>,
    spanText2: "Facility Manager",
    achtergrondProps: achtergrondData,
    elevation1Radius101Props: elevation1Radius1010Data,
    innterContent31Props: innterContent31Data,
    frame11Props: frame11Data,
    frame12Props: frame12Data,
    frame13Props: frame13Data,
    frame14Props: frame14Data,
    frame15Props: frame15Data,
    innterContent32Props: innterContent32Data,
    fillInBox1Props: fillInBox3Data,
    frame16Props: frame16Data,
    innterContent33Props: innterContent33Data,
    innterContent34Props: innterContent34Data,
    frame17Props: frame17Data,
    fillInBox2Props: fillInBox5Data,
    cancelAndSaveChanges21Props: cancelAndSaveChanges22Data,
    innerSubjects1Props: innerSubjects6Data,
    elevation1Radius102Props: elevation1Radius1011Data,
    innerContent2Props: innerContent22Data,
    cancelAndSaveChanges22Props: cancelAndSaveChanges23Data,
    innerSubjects2Props: innerSubjects7Data,
    typeTitlesProps: typeTitles4Data,
    headerProps: header3Data,
    typeTitlesProps: typeTitles3Data,
};

const frame43Data = {
    text: "Your change(s) have been saved succesfully",
    explanation: "",
    className: "frame-4-2",
};

const middleButton2Data = {
    children: "Close",
    className: "",
};

const popup22Data = {
    frame4Props: frame43Data,
    middleButtonProps: middleButton2Data,
};

const changesSavedData = {
    popup2Props: popup22Data,
};

const frame44Data = {
    text: "Enhance service quality",
    explanation: "By actively asking employees via a QR code on the table to review and/or share suggestions for improvement",
    className: "frame-4-3",
};

const middleButton3Data = {
    children: "E-mail me the details",
    className: "middle-button-4",
};

const popup23Data = {
    frame4Props: frame44Data,
    middleButtonProps: middleButton3Data,
};

const enhanceServiceQualityData = {
    popup2Props: popup23Data,
};

const elevation1Radius1023Data = {
    src: "/img/elevation-1---radius-10-1@1x.png",
    className: "elevation-1-radius-10-7",
};

const perHour1Data = {
    perYear: "Per year",
};

const elevation1CaramelRadius102Data = {
    className: "elevation-1-caramel-radius-10-1",
};

const perHour2Data = {
    perYear: "Per hour",
    className: "per-hour-1",
};

const elevation1CaramelRadius103Data = {
    className: "elevation-1-caramel-radius-10-2",
};

const header23Data = {
    className: "header-9",
};

const header4Data = {
    header2Props: header23Data,
};

const typeTitles5Data = {
    welcome: "Performance Dashboard",
    subtitle: "Insights in the Key Performance Indicators",
    className: "type-titles-4",
};

const performanceDashboardData = {
    achtergrondProps: achtergrondData,
    headerProps: header4Data,
    typeTitlesProps: typeTitles5Data,
};

const reportManagementOverviewPageData = {
  achtergrondProps: achtergrondData,
  headerProps: header3Data,
  typeTitlesProps: {
    welcome: "Manage your the BI report assignments",
    subtitle: "Manage the assignment of available PowerBI reports for account&hospitality managers",
    className: "type-titles-4",
  }
};

const clientsOverviewPageData = {
  achtergrondProps: achtergrondData,
  headerProps: header3Data,
  typeTitlesProps: {
    welcome: "Manage your clients",
    subtitle: "Manage your Compass clients and Principal users",
    className: "type-titles-4",
  }
};

const usersOverviewPageData = {
  achtergrondProps: achtergrondData,
  headerProps: header3Data,
  typeTitlesProps: {
    welcome: "Manage your users",
    subtitle: "Manage access for your colleagues and assign which report(s) they can access",
    className: "type-titles-4",
  }
};


// Define functional component containing all the routes (router has trouble with class based components)
const ClientPortalRoutes = (props) => {
  const {
    userIsLoggedIn,
    userIsLoggedOut,
    logoutMessage,
    changePassword,
    passwordReset,
    userRegistration } = props;

  // Evaluate the HTTP query parameters first
  const [ searchParams ] = useSearchParams();
  const passwordResetToken = searchParams.get("pwreset");
  const registrationToken = searchParams.get("reg");

  // Based on the query parameters and state, return a set of routes
  if (!!passwordResetToken) {
    // If we are on a password reset URL (?pwreset=<token>), set routing table which enforces the password reset.
    // But if the password reset was already completed, we can drop the ?reg search param and proceed to login.
    const passwordResetState = passwordReset || {};
    const passwordResetCompleted = !!passwordResetState.username && !!passwordResetState.password;
    if (passwordResetCompleted) {
      return (
        <Routes>
          <Route path="/*" element={<Navigate to="/login" replace={true} />} />
        </Routes>
      );
    }
    else {
      return (
        <Routes>
          <Route path="/*" element={<PasswordResetScreen token={passwordResetToken} {...passwordResetScreenData} />} />
        </Routes>
      );
    }
  }
  else if (!!registrationToken) {
    // If we are on a registration URL (?reg=<token>), set routing table which enforces the registration page
    // But if the registration was already completed, we can drop the ?reg search param and proceed.
    const registrationCompleted = !!(userRegistration || {}).username && !!(userRegistration || {}).initialPassword;
    if (registrationCompleted) {
      return (
        <Routes>
          <Route path="/*" element={<Navigate to="/login" replace={true} />} />
        </Routes>
      );
    }
    else {
      return (
        <Routes>
          <Route path="/*" element={<RegistrationScreen token={registrationToken} {...registrationScreenData} />} />
        </Routes>
      );
    }
  }
  else if (!!changePassword) {
    // Set routing table which enforces password change
    return (
       <Routes>
        <Route exact path="/change-password" element={<ChangePasswordScreen {...changePasswordScreenData} />} />
        <Route path="/*" element={<Navigate to="/change-password" />} />
      </Routes>
    );
  }
  else if (!!userIsLoggedIn) {
    // Set routing table with all the routes available to an authenticated user
    return (
      <Routes>
        <Route exact path="/home" element={<HomelandingPage {...homelandingPageData} />} />
        <Route exact path="/profile" element={<MyProfilePage {...myProfilePageData} />} />
        <Route exact path="/documents" element={<DocumentPage {...documentPageData} />} />
        <Route exact path="/performance-dashboard" element={<PerformanceDashboard {...performanceDashboardData} />} />
        <Route exact path="/report-assignments" element={<ReportManagementOverview {...reportManagementOverviewPageData} />} />
        <Route exact path="/clients" element={<ClientsOverview {...clientsOverviewPageData} />} />
        <Route exact path="/users" element={<UsersOverview {...usersOverviewPageData} />} />
        <Route path="/*" element={<Navigate to="/home" />} />
      </Routes>
    );
  }
  else {
    // Set default routing table (which enforces login).
    // For new registrations and password resets prefill the username/password fields.
    let loginScreenProps = loginScreenData;
    const defaultMessage = loginScreenProps.pleaseEnterYourUsernameAndPassword;
    if (!!userRegistration && !!userRegistration.username && !!userRegistration.initialPassword) {
      loginScreenProps = {
        ...loginScreenProps,
        pleaseEnterYourUsernameAndPassword: 'Welcome new user! Please proceed to login!',
        username: userRegistration.username,
        password: userRegistration.initialPassword
      };
    }
    else if (!!passwordReset && !!passwordReset.username && !!passwordReset.password) {
      loginScreenProps = {
        ...loginScreenProps,
        pleaseEnterYourUsernameAndPassword: 'Password reset was successful! Please proceed to login!',
        username: passwordReset.username,
        password: passwordReset.password
      };
    }
    else if (!!userIsLoggedOut) {
      loginScreenProps = {
        ...loginScreenProps,
        pleaseEnterYourUsernameAndPassword: logoutMessage || defaultMessage
      };
    }

    return (
      <Routes>
        <Route exact path="/login" element={<LoginScreen {...loginScreenProps} />} />
        <Route path="/*" element={<Navigate to="/login" />} />
      </Routes>
    );
  }
};

// Define another functional component to encapsulate the background timers
const BackgroundFunctions = (props) => {

    // Setup a timer
    useInterval(
      async () => {
        const { token } = store.getState();
        const { cognitoAuthenticationToken } = token;
        if (!!cognitoAuthenticationToken) {
          // Refreshes application token, profile data (basic and full) and dashboard attributes
          await loadAuthorizationsAndProfileData(cognitoAuthenticationToken);
        }
      },
      interval=300000
    );

    return null;
};


// Encapsulate the routes and background worker component in class based root component <App />
class App extends React.Component {
  constructor(props) {
    // Initialization
    super();
    this.props = props;
    this.state = {};
    this.mounted = false;

    /*
    Here on root level we only handle the elements of the state which impact the routing
    (i.e. login, password change/reset, user registration).
    Subcomponents of <App> map their own relevant parts of state through mapStateToProps() functions.
    */
    store.subscribe(() => {
      const { login, passwordChange, passwordReset, userRegistration } = store.getState();
      const newState = { login, passwordChange, passwordReset, userRegistration };
      if (this.mounted)
        this.setState(newState);
      else
        this.state = newState;
    });

    // Method binding
    this.handleScreenResolutionChanges = this.handleScreenResolutionChanges.bind(this);

    // Connect the viewport size events
    window.addEventListener('resize', this.handleScreenResolutionChanges);
    this.handleScreenResolutionChanges();
  }

  // Methods
  handleScreenResolutionChanges() {
    store.dispatch(viewportSizeUpdated(window.innerWidth, window.innerHeight));
  }

  render() {
    const { login, passwordChange, passwordReset, userRegistration } = this.state;
    const userIsLoggedIn = (login || {}).loggedInUser != null;
    const { loggedOut, logoutMessage } = (login || {});
    const changePassword = (passwordChange || {}).desired;

    return (
      <Provider store={store}>
        <BackgroundFunctions />

        <Router>
          <ClientPortalRoutes userIsLoggedIn={userIsLoggedIn}
                              userIsLoggedOut={loggedOut || false}
                              logoutMessage={logoutMessage}
                              changePassword={changePassword}
                              passwordReset={passwordReset}
                              userRegistration={userRegistration} />
        </Router>
      </Provider>
    )
  }

  componentDidMount() {
    this.mounted = true;
  }
}

export default App;
