const defaultState = {
  userId: null,
  employee: null,
  organization: null,
  roles: null,
};

export function profileReducer(state = defaultState, action) {
  switch (action.type) {

    case 'GET_PROFILE_INITIATED':
      return state

    case 'GET_PROFILE_SUCCEEDED':
      const { employee, organization } = action.payload;
      const { userId, firstName, lastName, functionTitle, eMailAddress } = employee;
      const {
        id,
        name,                             // only available in API response from /profile/full
        clientContractNumbers,            // only available in API response from /profile/full
        isAdmin,
        isAccountAndHospitalityManager,
        isClientPrincipal,
        availableClientContracts,
        clientPrincipalUser
      } = organization;

      return {
        ...state,
        userId,
        employee: {
          firstName,
          lastName,
          eMailAddress,
          functionTitle
        },
        organization: {
          id,
          name,                     // organization name (full API only)
          clientContractNumbers,    // these are the numbers configured for the current user (basic + full API)
          availableClientContracts, // these are all available client contracts in the organization (full API only)
          clientPrincipalUser,      // client principal name and email (full API only)
        },
        roles: {
          isAdmin,
          isAccountAndHospitalityManager,
          isClientPrincipal
        }
      };

    case 'USER_LOGIN_FAILED':
    case 'USER_LOGGED_OUT':
    case 'GET_PROFILE_FAILED':
      return {
        ...state,
        ...defaultState
      };

    default:
      return state;
  }
}
