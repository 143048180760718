import { getData } from "./serviceClient";

export async function getRegistrationLink(appToken, tokenRequestArgs) {
  return await getData('principal', 'registration/generate', appToken, tokenRequestArgs);
};

export async function parseRegistrationToken(registrationToken) {
  return await getData('public', 'registration/parse', {}, {registration_token: registrationToken});
};

export async function registerNewUser(registrationToken, userAttributes) {
  const requestBody = {
    registration_token: registrationToken,
    user_attributes: userAttributes
  };
  return await getData('public', 'registration/new', {}, requestBody);
}
