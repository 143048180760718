import React from "react";
import "./Elevation1Radius10.css";

function Elevation1Radius10(props) {
  const { className } = props;

  return (
    <div className={`elevation-1-radius-10-2 ${className || ""}`}>
      <div className="elevation-1-radius-10-3"></div>
    </div>
  );
}

export default Elevation1Radius10;
