import React from "react";
import Popup2 from "../Popup2";
import "./ThankYou.css";

function ThankYou(props) {
  const { popup2Props } = props;

  return (
    <div className="container-center-horizontal">
      <div className="thank-you screen">
        <div className="thank-you-1">
          <div className="overlap-group-17">
            <Popup2 frame4Props={popup2Props.frame4Props} middleButtonProps={popup2Props.middleButtonProps} />
            <img className="mail" src="/img/mail@2x.svg" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThankYou;
