const defaultState = {
  loginInProgress: false,   // boolean, indicates login transaction initiated but not yet completed
  loggedInUser: null,       // username
  authToken: null,          // Cognito access token
  userAttributes: null,     // Cognito user attributes
  loggedOut: false,         // logout completed
  logoutMessage: null,      // message passed to the logout action
};

export function loginReducer(state = defaultState, action) {
  switch (action.type) {

    case 'USER_LOGIN_INITIATED':
      return {
        ...state,
        loggedOut: false,
        logoutMessage: null,
        loginInProgress: true,
      };

    case 'USER_LOGIN_SUCCEEDED':
      const { loggedInUser, authToken, userAttributes } = action.payload;
      return {
        ...state,
        loginInProgress: false,
        loggedInUser,
        authToken,
        userAttributes
      };

    case 'USER_LOGIN_FAILED':
    case 'USER_LOGGED_OUT':
      const { message } = (action.payload || {}) || null;
      return {
        ...state,
        ...defaultState,
        loggedOut: true,
        logoutMessage: message
      };

    default:
      return state;
  }
}
