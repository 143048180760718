import React from "react";
import { Link } from "react-router-dom";
import "./YearsFilterDocumentsSLA.css";

function YearsFilterDocumentsSLA(props) {
  const { number1, number2, number3, number4, number5, number6, customDate } = props;

  return (
    <div className="container-center-horizontal">
      <div className="years-filter-documents-sla screen">
        <div className="years-filter-documents-sla-1">
          <div className="x2022-1">
            <div className="overlap-group1-5">
              <img className="vector-28" src="/img/vector-40@2x.svg" />
              <div className="number-4 desktopfilters">{number1}</div>
            </div>
          </div>
          <div className="overlap-group-16">
            <div className="flex-row-10">
              <img className="vector-27" src="/img/vector-8@2x.svg" />
              <div className="number-3 desktopfilters">{number2}</div>
            </div>
            <div className="flex-row-11">
              <img className="vector-27" src="/img/vector-8@2x.svg" />
              <div className="number-3 desktopfilters">{number3}</div>
            </div>
            <div className="flex-row-12">
              <img className="vector-27" src="/img/vector-8@2x.svg" />
              <div className="number-5 desktopfilters">{number4}</div>
            </div>
            <div className="flex-row-13">
              <img className="vector-27" src="/img/vector-8@2x.svg" />
              <div className="number-3 desktopfilters">{number5}</div>
            </div>
            <div className="flex-row-14">
              <img className="vector-27" src="/img/vector-7@2x.svg" />
              <div className="number-3 desktopfilters">{number6}</div>
            </div>
            <img className="line-18-2" src="/img/line-18@2x.svg" />
            <div className="flex-row-15">
              <Link to="/my-profile-page">
                <img className="vector-29" src="/img/vector-6@2x.svg" />
              </Link>
              <Link to="/my-profile-page">
                <div className="custom-date-1 desktopfilters">{customDate}</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default YearsFilterDocumentsSLA;
