import './IntroText.css'
import React from 'react';

const IntroText = (props) => {
  const { textLines } = props;
  if (!!textLines)
    return (
      <React.Fragment>
        {textLines.map((line, idx) => <div key={`intro-text-${idx}`} className="intro-text">{line}</div>)}
      </React.Fragment>
    );
};

export default IntroText;
