import React from "react";
import "./InnerSubjects.css";

function InnerSubjects(props) {
  const { desktopH4Header, className } = props;

  return (
    <div className={`inner-subjects ${className || ""}`}>
      <div className="desktop-h4-header-2 ptserif-bold-black-l">{desktopH4Header}</div>
      <img className="line-2" src="/img/line-2@2x.svg" />
    </div>
  );
}

export default InnerSubjects;
