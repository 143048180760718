const defaultState = {
  registrationInProgress: false,
  username: null,
  initialPassword: null
};

export function registrationReducer(state = defaultState, action) {
  switch (action.type) {

    case 'USER_REGISTRATION_INITIATED':
      return {
        ...state,
        registrationInProgress: true
      };

    case 'USER_REGISTRATION_SUCCEEDED':
      const { username, initialPassword } = action.payload;
      return {
        ...state,
        registrationInProgress: false,
        username,
        initialPassword
      };

    case 'USER_REGISTRATION_FAILED':
      return {
        ...state,
        ...defaultState
      };

    default:
      return state;
  }
}
