import "./LeftNavigationBar.css";

import React from "react";
import { connect } from 'react-redux';
import { Link, useLocation } from "react-router-dom";

import { Tooltip } from '@mui/material';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import InsertChartOutlinedRoundedIcon from '@mui/icons-material/InsertChartOutlinedRounded';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';


const DocumentsPageImplemented = false; // TODO


function SideBarLink(linkSpecs, currentPage) {
  const { target, IconClass, tooltip } = linkSpecs;
  const absoluteLinkPath = `/${target}`;
  const currentPageIconCssClassNames = (target === currentPage) ? 'transform-svg-colors' : '';
  const cssClassNames = `left-navigationbar-icon ${target}-icon position-top ${currentPageIconCssClassNames}`;
  const linkKey = `left-sidebar-link-${target}`;

  return (
    <Link to={absoluteLinkPath} key={linkKey}>
      <Tooltip key={`sidebar-link-${target}`}
              arrow placement="right"
              title={(target !== currentPage) ? tooltip : null}>
        <IconClass className={cssClassNames} fontSize="large" />
      </Tooltip>
    </Link>
  );
}


function LeftNavigationBar(props) {
  const { className } = props;

  const currentPath = (useLocation() || {}).pathname;
  const currentPage = currentPath.replace(/^\/+/, '');

  const currentUserHasAccessLevel = (level) => {
    const levels = {
      user: 1,
      principal: 2,
      manager: 3,
      admin: 4
    };

    const myRoles = (props.profile || {}).roles || {};
    let maxLevel;
    if (myRoles.isAdmin)
      maxLevel = levels.admin;
    else if (myRoles.isAccountAndHospitalityManager)
      maxLevel = levels.manager;
    else if (myRoles.isClientPrincipal)
      maxLevel = levels.principal;
    else
      maxLevel = levels.user;

    return levels[level] <= maxLevel;
  }

  // Links at the left top
  const sideBarLinkTargets = [
    { target: 'home', IconClass: HomeOutlinedIcon, tooltip: 'Home page'  },
    { target: 'profile', IconClass: PersonOutlineOutlinedIcon, tooltip: 'View your profile' },
  ];
  if (DocumentsPageImplemented) {
    sideBarLinkTargets.push({ target: 'documents', IconClass: FolderOutlinedIcon, tooltip: 'Your documents' });
  }

  // Plus the ones that are for specific user types
  if (currentUserHasAccessLevel('admin')) {
    sideBarLinkTargets.push(
      { target: "report-assignments", IconClass: ContentCopyIcon, tooltip: 'Manage BI reports' }
    );
  }

  if (currentUserHasAccessLevel('manager')) {
    sideBarLinkTargets.push(
      { target: 'clients', IconClass: ApartmentOutlinedIcon, tooltip: 'Manage your Compass clients' }
    );
  };

  if (currentUserHasAccessLevel('principal')) {
    sideBarLinkTargets.push(
      { target: 'users', IconClass: GroupOutlinedIcon, tooltip: 'Manage access for your colleagues' }
    );
  };

  const biReports = (props.dashboardAttributes || {}).reports || [];
  const showPerformanceDashboardPage = biReports.length > 0;
  if (showPerformanceDashboardPage) {
    sideBarLinkTargets.push(
      { target: 'performance-dashboard', IconClass: InsertChartOutlinedRoundedIcon, tooltip: 'View your reports'  }
    );
  };

  return (
    <div className="left-navigation-bar-container">
      <div className={`left-navigationbar ${className || ""}`}>
        {sideBarLinkTargets.map((target) => SideBarLink(target=target, currentPage=currentPage))}
      </div>
    </div>
  );
}


function mapStateToProps(state) {
  const { profile, dashboardAttributes } = state;
  return {
    profile,
    dashboardAttributes
  };
}

export default connect(mapStateToProps)(LeftNavigationBar);
