import { getApiUrl } from "./serviceDefinitions";

function composeResponseDataKey(api) {
  // I.e. API dashboard-attributes --> data key 'dashboard_attributes' (dash is not nice in object keys)
  const dataKey = api.replaceAll('-', '_').toLowerCase();

  // Check for the final segment
  // Suffixes /basic and /full get special treatment. i.e. API profile/basic has key 'profile'
  // but users/list has key 'users/list'
  const lastSegment = api.split('/').pop();
  if (['basic', 'full'].includes(lastSegment)) {
    return dataKey.slice(0, dataKey.length - lastSegment.length - 1);
  }
  else {
    return dataKey;
  }
}

export async function getData(accessLevel, api, token, body) {
  const friendlyApiName = api.charAt(0).toUpperCase() + api.slice(1).toLowerCase();
  const url = getApiUrl(accessLevel, api);
  const fetchArgs = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    redirect: 'follow',
    headers: {},
    body: JSON.stringify(body || {})
  };

  // Most APIs require an auth token. Some don't. This client supports requests with and without auth token.
  if (!!token) {
    fetchArgs.headers['Authorization'] = `Token ${token}`;
  }

  const response = await fetch(url, fetchArgs);
  if (response.status == 200) {
    console.debug(`${friendlyApiName} API responded with status code 200`);
    const responseDataKey = composeResponseDataKey(api);
    const responseBody = await response.json();
    return {[responseDataKey]: responseBody}
  }
  else if (response.status == 404) {
    console.warning(`${friendlyApiName} API failed with status code ${response.status}`);
    return {};
  }
  else {
    console.error(`${friendlyApiName} API failed with status code ${response.status}`);
  }
}
