import React from "react";
import Popup2 from "../Popup2";
import "./ChangesSaved.css";

function ChangesSaved(props) {
  const { popup2Props } = props;

  return (
    <div className="container-center-horizontal">
      <div className="changes-saved screen">
        <div className="changes-saved-1">
          <div className="overlap-group-25">
            <Popup2 frame4Props={popup2Props.frame4Props} middleButtonProps={popup2Props.middleButtonProps} />
            <img className="vector-57" src="/img/vector-57@2x.svg" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangesSaved;
