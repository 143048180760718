import React from "react";
import Frame4 from "../Frame4";
import MiddleButton from "../MiddleButton";
import "./Popup2.css";

function Popup2(props) {
  const { frame4Props, middleButtonProps } = props;

  return (
    <div className="thankyou-popup">
      <Frame4 text={frame4Props.text} explanation={frame4Props.explanation} className={frame4Props.className} />
      <MiddleButton className={middleButtonProps.className}>{middleButtonProps.children}</MiddleButton>
    </div>
  );
}

export default Popup2;
