import React from "react";
import { Link } from "react-router-dom";
import "./LocationFilter.css";

function LocationFilter(props) {
  const { locationZ1, locationA, locationB, locationC, locationZ2, addLocation } = props;

  return (
    <div className="container-center-horizontal">
      <div className="location-filter screen">
        <div className="location-filter-1">
          <div className="overlap-group-7">
            <img className="vector-6" src="/img/vector-5@2x.svg" />
            <div className="location-z desktopfilters">{locationZ1}</div>
          </div>
          <div className="filter-options">
            <div className="flex-row-1">
              <img className="vector-5" src="/img/vector-8@2x.svg" />
              <div className="location desktopfilters">{locationA}</div>
            </div>
            <div className="flex-row">
              <img className="vector-5" src="/img/vector-8@2x.svg" />
              <div className="location desktopfilters">{locationB}</div>
            </div>
            <div className="flex-row">
              <img className="vector-5" src="/img/vector-8@2x.svg" />
              <div className="location desktopfilters">{locationC}</div>
            </div>
            <div className="flex-row-2">
              <img className="vector-5" src="/img/vector-7@2x.svg" />
              <div className="location desktopfilters">{locationZ2}</div>
            </div>
            <img className="line-18" src="/img/line-18@2x.svg" />
            <div className="flex-row-3">
              <Link to="/my-profile-page">
                <img className="vector-7" src="/img/vector-6@2x.svg" />
              </Link>
              <Link to="/my-profile-page">
                <div className="add-location desktopfilters">{addLocation}</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocationFilter;
