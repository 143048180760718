import { getData } from "./serviceClient";


export async function listAllReportsAndManagers(appToken) {
  return await getData('admin', 'powerbi-reports/list', appToken);
}

export async function saveReportBindings(appToken, report) {
  return await getData('admin', 'powerbi-reports/save', appToken, {report});
}

export async function cleanupReportBindings(appToken) {
  return await getData('admin', 'powerbi-reports/cleanup', appToken);
}

export async function replaceReportBindings(appToken, oldReportId, newReportId) {
  const body = {
    report: {
        old_report_id: oldReportId,
        new_report_id: newReportId
    }
  };
  return await getData('admin', 'powerbi-reports/replace', appToken, body);
}
