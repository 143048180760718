export function passwordChangeInitiatedByUser(username) {
  return {
    type: 'PASSWORD_CHANGE_INITIATED_USER',
    payload: {
      username: username    // username of the logged in user or else the value filled into the Username textbox on the login screen
    }
  };
}

export function passwordChangeInitiatedByServer(cognitoUser) {
  return {
    type: 'PASSWORD_CHANGE_INITIATED_SERVER',
    payload: {
      cognitoUser: cognitoUser,    // CognitoUser object representing the logged in user
      username: cognitoUser.username
    }
  };
}

export function passwordChangeRequestSentToServer() {
  return {
    type: 'PASSWORD_CHANGE_REQUEST_SENT'
  };
}


export function passwordChangeSucceeded() {
  return {
    type: 'PASSWORD_CHANGE_SUCCEEEDED'
  };
}

export function passwordChangeFailed() {
  return {
    type: 'PASSWORD_CHANGE_FAILED'
  };
}
