import React from "react";
import InnerContent from "../InnerContent";
import "./PhoneNumber.css";

function PhoneNumber(props) {
  const { innerContentProps } = props;

  return (
    <div className="phone-number">
      <InnerContent
        desktopBodycopy18PtCalibriRegular={innerContentProps.desktopBodycopy18PtCalibriRegular}
        desktopBodycopy20PtCalibriRegular={innerContentProps.desktopBodycopy20PtCalibriRegular}
        className={innerContentProps.className}
      />
      <img className="edit-6" src="/img/edit@2x.svg" />
    </div>
  );
}

export default PhoneNumber;
