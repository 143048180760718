import React from "react";
import { Link } from "react-router-dom";
import "./MiddleButton.css";

function MiddleButton(props) {
  const { children, className } = props;

  return (
    <div className={`middle-button ${className || ""}`}>
      <div className="middle-button-1 ptserif-bold-white-xs">{children}</div>
    </div>
  );
}

export default MiddleButton;
