import './EditableTable.css'

import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import { MaterialReactTable } from 'material-react-table';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';

import MultiSelectDDL from "../MultiSelectDDL";


// Constants
const booleanOptions = {
  true: "Yes",
  false: "No"
};

// Global functions
function validateRequired(value) { return !!value.length; }
function validateEmail(email) {
  const eMailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !!email.length && email.toLowerCase().match(eMailRegExp);
}
function validateAge(age) {
  return age >= 0 && age < 120;
}
function parseBoolean(value) {
  if (!value)
    return false;
  else if (typeof(value) == 'string')
    return value.toLowerCase() == booleanOptions[true].toLowerCase();
  else
    return !!value;
}
function parseArray(value) {
  return typeof(value) === 'string' ? value.split(',').map((s) => s.trim()) : value;
}
function setNested(key, value, obj) {
  let keyParts = key.split('.');
  let innerObj = obj;
  keyParts.slice(0, -1).forEach((k) => {
    if (!(k in innerObj))
      innerObj[k] = {};
    innerObj = innerObj[k];
  });
  innerObj[keyParts[keyParts.length - 1]] = value;
  return obj;
}
function applyNesting(obj, out) {
  let transformed = {}
  Object.entries(obj).forEach((kvp) => {
    if(kvp[0] && typeof(kvp[0]) == 'string' && kvp[0].includes('.')) {
      setNested(kvp[0], kvp[1], transformed);
    }
    else {
      transformed = {
        [kvp[0]]: kvp[1],
        ...transformed,
      };
    }
  });
  return transformed;
}

// The functional component
const EditableTable = (props) => {
  // Destructure props
  const { tableId, className, recordTypeName, columns, data, settings } = props;
  const {
    canEditExistingRow,
    editExistingRowText,
    canDeleteExistingRow,
    saveHandler,
    deleteHandler } = settings || {};
  const columnVisibility = Object.fromEntries(
    new Map(
      columns
      .filter((column) => !!column.accessorKey && !!column.hiddenByDefault)
      .map((column) => [column.accessorKey, false])
    )
  );

  // Initialize state
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState(() => props.data);
  const [validationErrors, setValidationErrors] = useState({});
  const [exposedValues, setExposedValues] = useState({});

  // Define functions to support rendering
  const renderNewRowButton = (settings) => {
    if (settings.canCreateNewRow) {
      return (
        <Button color="secondary" onClick={() => setCreateModalOpen(true)} variant="contained">
          {settings.createNewRowText}
        </Button>
      );
    }
    else {
      return "";
    }
  };

  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid =
            cell.column.id === 'email'
              ? validateEmail(event.target.value)
              : cell.column.id === 'age'
              ? validateAge(+event.target.value)
              : validateRequired(event.target.value);
          if (!isValid) {
            //set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
      };
    },
    [validationErrors],
  );

  const addBooleanEditor = (column) => {
    // For boolean columns -> render as Yes/No drop-down
    if (!!column && column.enableEditingAsBoolean)
      return {
        ...column,
        accessorFn: (row) => { return booleanOptions[!!row[column.accessorKey]]; },
        valueParser: parseBoolean,
        muiTableBodyCellEditTextFieldProps: {
          select: true,
          children: Object.keys(booleanOptions).map((key) => (
            <MenuItem key={key} value={booleanOptions[key]}>
              { booleanOptions[key] }
            </MenuItem>
          )),
        }
      };
    else
      return column;
  };

  const addDropDownEditor = (column) => {
    // For columns with 'options' attribute defined but no multiselect (array fields) -> render as drop-down
    if (!!column && !!column.options && !column.isArrayField) {
      const accessorFn = (row) => {
        const dataField  = column.accessorKey;
        const value = row[dataField];
        return value || '-';
      }
      const muiTableBodyCellEditTextFieldProps = {
        select: true,
        children: column.options.map((obj) => (
          <MenuItem key={`menu-item-${obj.key}`} value={obj.value}>
            {obj.displayText ?? obj.value}
          </MenuItem>
        )),
      };
      return {
        ...column,
        valueParser: parseArray,
        accessorFn,
        muiTableBodyCellEditTextFieldProps
      }
    }
    else
      return column;
  }

  const addArrayEditor = (column) => {
    if (!!column && !!column.isArrayField) {

      // function to serialize the field for display in the table column (may return text or HTML element)
      const accessorFn = (row) => {
        const MaxElementsToShow = 3;
        const dataField = column.accessorKey || '__no_data_field';
        const value = (!!column.accessorFn) ? column.accessorFn(row) : row[dataField];
        if (Array.isArray(value)) {
          const dots = (value.length > MaxElementsToShow) ? ', ...' : '';
          const count = (value.length > MaxElementsToShow) ? `(${value.length} items)` : '';
          return (
            <React.Fragment>
              {value.slice(0, MaxElementsToShow).join(', ')}{dots}<br />
              {count}
            </React.Fragment>
          );
        }
        else if (!!value)
          return value;
        else
          return '-';
      }

      // function to implement a column filter the field
      let { filterFn } = column;
      if (!filterFn) {
        filterFn = (row, column, filterExpresssion) => {
          const value = row.original[column];
          let valueStr;
          if(Array.isArray(value))
            valueStr = value.join(', ')
          else if (!!value)
            valueStr = `${value}`
          else
            valueStr = '';

          return valueStr.toLowerCase().includes((filterExpresssion || '').toLowerCase());
        };
      }

      return {
        ...column,
        valueParser: parseArray,
        accessorFn,
        filterFn,
        Edit: (context) => {
          const { accessorKey } = column;
          const rowData = ((context || {}).row || {}).original || {};
          const currentSelection = rowData[accessorKey];
          const stateChangeHandler = (e) => {console.log(e); };
          return (
            <MultiSelectDDL title={column.header}
                            options={column.options}
                            optionsFilterFn={
                              (!!column.optionsFilterFn)
                              ? (value) => column.optionsFilterFn(value, rowData)
                              : null
                            }
                            initialSelection={currentSelection}
                            contextKey={accessorKey}
                            onChange={(data) => handleNestedComponentChange(accessorKey, data)} />
          );
        },
      };
    }
    else
      return column;
  };

  const makeColumnEditable = (column) => {
    if (column.enableEditing === false) {
      return column;
    }
    else {
      return {
        ...column,
        muiTableBodyCellEditTextFieldProps: {
          select: false
        },
      }
    }
  };

  const renderActionButtons = ({ table, row }) => {
    const { settings } = props;
    const rowIsReadOnly = (row.original || {}).readOnly;

    const defaultActions = [];
    if (!!settings.canEditExistingRow && !rowIsReadOnly) {
      defaultActions.push({
        title: settings.editExistingRowText ?? "Edit",
        clickHandler: (table, row) => handleEditRow(table, row),
        renderIcon: () => (<Edit />),
      });
    }
    if (!!settings.canDeleteExistingRow && !rowIsReadOnly) {
      defaultActions.push({
        title: settings.deleteExistingRowText ?? "Delete",
        clickHandler: (table, row) => handleDeleteRow(table, row),
        renderIcon: () => (<Delete />),
      });
    }

    const extraActions = (!rowIsReadOnly) ? settings.extraActions : null;
    actions = [
      ...defaultActions,
      ... (extraActions || [])
    ];
    tooltipPositions = actions.map(
      (a, idx) => (idx == 0) ? "left" : (idx == actions.length - 1) ? "right" : "top"
    );
    return actions.map(
      (a, idx) => (
        <Tooltip key={`extra-action-button-${idx}`}
                      arrow placement={tooltipPositions[idx]}
                      title={a.title}>
          <IconButton onClick={() => a.clickHandler(table, row)}>
            {a.renderIcon()}
          </IconButton>
        </Tooltip>
      )
    );
  };

  const renderActionBox = ({ table, row }) => (
    <Box sx={{ display: 'flex', gap: '1rem' }}>
      {renderActionButtons({table, row})}
    </Box>
  );

  // Build the columns
  const columnsForTable = useMemo(
    () => columns
      .map(addBooleanEditor)
      .map(addDropDownEditor)
      .map(addArrayEditor),
    [getCommonEditTextFieldProps],
  );

  const columnsForEditorNewRecord = useMemo(
    () => columns
      .filter((column) => column.enableEditingForNewRecords || column.enableEditing !== false)
      .filter((column) => !column.isAutoGenerated)
      .filter((column) => !column.isArrayField),
    [getCommonEditTextFieldProps],
  );

  const columnsForEditorExistingRecord = useMemo(
    () => columns
      .filter((column) => column.enableEditing !== false)
      .filter((column) => !column.isAutoGenerated)
      .map(makeColumnEditable)
      .map(addBooleanEditor)
      .map(addDropDownEditor)
      .map(addArrayEditor),
    [getCommonEditTextFieldProps],
  );

  // Define callbacks for add/edit/save/cancel actions
  const handleNestedComponentChange = (key, data) => {
    const merged = exposedValues || {};
    merged[key] = data;
    setExposedValues(merged);
  };

  const saveRowAndUpdateTable = async(values, rowIdx, recordName, closeEditorFunc) => {
    // Get the applicable column spec
    const isNewRow = (rowIdx == null);
    const columnsForEditor = (isNewRow) ? columnsForEditorNewRecord : columnsForEditorExistingRecord;

    // Prepare data to save
    const getColumnSpec = (accessorKey) => columnsForEditor.filter((c) => c && c.accessorKey == accessorKey)[0];
    const parsedValues = Object.entries(values)
      .map((kvp) => [kvp[0], kvp[1], getColumnSpec(kvp[0]) || {}])  // annotate (data field, value) with 3rd element representing the column spec for that data field
      .filter((a) => !!a[2].valueParser)                            // only parse when there is a valueParser defined
      .map((a) => [a[0], a[2].valueParser(a[1])]);                  // apply valueParser to transform (data field, value, column spec) -> (data field, parsed value)
    const processedValues = applyNesting({
      ...values,
      ...Object.fromEntries(new Map(parsedValues))
    });

    // Update the table in the front-end
    if (rowIdx == null) {
      // append at end, rowIdx set to the new row
      tableData.push(processedValues);
      rowIdx = tableData.length - 1;
    }
    else {
      // update in place
      tableData[rowIdx] = processedValues;
    }

    // Close the editor modal
    if (!!closeEditorFunc) {
      closeEditorFunc();
    }

    // Save data to back-end
    if (!!saveHandler && !!processedValues) {
      const primaryKeyColumns = columns.filter((column) => column.isPrimaryKey);
      const primaryKeyColumn = primaryKeyColumns ? primaryKeyColumns[0].accessorKey : null;
      const primaryNameColumns = columns.filter((column) => column.isPrimaryName);
      const primaryNameColumn = primaryNameColumns ? primaryNameColumns[0].accessorKey : null;
      console.log(`Saving ${recordTypeName} with ${primaryNameColumn} ${recordName}`);

      const savedRecordId = await saveHandler(processedValues);
      console.log(`Saved record with ID: ${savedRecordId}`);

      if (!!primaryKeyColumn)
        tableData[rowIdx][primaryKeyColumn] = savedRecordId;
    }

    // Update the data binding behind the GUI element
    setTableData([...tableData]);
  };

  const handleEditRow = (table, row) => {
    // this function imposes the field visibility in the modal when editing an existing table row
    const dataFieldsToShow = columnsForEditorExistingRecord.map((c) => c.accessorKey);
    const allCells = row.getAllCells();
    const getAllCells = () => allCells.filter((c) => {
      const dataField = ((c.column || {}).columnDef || {}).accessorKey;
      return dataField && dataFieldsToShow.includes(dataField);
    });
    table.setEditingRow({
      ...row,
      getAllCells
    });
  };

  const handleCreateNewRow = async (values) => {
    const primaryNameColumns = columns.filter((column) => column.isPrimaryName);
    const primaryNameColumn = primaryNameColumns ? primaryNameColumns[0].accessorKey : null;
    const recordName = values[primaryNameColumn]
    const dummyCloseEditor = () => null;  // the "new row" modal is a different element, no need to close the editor
    await saveRowAndUpdateTable(values, null, recordName, dummyCloseEditor);
  };

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors).length) {
      const primaryKeyColumns = columns.filter((column) => column.isPrimaryKey);
      const primaryKeyColumn = primaryKeyColumns ? primaryKeyColumns[0].accessorKey : null;
      const primaryNameColumns = columns.filter((column) => column.isPrimaryName);
      const primaryNameColumn = primaryNameColumns ? primaryNameColumns[0].accessorKey : null;
      const recordName = row.getValue(primaryNameColumn);

      if (!!primaryKeyColumn && values[primaryKeyColumn] == null) {
        /* Apply PK to the editor data
           The PK (ID field) is typically not displayed in edit modal so it will be absent from the saved data;
           potentially leading to an insert on the database rather than an update.
        */
        const existingPK = (row.original || {})[[primaryKeyColumn]];
        console.log(`Patch primary key value: ${existingPK}`);
        values[primaryKeyColumn] = existingPK;
      }

      // Merge values returned from the modal with the values exposed by nested components through callbacks
      const mergedValues = {
        ...values,
        ...exposedValues
      }

      await saveRowAndUpdateTable(mergedValues, row.index, recordName, exitEditingMode);
    }
  };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const handleDeleteRow = useCallback(
    async (table, row) => {
      const { columns } = props;
      const primaryKeyColumns = columns.filter((column) => column.isPrimaryKey);
      const primaryKeyColumn = primaryKeyColumns ? primaryKeyColumns[0].accessorKey : null;
      const primaryNameColumns = columns.filter((column) => column.isPrimaryName);
      const primaryNameColumn = primaryNameColumns ? primaryNameColumns[0].accessorKey : null;
      const recordId = row.getValue(primaryKeyColumn);
      const recordName = row.getValue(primaryNameColumn);
      const recordNameQuoted = !!recordName ? '"' + recordName + '"' : null;
      const conformationMessage = (!!recordName) ? `Do you want to delete ${recordTypeName} ${recordNameQuoted}?` : `Do you want to delete this ${recordTypeName}?`;
      if (!confirm(conformationMessage)) {
        return;
      }

      if (!!deleteHandler && !!recordId) {
        console.log(`Deleting ${recordTypeName} with ID ${recordId}`);
        await deleteHandler(recordId);
      };

      tableData.splice(row.index, 1);
      setTableData([...tableData]);
    },
    [tableData, props],
  );

  // Set table props based on the viewport size
  const { viewportSize } = props;
  const renderingMode =
    (viewportSize.height >= 1280) ? 'large'
    : (viewportSize.height >= 880) ? 'medium'
    : 'compact';
  let enableDensityToggle, enableFullScreenToggle, density, pageSize;
  switch(renderingMode)
  {
    case 'large':
      enableDensityToggle = true;
      enableFullScreenToggle = false;
      density = 'comfortable';
      nrRowsToDisplay = 10;
      break;

    case 'medium':
      enableDensityToggle = true;
      enableFullScreenToggle = false;
      density = 'comfortable';
      nrRowsToDisplay = 5;
      break;

    case 'compact':
    default:
      enableDensityToggle = false;
      enableFullScreenToggle = true;
      density = 'compact';
      nrRowsToDisplay = 5;
      break;
  }

  // Compute the pagination state (re-paginate when needed)
  const targetPagination = {
    pageIndex: 0,
    pageSize: nrRowsToDisplay,
  };
  const [pagination, setPagination] = useState(targetPagination);
  let initialTableState = {
    density,
    columnVisibility,
    pagination: targetPagination
  };
  let tableState = {
    density,
    columnVisibility
  };
  if (nrRowsToDisplay != pagination.pageSize) {
    initialTableState = {};
    tableState = {
      ...tableState,
      pagination: targetPagination
    };
  }

  // Render the table
  const classNameStr = (!!className) ? `editable-table ${className}` : 'editable-table';
  return (
    <div id={tableId} className={classNameStr}>
      <MaterialReactTable
        // Global display options
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            header: 'Actions',
            // Example: use a text button instead of an icon button
            //Cell: ({ row, table }) => (
            //  <Button onClick={() => table.setEditingRow(row)}>Klant Bewerken</Button>
            //),
          },
        }}
        editingMode="modal"

        // Size-dependent display options
        initialState={initialTableState}
        enableDensityToggle={enableDensityToggle}
        enableFullScreenToggle={enableFullScreenToggle}

        // Feature switches
        enableColumnOrdering={true}
        enableEditing={canEditExistingRow}

        // Actions & callbacks
        onEditingRowSave={handleSaveRowEdits}
        onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={renderActionBox}
        renderTopToolbarCustomActions={() => renderNewRowButton(settings)}

        // Data
        columns={columnsForTable}
        data={tableData}
      />

      <CreateNewRowModal
        title={settings.createNewRowText}
        columns={columnsForEditorNewRecord}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreateNewRow}
      />
    </div>
  );
};

export const CreateNewRowModal = ({ title, open, columns, onClose, onSubmit }) => {
  const [values, setValues] = useState(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {}),
  );

  const handleSubmit = () => {
    //put your validation logic here
    onSubmit(values);
    onClose();
  };

  const renderBooleanInputField = (column) => {
    return '';

    /* TODO work in progress, get the DDL editor right
    const availableValues = [
      [false, booleanOptions[false]],
      [true, booleanOptions[true]]
    ];
    const defaultValue = 'Yes';
    let value = defaultValue;

    const setValue = (e) => {
      const newValue = {
        [e.target.name]: e.target.value
      };
      console.log(newValue);
      setValues({ ...values, ...newValue });
      value = e.target.value;
    };

    return (
      <TextField key={column.accessorKey}
                 label={column.header}
                 name={column.accessorKey}
                 defaultValue={defaultValue}
                 value={value}
                 onChange={(e) => setValue(e)}
                 select
                 defaultValue={booleanOptions[true]} >
        { availableValues.map((kvp) => (
            <MenuItem key={kvp[0]} value={kvp[1]}>
              {kvp[1]}
            </MenuItem>
          )) }
      </TextField>
    );
    */
  };

  const renderTextInputField = (column) => {
    const value = null;
    return (
      <TextField key={column.accessorKey}
                 label={column.header}
                 name={column.accessorKey}
                 value={value}
                 onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })} />
    );
  };

  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center" className="dialog-title">{title}</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
            }}
          >
            {
              columns.map((column) => {
                if (!!column.enableEditingAsBoolean)
                  return renderBooleanInputField(column);
                else
                  return renderTextInputField(column);
              })
            }
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="secondary" onClick={handleSubmit} variant="contained">
          {title}
        </Button>
      </DialogActions>
    </Dialog>
  );
};


function mapStateToProps(state) {
  /* Return a dict with the relevant state info to pass into the props for this component */
  const { viewportSize } = state;
  return { ...viewportSize };
}

export default connect(mapStateToProps)(EditableTable);
