export function getProfileInitiated() {
  return {
    type: 'GET_PROFILE_INITIATED'
  };
}

export function getProfileSucceeded(profile) {
  return {
    type: 'GET_PROFILE_SUCCEEDED',
    payload: {
      ...profile
    }
  };
}

export function getProfileFailed() {
  return {
    type: 'GET_PROFILE_FAILED'
  };
}
