import "./ReportManagementOverview.css";

import React from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';

import Achtergrond from "../Achtergrond";
import Header from "../Header";
import LeftNavigationBar from "../LeftNavigationBar";
import TypeTitles from "../TypeTitles";
import EditableTable from "../EditableTable";
import MultiSelectDDL from "../MultiSelectDDL";

import { listAllReportsAndManagers, saveReportBindings, replaceReportBindings, cleanupReportBindings } from '../../api/reports';
import { sortByAttribute } from '../../helpers/sorting';
import sleep from '../../helpers/sleep';
import { loadAuthorizationsAndProfileData } from '../../state/workflows/login';


class ReportManagementOverview extends React.Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = {
      reportsAndUsers: null,
      listQueryInProgress: false,
      message: null,
      showReplaceReportModal: false,
      replaceReportsInProgress: false,
      replaceReportsOldReportId: null,
      replaceReportsNewReportId: null,
    };

    // Click event handlers
    this.reloadReports = this.reloadReports.bind(this);
    this.saveReport = this.saveReport.bind(this);
    this.replaceReportGlobally = this.replaceReportGlobally.bind(this);
    this.cleanupOldReports = this.cleanupOldReports.bind(this);

    // Rendering functions
    this.renderReportsTable = this.renderReportsTable.bind(this);
    this.renderReloadButton = this.renderReloadButton.bind(this);
    this.renderReplaceReportButton = this.renderReplaceReportButton.bind(this);
    this.renderCleanOldReportsButton = this.renderCleanOldReportsButton.bind(this);
    this.renderReplaceReportModal = this.renderReplaceReportModal.bind(this);
    this.renderMessageModal = this.renderMessageModal.bind(this);
    this.closeMessageModal = this.closeMessageModal.bind(this);
  }

  async reloadReports() {
    this.setState({listQueryInProgress: true});

    // Get API credentials
    const { tokenState } = this.props;
    const { applicationToken } = tokenState;
    if (!applicationToken) {
      return;
    }

    // Get the data for the table
    const response = await listAllReportsAndManagers(applicationToken);
    const { reports, users } = response['powerbi_reports/list'];
    const reportsAndUsers = {
      reports: sortByAttribute(reports, 'powerbi_report_name', false),
      users: sortByAttribute(users, 'first_name', false, (user) => user.user_id=='new[AM]'),
    }
    this.setState({
      listQueryInProgress: false,
      cleanupQueryInProgress: false,
      reportsAndUsers
    })
  }

  async saveReport(report) {
    // Prepare request
    const { tokenState } = this.props;
    const { applicationToken } = tokenState;
    if (!applicationToken) {
      return;
    }

    const { powerbi_report_id, user_ids } = report;
    const reportIdx = this.state.reportsAndUsers.reports.findIndex((rpt) => rpt.powerbi_report_id === powerbi_report_id);
    const reportFromApi = this.state.reportsAndUsers.reports[reportIdx];
    const payload = {
      report_id: powerbi_report_id,
      report_name: reportFromApi.powerbi_report_name,
      dataset_id: reportFromApi.powerbi_dataset_id,
      report_group_id: reportFromApi.powerbi_report_group_id,
      user_ids
    };

    // Perform API call
    const response = await saveReportBindings(applicationToken, payload);
    const saveResult = (response || {})["powerbi_reports/save"] || {};

    // Update GUI
    const itemToSaveDesc = `User bindings for report '${reportFromApi.powerbi_report_name}'`;
    if (saveResult.status === "saved") {
      const { reports, users } = this.state.reportsAndUsers;
      this.setState({message: `${itemToSaveDesc} have been saved!`})
      reports[reportIdx] = {
        ...reportFromApi,
        user_ids
      };
      this.setState({listQueryInProgress: true});
      this.setState({
        reportsAndUsers: {
          reports,
          users
        }
      });
      await sleep(0);
      this.setState({listQueryInProgress: false});
    }
    else {
      this.setState({message: `Failed to save ${itemToSaveDesc}!`})
    }
  }

  async replaceReportGlobally(oldReportId, newReportId) {
    const { tokenState } = this.props;
    const { applicationToken } = tokenState;
    if (!applicationToken) {
      return;
    }
    await replaceReportBindings(applicationToken, oldReportId, newReportId);
    await this.reloadReports();
  }

  async cleanupOldReports() {
    // Responsive GUI
    this.setState({cleanupQueryInProgress: true});

    // Prepare request
    const { tokenState } = this.props;
    const { applicationToken } = tokenState;
    if (!applicationToken) {
      return;
    }
    const payload = {};

    // Perform API call
    const response = await cleanupReportBindings(applicationToken, payload);
    const cleanupResult = (response || {})["powerbi_reports/cleanup"] || {};

    // User feedback
    if (cleanupResult.status === 'ok') {
      const displayMessage = `Old reports have been cleaned up: ${cleanupResult.details}`;
      this.setState({
        cleanupQueryInProgress: false,
        message: displayMessage});
    }
    else {
      console.warn(cleanupResult);
      this.setState({
        cleanupQueryInProgress: false,
        message: 'Failed to clean up old reports'});
    }
  }

  expandUsers(reportsAndUsers) {
    const { reports, users } = reportsAndUsers;
    const merged = reports.map((report) => {
      const usersForThisReport = users.filter((userObj) => report.user_ids.includes(userObj.user_id));
      return {
        ...report,
        users: usersForThisReport.map((userObj) => `${userObj.first_name} ${userObj.last_name}`)
      };
    })
    return merged;
  }

  renderReportsTable() {
    /* Example record:
      {
        powerbi_report_id: "00000000-1111-2222-3333-abcdefabcdef"
        powerbi_report_name: "Performance Dashboard"
        powerbi_dataset_id: "00000000-9999-0000-1111-abcdefabcdef"
        powerbi_embed_url: "https://app.powerbi.com/reportEmbed?reportId=....."
        powerbi_web_url: https://app.powerbi.com/groups/<guid>/reports/<guid>
        user_ids: [ "E000001", "E000002" ]
        users: [
          {
            user_id: "E000001",
            first_name: "Henk"
            last_name: "de Eerste"
          },
          {
            user_id: "E000002",
            first_name: "Jan"
            last_name: "de Tweede"
          },
        ]
      }
    */
    const { reportsAndUsers } = this.state;
    const tableData = this.expandUsers(reportsAndUsers);

    const columns = [
      {
        accessorKey: 'powerbi_report_id',
        header: 'Report ID',
        enableEditing: false,
        hiddenByDefault: true, // custom attribute
        isPrimaryKey: true, // custom attribute
        isAutoGenerated: true, // custom attribute
      },
      {
        accessorKey: 'powerbi_report_name',
        header: 'Report',
        size: 150,
        enableEditing: false,
        isPrimaryName: true, // custom attribute
      },
      {
        accessorKey: 'powerbi_report_group_id',
        header: 'Report Group ID',
        enableEditing: false,
        hiddenByDefault: true, // custom attribute
        isAutoGenerated: true, // custom attribute
      },
      {
        accessorKey: 'powerbi_dataset_id',
        header: 'Dataset ID',
        enableEditing: false,
        hiddenByDefault: true, // custom attribute
        isAutoGenerated: true, // custom attribute
      },
      {
        accessorKey: 'user_ids',
        header: 'User IDs',
        hiddenByDefault: true, // custom attribute
        isArrayField: true, // custom attribute
        options: reportsAndUsers.users.map(
          (user) => {
            return {
              key: user.user_id,
              value: user.user_id,
              displayText: `${user.first_name} ${user.last_name}`,
            };
          }
        ),
      },
      {
        accessorKey: 'users',
        header: 'Account & Hospitality Managers',
        size: 220,
        enableEditing: false,
        isArrayField: true, // custom attribute
      },
      {
        accessorFn: (row) => (row.user_ids || []).includes("new[AM]") ? 'Yes' : '-',
        header: 'Default Report',
        size: 80,
        enableEditing: false,
        isAutoGenerated: true, // custom attribute
      }
    ];
    const editorSettings = {
      canCreateNewRow: false,
      canEditExistingRow: true,
      editExistingRowText: "Assign report",
      canDeleteExistingRow: false,
      saveHandler: this.saveReport,
    };

    return (
      <EditableTable tableId="reports-table"
                     className="reports-table"
                     recordTypeName="report"
                     columns={columns}
                     data={tableData}
                     settings={editorSettings} />
    );
  }

  renderPlaceHolder() {
    return (
      <div className="reports-table-placeholder">Reports loading...</div>
    );
  }

  renderReloadButton() {
    return (
      <Button variant="contained"
              sx={{
                marginRight: "1.25em"
              }}
              style={{backgroundColor: "var(--teak)"}}
              onClick={this.reloadReports}>
        Reload reports
      </Button>
    );
  }

  renderReplaceReportButton() {
    return (
      <Button variant="contained"
              sx={{
                marginRight: "1.25em"
              }}
              style={{backgroundColor: "var(--teak)"}}
              onClick={() => this.setState({showReplaceReportModal: true})}>
        Replace old with new report
      </Button>
    );
  }

  renderCleanOldReportsButton() {
    return (
      <Button variant="contained"
              sx={{
                marginRight: "1.25em"
              }}
              style={{backgroundColor: "var(--teak)"}}
              onClick={this.cleanupOldReports}>
        Clean up old report links
      </Button>
    );
  }

  renderReplaceReportModal() {
    const {
      reportsAndUsers,
      showReplaceReportModal,
      replaceReportsInProgress,
      replaceReportsOldReportId,
      replaceReportsNewReportId
    } = this.state;

    if (!showReplaceReportModal || !reportsAndUsers)
      return '';

    const reports = reportsAndUsers.reports || [];
    const optionsFrom = reports
      .map((report) => {
        return {
          key: report.powerbi_report_id,
          value: report.powerbi_report_id,
          displayText: report.powerbi_report_name
        };
      });

    let optionsTo = reports
      .filter((report) => (replaceReportsOldReportId == null) || (report.value !== replaceReportsOldReportId))
      .map((report) => {
        return {
          key: report.powerbi_report_id,
          value: report.powerbi_report_id,
          displayText: report.powerbi_report_name
        };
      });
    optionsTo = [
      {
        key: 'null-report-id',
        value: null,
        displayText: '(no replacement, just delete the old report)'
      },
      ...optionsTo
    ]

    const onCancelButtonClick = () => {
        this.setState({showReplaceReportModal: false});
      };
      const onSubmitButtonClick = async () => {
        this.setState({replaceReportsInProgress: true});
        await this.replaceReportGlobally(replaceReportsOldReportId, replaceReportsNewReportId);
        this.setState({
          showReplaceReportModal: false,
          replaceReportsInProgress: false,
          message: 'Replaced powerBI report'
        });
      };

    return (
      <Dialog open={showReplaceReportModal}>
        <DialogTitle textAlign="center" className="dialog-title">Replace BI report globally</DialogTitle>
        <DialogContent>
          <form onSubmit={(e) => e.preventDefault()}>
            <Stack sx={{ marginTop: "1em", width: '100%', minWidth: { xs: '300px', sm: '360px', md: '400px' }, gap: '1.5rem', }} >
              <MultiSelectDDL title="Old report to be replaced"
                              restrictToSingleSelect
                              options={optionsFrom}
                              initialSelection={replaceReportsOldReportId}
                              contextKey="old-report-id"
                              onChange={(data) => this.setState({replaceReportsOldReportId: (data || [null])[0]})} />
              <MultiSelectDDL title="New report"
                              restrictToSingleSelect
                              options={optionsTo}
                              initialSelection={replaceReportsNewReportId}
                              contextKey="new-report-id"
                              onChange={(data) => this.setState({replaceReportsNewReportId: (data || [null])[0]})} />
            </Stack>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancelButtonClick}>Cancel</Button>
          <Button color="secondary"
                  variant="contained"
                  sx={{
                    marginLeft: "1.25em",
                    marginRight: "1.25em"
                  }}
                  disabled={replaceReportsInProgress}
                  onClick={onSubmitButtonClick} >
            Replace
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderMessageModal() {
    const { message } = this.state;
    if (!!message) {
      setTimeout(this.closeMessageModal, 2000);
      return (
        <div className="reports-message-modal" onClick={() => this.closeMessageModal()} >
          { message }
        </div>
      );
    }
    else {
      return '';
    }
  }

  closeMessageModal() {
    this.setState({message: null});
  }

  render() {
    const { headerProps, typeTitlesProps } = this.props;
    const typeTitlesClassName = `${(typeTitlesProps || {}).className || ''} selector-pos-col-1`;

    const { reportsAndUsers, listQueryInProgress } = this.state;

    const tableElement = (!!reportsAndUsers && !listQueryInProgress) ? this.renderReportsTable() : this.renderPlaceHolder();
    const reloadButton = this.renderReloadButton();
    const replaceReportButton = this.renderReplaceReportButton();
    const replaceReportModal = this.renderReplaceReportModal();
    const cleanOldReportsButton = this.renderCleanOldReportsButton();
    const messageModal = this.renderMessageModal();

    return (
      <div className="container-center-horizontal">
        <div className="clients-overview-page screen">
          <div className="overlap-group-clients-overview">
            <Achtergrond />
            <Header header2Props={headerProps.header2Props} />
            <LeftNavigationBar />
            <div className="reports-table-container">
              { tableElement }
              { reloadButton }
              { replaceReportButton }
              { cleanOldReportsButton }
            </div>
            { replaceReportModal }
            { messageModal }
          </div>
          <TypeTitles subtitle={typeTitlesProps.subtitle} className={typeTitlesClassName} />
        </div>
      </div>
    );
  }

  async componentDidMount() {
    // Load the reports list
    await this.reloadReports();

    // This will refresh the current user's data in the redux store (i.e. which report IDs you can assign etc)
    const { cognitoAuthenticationToken } = this.props.tokenState;
    await loadAuthorizationsAndProfileData(cognitoAuthenticationToken);
  }
}


function mapStateToProps(state) {
  /* Return a dict with the relevant state info to pass into the props for this component */
  return {
    tokenState: state.token,
  };
}

export default connect(mapStateToProps)(ReportManagementOverview);
