const defaultState = {
  cognitoAuthenticationToken: null,
  applicationToken: null,
  errorMessage: null,
};

export function tokenReducer(state = defaultState, action) {
  switch (action.type) {

    case 'GET_COGNITO_TOKEN_SUCCEEDED':
      return {
        ...state,
        cognitoAuthenticationToken: action.payload.cognitoAuthenticationToken
      };

    case 'GET_APPLICATION_TOKEN_SUCCEEDED':
      return {
        ...state,
        applicationToken: action.payload.applicationToken,
        errorMessage: null
      };

    case 'GET_APPLICATION_TOKEN_FAILED':
      const { errorMessage } = action.payload;
      return {
        applicationToken: null,
        errorMessage
      };

    default:
      return state;
  }
}
