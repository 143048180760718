import * as AWS from 'aws-sdk/global';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';

const awsRegion = 'eu-west-1'
const cognitoUserPoolID = 'eu-west-1_ZnWbWpVYp';
const cognitoUserPoolAppClientID = '725adu8eus79krgg5t5ccij4nr';
const cognitoIdentityPoolID = 'eu-west-1:c345ab98-78b4-4202-b60e-866d83c7740c';


export async function getToken(username, password) {
  const authenticationData = {
    Username: username.toLowerCase(),
    Password: password
  };
  const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);

  const poolData = {
    UserPoolId: cognitoUserPoolID,
    ClientId: cognitoUserPoolAppClientID
  };
  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  const cognitoUser = new AmazonCognitoIdentity.CognitoUser({Username: username, Pool: userPool});

  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(
      authenticationDetails,
      {
        onSuccess: function(result) {
          var accessToken = result.getAccessToken().getJwtToken();

          const identityPoolLoginID = `cognito-idp.${awsRegion}.amazonaws.com/${cognitoUserPoolID}`;
          const creds = {
            IdentityPoolId: cognitoIdentityPoolID,
            Logins: {}
          };
          creds.Logins[identityPoolLoginID] = result.getIdToken().getJwtToken(),

          AWS.config.region = awsRegion;
          AWS.config.credentials = new AWS.CognitoIdentityCredentials(creds);

          //refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
          AWS.config.credentials.refresh(error => {
            if (error) {
              console.log(`AWS Cognito authentication failed: ${error}`);
              resolve({success: false});
            }
            else {
              console.log('AWS Cognito authentication successful');
              const payload = (result.idToken || {}).payload || {};
              const ignoredKeys = ['aud', 'auth_time', 'cognito:username', 'event_id', 'exp', 'iat', 'iss', 'jti', 'origin_jti', 'token_use'];
              var userAttributes = Object.fromEntries(
                Object.entries(payload)
                .filter(([key]) => !ignoredKeys.includes(key))
              );
              resolve({
                success: true,
                username: username,
                token: accessToken,
                userAttributes: userAttributes
              });
            }
          });
        },

        onFailure: function(err) {
          const errorMessage = err.message || JSON.stringify(err);
          console.log(`AWS Cognito authentication failed: ${errorMessage}`);
          resolve({success: false});
        },

        newPasswordRequired: async function(userAttributes, requiredAttributes) {
            // User was signed up by an admin and must provide new
            // password and required attributes, if any, to complete
            // authentication.
            console.log('Password change required by server');

            // the api doesn't accept this field back
            delete userAttributes.email_verified;

            // store userAttributes on global variable
            sessionUserAttributes = userAttributes;

            resolve({
              success: false,
              passwordChangeRequired: true,
              cognitoUser: cognitoUser
            });
        }
      }
    );
  });
}


export async function changePassword(cognitoUser, newPassword) {
  return new Promise((resolve, reject) => {
    if (!cognitoUser || !newPassword) {
      console.warn('Unable to change password. Parameters (cognitoUser, newPassword) incomplete.');
      reject('MissingParameters');
    }

    function onSuccess(result) {
      console.log(`Change password OK: ${result}`);
      resolve(result);
    }

    function onFailure(err) {
      console.warn(`Change password failed: ${err}`);
      reject(err);
    }

    cognitoUser.completeNewPasswordChallenge(
      newPassword,
      {},
      {
        onSuccess: (result) => {
          console.log('Change password OK');
          resolve(result);
        },

        onFailure: (err) => {
          console.warn(`Change password failed: ${err}`);
          reject(err);
        }
      }
    );
  });
}
