import React from "react";
import { Link } from "react-router-dom";
import "./CompanyFilterDocumentsSLA.css";

function CompanyFilterDocumentsSLA(props) {
  const { companyAbc1, companyAbc2, addCompany } = props;

  return (
    <div className="container-center-horizontal">
      <div className="company-filter-documents-sla screen">
        <div className="company-filter-documents-sla-1">
          <div className="overlap-group1-7">
            <img className="vector-36" src="/img/vector-49@2x.svg" />
            <div className="company-abc-4 desktopfilters">{companyAbc1}</div>
          </div>
          <div className="overlap-group-24">
            <div className="flex-row-16">
              <img className="vector-37" src="/img/vector-7@2x.svg" />
              <div className="company-abc-5 desktopfilters">{companyAbc2}</div>
            </div>
            <img className="line-18-3" src="/img/line-18@2x.svg" />
            <div className="flex-row-17">
              <Link to="/my-profile-page">
                <img className="vector-38" src="/img/vector-6@2x.svg" />
              </Link>
              <Link to="/my-profile-page">
                <div className="add-company desktopfilters">{addCompany}</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyFilterDocumentsSLA;
