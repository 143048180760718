const defaultState = {
  passwordResetInProgress: false,
  username: null,
  password: null
};

export function passwordResetReducer(state = defaultState, action) {
  const { username, password } = action.payload || {};

  switch (action.type) {

    case 'PASSWORD_RESET_INITIATED':
      return {
        ...state,
        passwordResetInProgress: true,
        username
      };

    case 'PASSWORD_RESET_SUCCEEDED':
      return {
        ...state,
        passwordResetInProgress: false,
        username,
        password
      };

    case 'PASSWORD_RESET_FAILED':
      return {
        ...state,
        ...defaultState
      };

    default:
      return state;
  }
}
