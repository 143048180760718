import "./PasswordResetScreen.css";

import React from "react";
import { connect } from 'react-redux';

import { Lock, VerifiedUser } from '@mui/icons-material';

import { parsePasswordResetToken, performPasswordReset } from '../../api/passwordReset';
import { passwordResetInitiated, passwordResetSucceeded, passwordResetFailed } from '../../state/actions/passwordReset';
import store from '../../state/store';

import IntroText from "../IntroText";
import LoginButton from "../LoginButton";
import WelcomeHeader from "../WelcomeHeader";

class PasswordResetScreen extends React.Component {

  constructor(props) {
    super();

    this.props = props;
    this.state = {
      // API interaction state
      tokenValidationInProgress: false,
      tokenValidationCompleted: false,
      tokenValidationFailed: false,
      tokenValidationResponseData: null,

      // Form field values
      email: null,
      password: null,
      password2: null,

      // Other UI state
      passwordVisible: false,
      inputValidationError: null,
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.passwordInputType = this.passwordInputType.bind(this);
    this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
    this.validateToken = this.validateToken.bind(this);
    this.renderFormItem = this.renderFormItem.bind(this);
    this.renderPasswordResetForm = this.renderPasswordResetForm.bind(this);
    this.renderMessages = this.renderMessages.bind(this);
    this.renderTokenValidationErrors = this.renderTokenValidationErrors.bind(this);
    this.onClickSetPasswordButton = this.onClickSetPasswordButton.bind(this);
    this.setValidationError = this.setValidationError.bind(this);
    this.clearValidationError = this.clearValidationError.bind(this);
  }

  // Start-up
  async componentDidMount() {
    this.validateToken();
  }

  async validateToken() {
    if (!!this.props.token && !this.state.tokenValidationCompleted) {
      let tokenValidationFailed = true;
      let tokenValidationResponseData = null;
      let email = null;
      try {
        this.setState({
          tokenValidationInProgress: true
        });
        const response = await parsePasswordResetToken(this.props.token);
        if (!!response) {
          tokenValidationResponseData = response['password_reset/parse'];
          console.log(response)
          email = this.getElementFromTokenValidationResponse(tokenValidationResponseData, 'email');
          tokenValidationFailed = false;
        }
      }
      finally {
        this.setState({
          tokenValidationInProgress: false,
          tokenValidationCompleted: true,
          tokenValidationFailed,
          tokenValidationResponseData,
          email
        });
      }
    }
  }

  getElementFromTokenValidationResponse(tokenValidationResponseData, key) {
    return ((tokenValidationResponseData || {}).status === 'ok') ? tokenValidationResponseData.data[key] : null;
  }

  // GUI state management
  passwordInputType() {
    return (!!this.state.passwordVisible) ? "text" : "password";
  }

  togglePasswordVisibility() {
    this.setState({passwordVisible: !this.state.passwordVisible});
  }

  // Rendering
  renderMessages() {
    const { tokenValidationInProgress, inputValidationError } = this.state;
    let messageText;
    let showAsError;
    if (!!tokenValidationInProgress) {
      messageText = 'Checking token...';
      showAsError = false;
    }
    else if (!!inputValidationError) {
      messageText = inputValidationError;
      showAsError = true;
    }
    else {
      messageText = null;
      showAsError = false;
    }

    const errorClass = (showAsError) ? 'error-modal' : 'message-modal';
    const modalClasses = `modal ${errorClass}`;
    return (!!messageText) ? <div className={modalClasses}>{messageText}</div> : '';
  }

  renderTokenValidationErrors() {
    const { tokenValidationFailed, tokenValidationResponseData } = this.state;
    if (tokenValidationFailed) {
      const errorMessage = (tokenValidationResponseData || {}).message || "invalid token";
      return (
        <div className="modal error-modal token-validation-error">
          Password reset token refused:<br /><br /><br />
          {errorMessage}
        </div>
      );
    }
    else {
      return '';
    }
  }

  renderFormItem(dataField, initialValue, renderIcon, readOnly, treatAsPasswordInput) {
    const readOnlyClass = (!!readOnly) ? 'form-item-readonly' : '';
    const containerClass = `form-item ${readOnlyClass}`;
    const changeHandler = (!readOnly) ? (event) => this.setState({[dataField]: event.target.value}) : null;
    const usePlaceholder = !readOnly && initialValue !== this.state[dataField];
    const placeholderValue = (!!usePlaceholder) ? initialValue : null;
    const inputValue = (!usePlaceholder) ? initialValue : this.state[dataField];
    const autoCompleteKey = `compass-client-portal-${dataField}`;
    return (
      <div className={containerClass}>
        <input type={(!!treatAsPasswordInput) ? this.passwordInputType() : 'text'}
               disabled={!!readOnly ? 'disabled': null}
               className="text-input desktopbodycopy"
               autoComplete={autoCompleteKey}
               placeholder={placeholderValue}
               value={inputValue}
               onChange={changeHandler} />
        {renderIcon()}
      </div>
    );
  }

  renderPasswordResetForm() {
    const { passwordResetState } = this.props;
    const { tokenValidationCompleted, tokenValidationFailed, tokenValidationResponseData } = this.state;
    if (tokenValidationCompleted && !tokenValidationFailed) {
      return (
        <form>
          <div className="password-reset-form-head ptserif-normal-black-l">
          Fill in your new password and submit the password change request
          </div>
          {
            this.renderFormItem('email',
                                this.state.email,
                                () => <VerifiedUser className="custom-icon" />,
                                true)
          }
          {
            this.renderFormItem('password',
                                '(create password)',
                                () => <Lock className="custom-icon" onClick={() => this.togglePasswordVisibility()}/>,
                                false,
                                true)
          }
          {
            this.renderFormItem('password2',
                                '(repeat password)',
                                () => <Lock className="custom-icon" onClick={() => this.togglePasswordVisibility()}/>,
                                false,
                                true)
          }

          <LoginButton clickHandler={this.onClickSetPasswordButton}
                       disabled={!!passwordResetState.passwordResetInProgress ? 'disabled' : null}>
                       {this.props.loginButtonProps.children}
          </LoginButton>
        </form>
      );
    }
    else {
      return "";
    }
  }

  render() {
    return (
      <div className="container-center-horizontal">
        <div className="password-reset-screen screen">
          <div className="proposition-summary">
            <div className="overlap-group-password-reset-left">
              <WelcomeHeader />
              <IntroText textLines={this.props.introText} />
              <div className="login-and-discover-the-portal">{this.props.loginAndDiscoverThePortal}</div>
            </div>
          </div>
          <div className="password-reset-area">
            <div className="overlap-group-password-reset-right">
              <img className="logo-compass-group_zwart_goud_rgb-1" src={this.props.logoCompassGroup_Zwart_Goud_Rgb1} />
              <div className="reset-password-message ptserif-bold-black-xl">Enter your new password twice</div>
              {this.renderPasswordResetForm()}
            </div>
          </div>
          {this.renderMessages()}
          {this.renderTokenValidationErrors()}
        </div>
      </div>
    );
  }

  // Form submission
  checkPasswordComplexity(password) {
    // Requires a length of at least 8 characters, containing at least:
    // * 1 uppercase letter
    // * 1 lowercase letter
    // * 1 digit
    // * 1 special character (! @ # $ % ^ & * - _ ; :)
    if (!password)
      return false;
    else if (typeof password  !== 'string')
      return false;
    else if (password.length < 8)
      return false
    else if (!new RegExp("[a-z]+").test(password))
      return false;
    else if (!new RegExp("[A-Z]+").test(password))
      return false;
    else if (!new RegExp("[0-9]+").test(password))
      return false;
    else if (!new RegExp("[^A-Za-z0-9 ]+").test(password))
      return false;
    else
      return true;
  }

  setValidationError(message, timeout=2000) {
    this.setState({inputValidationError: message});
    window.setTimeout(this.clearValidationError, timeout);
  }

  clearValidationError() {
    this.setState({inputValidationError: null});
  }

  async onClickSetPasswordButton() {
    const { email, password, password2 } = this.state;
    if (!password || !password2) {
      this.setValidationError('Not all fields have been filled in');
    }
    else if (password !== password2) {
      this.setValidationError('Passwords do not match');
    }
    else if (!this.checkPasswordComplexity(password)) {
      this.setValidationError(
        'Use a better password. (minimum 6 characters, must include uppercase and lowercase, numbers and special characters)',
        5000
      );
    }
    else {
        const { token } = this.props;
        store.dispatch(passwordResetInitiated(email));
        try {
          const response = await performPasswordReset(token, password);
          if (!!response && (response['password_reset/execute'] || {}).status === 'ok') {
            store.dispatch(passwordResetSucceeded(email, password));
            console.log(`Successfully reset password for user ${email}`);
          }
          else {
            store.dispatch(passwordResetFailed());
            console.log(response);
            const { message } = (response || {})['password_reset/execute'] || {};
            if (!!message) {
              this.setValidationError(message, 5000);
            }
          }
        }
        catch {
          store.dispatch(passwordResetFailed());
        }
    }
  }
}

function mapStateToProps(state) {
  const { passwordReset } = state;
  return {
    passwordResetState: passwordReset
  };
}

export default connect(mapStateToProps)(PasswordResetScreen);
